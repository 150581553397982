import ReactDOM from 'react-dom';
import React, { Suspense, useEffect, useCallback, useState, useRef } from 'react';
import BingMapsReact from './BingMapsReact';
import { Button, HTMLSelect } from '@blueprintjs/core';
import { data } from './data'

import './app.css';

import UAParser from 'ua-parser-js';

const uap = new UAParser();

window.deviceType = uap.getDevice()?.type ?? 'desktop';
window.isTablet = window.deviceType === UAParser.DEVICE.TABLET ||
                  window.deviceType === UAParser.DEVICE.MOBILE;
window.isDesktop = !window.isTablet; // && window.deviceType === 'desktop';
// window.isTablet = true


data.sort((l,r) => l.name.localeCompare(r.name));
data.forEach((s,i) => s.index = i);


const regions = [... new Set(data.map(e => e.region))];
regions.sort();

const colors = [
    "blueviolet",
    "cadetblue",
    "coral",
    "crimson",
    "cornflowerblue",
    "darkseagreen",
    "gold"
];

const regionColor = {};
regions.forEach((r,i) => regionColor[r] = colors[i]);

const countiesByRegion = {};
let allCounty = [];

regions.forEach(r => {
    const filtered = data.filter(e => e.region === r);

    countiesByRegion[r] = [...new Set(filtered.map(e => e.county))];
    countiesByRegion[r].sort();

    allCounty = [...allCounty, ...countiesByRegion[r]];
});

allCounty.sort();

const delalfold = data.filter((s,i) => s.region === "Dél-Alföld");

window.schools = data;


function App(props)
{

    const idxRef = useRef(-1);
    const mapDisplayQueue = useRef(['Hungary']);
    const [ center, setCenter ] = useState();

    const [ showData, setShowData ] = useState(false);

    const [ selectedRegion, setSelectedRegion ] = useState("");
    const [ selectedCounty, setSelectedCounty ] = useState("");
    const [ selectedSchool, setSelectedSchool ] = useState(-1);


    const changeRegion = useCallback(evt => {
        setSelectedCounty("");
        setSelectedRegion(evt.currentTarget.value);
        setSelectedSchool(-1);
    }, [ selectedRegion, selectedCounty, selectedSchool ]);


    const changeCounty = useCallback(evt => {
        setSelectedCounty(evt.currentTarget.value);
        setSelectedSchool(-1);
        doMapSearch(`${evt.currentTarget.value}, Hungary`);
    }, [ selectedRegion, selectedCounty, selectedSchool ]);


    const changeSchool = useCallback(evt => {
        setSelectedSchool(evt.currentTarget.value);

        if (evt.currentTarget.value > -1)
        if (window.Microsoft && window.Microsoft.Maps && window.currentMap)
        {
            try {
                const school = data[evt.currentTarget.value];

                console.log(`${school.address}, ${school.city}, Hungary`);
                //console.log(JSON.stringify(school, null, 3));
                //window.currentMap.setView({ bounds: data[evt.currentTarget.value].bestView});

                doMapSearch(`${school.address}, ${school.city}, Hungary`);
            }
            catch (e)
            {}
        }
    }, [ selectedRegion, selectedCounty, selectedSchool ]);





    const regionOptions = regions.map(r => {
        return <option key={r} value={r}>{r}</option>
    });


    const counties = countiesByRegion[selectedRegion] ?? allCounty;


    const countyOptions = counties.map(c => {
        return <option key={c} value={c}>{c}</option>
    });


    const schools = data.filter(s => {
        let ok = true;
        if ((selectedRegion || "").length > 0)
            ok &= s.region === selectedRegion;
        if ((selectedCounty || "").length > 0)
            ok &= s.county === selectedCounty;
        return ok;
    });


    const schoolOptions = schools.map((s, i) => {
        return <option key={i} value={s.index}>{s.name}, {s.city}, {s.address}</option>
    });


    const pushPins = schools.map(s => {
        return {
            center: s.bestView.center,
            options: {
                color: regionColor[s.region] || 'red',
                title: s.name,
                subTitle: `${s.city}, ${s.address}`
            }
        }
    });


    const doMapSearch = (where => {
        if (window.Microsoft && window.Microsoft.Maps && window.currentMap)
        window.Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
            let searchManager = new window.Microsoft.Maps.Search.SearchManager(window.currentMap);

            let requestOptions = {
                where: where,
                callback: (answer, userData) => {
                    try {
                        window.currentMap.setView({ bounds: answer.results[0].bestView });
                    } catch (e) {

                    }
                    //window.currentMap.setView({ bounds: answer.results[0].bestView });
                    //window.currentMap.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location));
                },
                errorCallback: (err) => {
                    console.error(err)
                }
            };

            try {
                searchManager.geocode(requestOptions);
            } catch (e) {
                console.error(e)
            }
        });
    });


    const xxx = () => {

        //if (idxRef.current >= 10) return;
        if (idxRef.current >= data.length) return;

        window.Microsoft.Maps.loadModule('Microsoft.Maps.Search', function () {
            let searchManager = new window.Microsoft.Maps.Search.SearchManager(window.currentMap);

            let s = `${data[idxRef.current].address}, ${data[idxRef.current].city}, Hungary`;
            let requestOptions = {
                where: s,
                callback: (answer, userData) => {
                    try {
                        data[idxRef.current].bestView = answer.results[0].bestView;
                        console.log(`${s}, ${data[idxRef.current].bestView.center.latitude}, ${data[idxRef.current].bestView.center.longitude}`)

                        window.currentMap.setView({ bounds: data[idxRef.current].bestView});
                        window.currentMap.entities.push(
                            new Microsoft.Maps.Pushpin(data[idxRef.current].bestView.center,
                            {
                                title:data[idxRef.current].name,
                                subTitle:`${data[idxRef.current].city}, ${data[idxRef.current].address}`,
                            }));

                        idxRef.current++;
                        setTimeout(xxx, 2000);
                    } catch (e) {
                        console.error(e)
                    }
                    //window.currentMap.setView({ bounds: answer.results[0].bestView });
                    //window.currentMap.entities.push(new Microsoft.Maps.Pushpin(answer.results[0].location));
                },
                errorCallback: (err) => {
                    console.error(err)
                }
            };

            try {
                searchManager.geocode(requestOptions);
            } catch (e) {
                console.error(e)
            }
        });
    };


    const dire = () => {

        //if (idxRef.current >= 10) return;
        if (idxRef.current >= delalfold.length) return;

        Microsoft.Maps.loadModule('Microsoft.Maps.Directions', function () {
            let directionsManager = new Microsoft.Maps.Directions.DirectionsManager(window.currentMap);
            // Set Route Mode to driving
            directionsManager.setRequestOptions({
                routeMode: Microsoft.Maps.Directions.RouteMode.driving,
                distanceUnit: Microsoft.Maps.Directions.DistanceUnit.km
            });
            let waypoint1 = new Microsoft.Maps.Directions.Waypoint({ address: 'Szeged' });
            let waypoint2 = new Microsoft.Maps.Directions.Waypoint({ location: delalfold[idxRef.current].bestView.center });
            directionsManager.addWaypoint(waypoint1);
            directionsManager.addWaypoint(waypoint2);
            Microsoft.Maps.Events.addHandler(directionsManager, 'directionsUpdated', function () {
                const r = directionsManager.getRouteResult();
                if (r.length > 0 && r[0].routeLegs.length > 0)
                {
                    const su = r[0].routeLegs[0].summary;
                    console.log(`Szeged;${delalfold[idxRef.current].name};${delalfold[idxRef.current].city};${su.distance};${su.time}`);

                    ++idxRef.current;
                    setTimeout(dire, 2000);
                }
            });
            directionsManager.calculateDirections();
        });
    };


    const onMapReady = useCallback(evt => {
        //++idxRef.current;
        //setTimeout(dire, 200);

        if (mapDisplayQueue.current.length === 0)
            return;

        doMapSearch(mapDisplayQueue.current.shift());
    }, []);


    if (showData)
    {
        return <pre>{JSON.stringify(data, null, 3)}</pre>
    }


    return (
        <div>
            <BingMapsReact
                bingMapsKey={"Ak6C_E3kNBYRX5qX2NSZ7UbKpqH5TTXhpwH7VbMPKphEmQdq47XC_nHyovShk3WM"}
                viewOptions={{
                    center: center
                }}
                mapOptions={{
                    navigationBarMode: "square",
                    showMapTypeSelector: true,
                    disableStreetside: false,
                    supportedMapTypes:["aerial","road","streetside","birdseye"]
                }}
                pushPinsWithInfoboxes={pushPins}
                onMapReady={onMapReady}
            />
            <div style={{
                position:"absolute",
                backgroundColor: "rgba(208,224,255,0.85)",
                borderRadius: 6,
                left: 8,
                top: 8,
                right: 208,
                padding: 8,
                zIndex: 100,
                display:"flex",
                flexDirection:"row",
                columnGap: 8
            }}>
                {/*<Button title={"CLICk"}*/}
                {/*        onClick={() => setShowData(true)}*/}
                {/*/>*/}
                <div style={{flexGrow:1}}>
                    <span style={{ padding: '0 8px 0 0' }}>Régió</span>
                    <HTMLSelect
                        fill
                        value={selectedRegion}
                        onChange={changeRegion}
                    >
                        <option key={""} value={""}>-- Mind --</option>
                        {regionOptions}
                    </HTMLSelect>
                </div>
                <div style={{flexGrow:1}}>
                    <span style={{ padding: '0 8px 0 0' }}>Megye</span>
                    <HTMLSelect
                        fill
                        value={selectedCounty}
                        onChange={changeCounty}
                    >
                        <option key={""} value={""}>-- Mind --</option>
                        {countyOptions}
                    </HTMLSelect>
                </div>
                <div style={{flexGrow:1}}>
                    <span style={{ padding: '0 8px 0 0' }}>Intézmény</span>
                    <HTMLSelect
                        fill
                        value={selectedSchool}
                        onChange={changeSchool}
                    >
                        <option key={""} value={""}>-- Mind --</option>
                        {schoolOptions}
                    </HTMLSelect>
                </div>
            </div>
        </div>
    );
}


ReactDOM.render(
    // <React.StrictMode>
        <Suspense fallback="...">
            <App />
        </Suspense>
    // </React.StrictMode>
    , document.getElementById('app')
);