export const data = [
    {
        "region": "Közép-Magyarország",
        "name": " Budapesti Gépészeti SZC Katona József Technikum Székhely",
        "city": "Budapest XIII. kerület",
        "address": "Váci út 107",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.586529999999996,
                "longitude": 19.08231,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.585629999999995,
                19.08364,
                47.58743,
                19.08098
            ],
            "_northWest": {
                "latitude": 47.585629999999995,
                "longitude": 19.08098,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.58743,
                "longitude": 19.08364,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 0
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Akác Tanvendéglő",
        "city": "Kadarkút",
        "address": "Kossuth Lajos  utca 1.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 47.580431,
                "longitude": 19.0579521,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015269069212656916,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.57656828242932,
                19.06558663460633,
                47.584293717570674,
                19.050317565393673
            ],
            "_northWest": {
                "latitude": 47.57656828242932,
                "longitude": 19.050317565393673,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.584293717570674,
                "longitude": 19.06558663460633,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 1
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Alapi Gáspár utcai tanműhely",
        "city": "Szigetvár",
        "address": "Alapi Gáspár  utca 1.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.05191,
                "longitude": 17.7988,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.05101,
                17.8001,
                46.05281,
                17.7975
            ],
            "_northWest": {
                "latitude": 46.05101,
                "longitude": 17.7975,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.05281,
                "longitude": 17.8001,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 2
    },
    {
        "region": "Közép-Magyarország",
        "name": "Arany János Technikum és Szakképző Iskola Székhely",
        "city": "Budapest VII. kerület",
        "address": "Nyár utca 9.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.49744,
                "longitude": 19.06607,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.496539999999996,
                19.0674,
                47.49834,
                19.06474
            ],
            "_northWest": {
                "latitude": 47.496539999999996,
                "longitude": 19.06474,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49834,
                "longitude": 19.0674,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 3
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Bányai Júlia Technikum és Szakképző Iskola",
        "city": "Baja",
        "address": "Köztársaság tér 1",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 47.433347,
                "longitude": 19.1225408,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015226348231422548,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.42948428242932,
                19.13015397411571,
                47.437209717570674,
                19.114927625884288
            ],
            "_northWest": {
                "latitude": 47.42948428242932,
                "longitude": 19.114927625884288,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.437209717570674,
                "longitude": 19.13015397411571,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 4
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Jelky András Technikum és Szakképző Iskola ",
        "city": "Baja ",
        "address": "Petőfi S. utca 3.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.18076,
                "longitude": 18.95549,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.17986,
                18.95679,
                46.18166,
                18.95419
            ],
            "_northWest": {
                "latitude": 46.17986,
                "longitude": 18.95419,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.18166,
                "longitude": 18.95679,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 5
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Jelky András Technikum és Szakképző Iskola Szegedi Úti Telephelye",
        "city": "Baja ",
        "address": "Szegedi  út 69-73",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.18457,
                "longitude": 18.96728,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.18367,
                18.96858,
                46.18547,
                18.96598
            ],
            "_northWest": {
                "latitude": 46.18367,
                "longitude": 18.96598,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.18547,
                "longitude": 18.96858,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 6
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Jelky András Technikum és Szakképző Iskola Szent Antal Utcai Telephelye",
        "city": "Baja ",
        "address": "Szent Antal utca 17.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.1757,
                "longitude": 18.95413,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.1748,
                18.95543,
                46.1766,
                18.95283
            ],
            "_northWest": {
                "latitude": 46.1748,
                "longitude": 18.95283,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.1766,
                "longitude": 18.95543,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 7
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Kalocsai Dózsa György Technikum és Kollégium",
        "city": "Kalocsa",
        "address": "Asztrik  tér 5-7.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.53148,
                "longitude": 18.97323,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.53058,
                18.97454,
                46.53238,
                18.97192
            ],
            "_northWest": {
                "latitude": 46.53058,
                "longitude": 18.97192,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.53238,
                "longitude": 18.97454,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 8
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Kalocsai Dózsa György Technikum és Kollégium Kunszt József Utcai Telephelye",
        "city": "Kalocsa",
        "address": "Kunszt József utca 1.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.5321,
                "longitude": 18.97382,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.5312,
                18.97513,
                46.533,
                18.97251
            ],
            "_northWest": {
                "latitude": 46.5312,
                "longitude": 18.97251,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.533,
                "longitude": 18.97513,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 9
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Kalocsai Dózsa György Technikum és Kollégium Martinovics Utcai Telephelye",
        "city": "Kalocsa",
        "address": "Martinovics utca 2-4.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.53271,
                "longitude": 18.97657,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.53181,
                18.97788,
                46.53361,
                18.97526
            ],
            "_northWest": {
                "latitude": 46.53181,
                "longitude": 18.97526,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.53361,
                "longitude": 18.97788,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 10
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Kossuth Zsuzsanna Technikum",
        "city": "Kalocsa ",
        "address": "Tomori Pál utca 10",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.52793,
                "longitude": 18.97423,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.527029999999996,
                18.97554,
                46.52883,
                18.97292
            ],
            "_northWest": {
                "latitude": 46.527029999999996,
                "longitude": 18.97292,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.52883,
                "longitude": 18.97554,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 11
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Kossuth Zsuzsanna Technikum Asztrik Téri Telephelye",
        "city": "Kalocsa ",
        "address": "Asztrik  tér 5-7",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.53148,
                "longitude": 18.97323,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.53058,
                18.97454,
                46.53238,
                18.97192
            ],
            "_northWest": {
                "latitude": 46.53058,
                "longitude": 18.97192,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.53238,
                "longitude": 18.97454,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 12
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Radnóti Miklós Kollégium",
        "city": "Baja ",
        "address": "Március 15. sétány 2.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.17815,
                "longitude": 18.94754,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.17725,
                18.94884,
                46.179050000000004,
                18.94624
            ],
            "_northWest": {
                "latitude": 46.17725,
                "longitude": 18.94624,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.179050000000004,
                "longitude": 18.94884,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 13
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Radnóti Miklós Kollégium Szenes Utcai Telephelye",
        "city": "Baja ",
        "address": "Szenes utca 12.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.18128,
                "longitude": 18.94833,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.18038,
                18.94963,
                46.18218,
                18.947029999999998
            ],
            "_northWest": {
                "latitude": 46.18038,
                "longitude": 18.947029999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.18218,
                "longitude": 18.94963,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 14
    },
    {
        "region": "Dél-Alföld",
        "name": "Bajai SZC Türr István Technikum",
        "city": "Baja ",
        "address": "Bácska tér 1",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.18593,
                "longitude": 18.954935,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0007500000000000284,
            "height": -0.0002599999999972624,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.1858,
                18.955309999999997,
                46.18606,
                18.95456
            ],
            "_northWest": {
                "latitude": 46.1858,
                "longitude": 18.95456,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.18606,
                "longitude": 18.955309999999997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 15
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Angster József Szakképző Iskola és Szakiskola - Székhely",
        "city": "Pécs",
        "address": "Rét utca 41-43",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.069,
                "longitude": 18.22332,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.0681,
                18.22462,
                46.069900000000004,
                18.22202
            ],
            "_northWest": {
                "latitude": 46.0681,
                "longitude": 18.22202,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.069900000000004,
                "longitude": 18.22462,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 16
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Angster József Szakképző iskola és Szakiskola - TANMŰHELY",
        "city": "Pécs ",
        "address": "Martyn Ferenc utca 4",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.066765000000004,
                "longitude": 18.209400000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002880000000001104,
            "height": -0.0003299999999981651,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.06660000000001,
                18.210840000000005,
                46.06693,
                18.20796
            ],
            "_northWest": {
                "latitude": 46.06660000000001,
                "longitude": 18.20796,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.06693,
                "longitude": 18.210840000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 17
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Angster József Szakképző Iskola és Szakiskola -SZAKISKOLA ",
        "city": "Pécs",
        "address": "Petőfi Sándor utca 72",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.0697986,
                "longitude": 18.2177576,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014845969169662965,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.06593588242932,
                18.22518058458483,
                46.073661317570675,
                18.210334615415167
            ],
            "_northWest": {
                "latitude": 46.06593588242932,
                "longitude": 18.210334615415167,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.073661317570675,
                "longitude": 18.22518058458483,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 18
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Garai Miklós Technikum és Szakképző Iskola - Székhely",
        "city": "Siklós",
        "address": "Iskola utca 10/a",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 45.8602722,
                "longitude": 18.2908676,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014789931688916624,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.85640948242932,
                18.298262565844457,
                45.864134917570674,
                18.28347263415554
            ],
            "_northWest": {
                "latitude": 45.85640948242932,
                "longitude": 18.28347263415554,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.864134917570674,
                "longitude": 18.298262565844457,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 19
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC II. Béla Technikum és Kollégium - kollégium",
        "city": "Pécsvárad",
        "address": "Kossuth Lajos utca 2",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.158100000000005,
                "longitude": 18.424129999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.1572,
                18.42543,
                46.159000000000006,
                18.422829999999998
            ],
            "_northWest": {
                "latitude": 46.1572,
                "longitude": 18.422829999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.159000000000006,
                "longitude": 18.42543,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 20
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC II. Béla Technikum és Kollégium - leánykollégium",
        "city": "Pécsvárad",
        "address": "Szent Gellért utca 2",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.163219999999995,
                "longitude": 18.41899,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.162319999999994,
                18.42029,
                46.16412,
                18.41769
            ],
            "_northWest": {
                "latitude": 46.162319999999994,
                "longitude": 18.41769,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.16412,
                "longitude": 18.42029,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 21
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC II. Béla Technikum és Kollégium - székhely",
        "city": "Pécsvárad",
        "address": "Vak Béla utca 8",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.15675,
                "longitude": 18.41298,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.15585,
                18.41428,
                46.157650000000004,
                18.41168
            ],
            "_northWest": {
                "latitude": 46.15585,
                "longitude": 18.41168,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.157650000000004,
                "longitude": 18.41428,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 22
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Komlói Technikum, Szakképző Iskola és Kollégium - Telephely",
        "city": "Komló",
        "address": "Alkotmány utca 2.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.19357,
                "longitude": 18.26711,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.19267,
                18.26841,
                46.19447,
                18.26581
            ],
            "_northWest": {
                "latitude": 46.19267,
                "longitude": 18.26581,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.19447,
                "longitude": 18.26841,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 23
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Komlói Technikum, Szakképző Iskola és Szakiskola - Székhely",
        "city": "Komló",
        "address": "Vájáriskola utca 1.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.18614,
                "longitude": 18.25918,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.18524,
                18.26048,
                46.18704,
                18.25788
            ],
            "_northWest": {
                "latitude": 46.18524,
                "longitude": 18.25788,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.18704,
                "longitude": 18.26048,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 24
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Mohácsi Radnóti Miklós Technikum és Szakképző Iskola",
        "city": "Mohács",
        "address": "Kossuth Lajos  utca 71.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 45.99575,
                "longitude": 18.679589999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0025799999999982504,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.99485,
                18.680879999999995,
                45.99665,
                18.6783
            ],
            "_northWest": {
                "latitude": 45.99485,
                "longitude": 18.6783,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.99665,
                "longitude": 18.680879999999995,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 25
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Pollack Mihály Technikum  és Kollégium",
        "city": "Pécs",
        "address": "Batthyány  utca 1-3.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 45.9878042,
                "longitude": 18.6969949,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014823965792054139,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.98394148242932,
                18.704406882896027,
                45.991666917570676,
                18.689582917103973
            ],
            "_northWest": {
                "latitude": 45.98394148242932,
                "longitude": 18.689582917103973,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.991666917570676,
                "longitude": 18.704406882896027,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 26
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Pollack Mihály Technikum és Kollégium",
        "city": "Pécs",
        "address": "Jókai utca 8",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.073930000000004,
                "longitude": 18.22684,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.07303,
                18.22814,
                46.074830000000006,
                18.22554
            ],
            "_northWest": {
                "latitude": 46.07303,
                "longitude": 18.22554,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.074830000000006,
                "longitude": 18.22814,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 27
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Radnóti Miklós Közgazdasági Technikum",
        "city": "Pécs",
        "address": "Esztergár Lajos út 6",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.0638314,
                "longitude": 18.1946167,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014844364628636697,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.05996868242932,
                18.20203888231432,
                46.067694117570674,
                18.187194517685683
            ],
            "_northWest": {
                "latitude": 46.05996868242932,
                "longitude": 18.187194517685683,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.067694117570674,
                "longitude": 18.20203888231432,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 28
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Sásdi Vendéglátóipari Szakképző Iskola - Székhely",
        "city": "Sásd",
        "address": "Kossuth Lajos utca 2.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.251035,
                "longitude": 18.112085,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0022300000000008424,
            "height": -0.0017700000000004934,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.250150000000005,
                18.1132,
                46.25192,
                18.110970000000002
            ],
            "_northWest": {
                "latitude": 46.250150000000005,
                "longitude": 18.110970000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25192,
                "longitude": 18.1132,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 29
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Sásdi Vendéglátóipari Szakképző Iskola - Telephely",
        "city": "Sásd",
        "address": "Szent Imre  út 23.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.25214,
                "longitude": 18.10973,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.251239999999996,
                18.11103,
                46.25304,
                18.10843
            ],
            "_northWest": {
                "latitude": 46.251239999999996,
                "longitude": 18.10843,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25304,
                "longitude": 18.11103,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 30
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Simonyi Károly Technikum és Szakképző Iskola - Székhely",
        "city": "Pécs",
        "address": "Malomvölgyi utca 1/b.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.1348695,
                "longitude": 18.3267545,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014863499323837459,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.131006782429324,
                18.33418624966192,
                46.13873221757068,
                18.31932275033808
            ],
            "_northWest": {
                "latitude": 46.131006782429324,
                "longitude": 18.31932275033808,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.13873221757068,
                "longitude": 18.33418624966192,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 31
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Zipernowsky Károly Műszaki Technikum",
        "city": "Pécs",
        "address": "48-as tér 2.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.07373,
                "longitude": 18.23935,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0027200000000000557,
            "height": -0.0018799999999998818,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.07279,
                18.24071,
                46.07467,
                18.237990000000003
            ],
            "_northWest": {
                "latitude": 46.07279,
                "longitude": 18.237990000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.07467,
                "longitude": 18.24071,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 32
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Zrínyi Miklós Gimnázium és Szakképző Iskola",
        "city": "Szigetvár",
        "address": "Rákóczi utca 18.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.04511,
                "longitude": 17.80107,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.04421,
                17.80237,
                46.04601,
                17.79977
            ],
            "_northWest": {
                "latitude": 46.04421,
                "longitude": 17.79977,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.04601,
                "longitude": 17.80237,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 33
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Baranya Megyei SZC Zsolnay Vilmos Technikum és Szakképző Iskola - Székhely",
        "city": "Pécs",
        "address": "Rét utca 10.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.06794,
                "longitude": 18.22308,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.06704,
                18.22438,
                46.06884,
                18.22178
            ],
            "_northWest": {
                "latitude": 46.06704,
                "longitude": 18.22178,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.06884,
                "longitude": 18.22438,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 34
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Barcs",
        "city": "Barcs",
        "address": "Szent László utca 13",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 45.9623,
                "longitude": 17.454140000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0025799999999982504,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.9614,
                17.45543,
                45.9632,
                17.452850000000005
            ],
            "_northWest": {
                "latitude": 45.9614,
                "longitude": 17.452850000000005,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.9632,
                "longitude": 17.45543,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 35
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai Szakképzési Centrum Széchenyi István Két Tanítási Nyelvű Közgazdasági Technikum és Kollégium  Luther Utcai Telephelye",
        "city": "Békéscsaba",
        "address": "Luther utca 2",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.68173,
                "longitude": 21.0956,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.68083,
                21.09691,
                46.68263,
                21.09429
            ],
            "_northWest": {
                "latitude": 46.68083,
                "longitude": 21.09429,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.68263,
                "longitude": 21.09691,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 36
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai Szakképzési Centrum Széchenyi István Két Tanítási Nyelvű Közgazdasági Technikum és Kollégium Lencsési Úti Telephelye (Kollégium)",
        "city": "Békéscsaba",
        "address": "Lencsési út 136.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.66696,
                "longitude": 21.12752,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.66606,
                21.12883,
                46.667860000000005,
                21.12621
            ],
            "_northWest": {
                "latitude": 46.66606,
                "longitude": 21.12621,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.667860000000005,
                "longitude": 21.12883,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 37
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Kemény Gábor Technikum Székhely",
        "city": "Békéscsaba",
        "address": "Gábor köz 1.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.67516,
                "longitude": 21.0898,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.67426,
                21.09111,
                46.67606,
                21.08849
            ],
            "_northWest": {
                "latitude": 46.67426,
                "longitude": 21.08849,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.67606,
                "longitude": 21.09111,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 38
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Kós Károly Szakgimnáziuma és Szakközépiskolája Székhely",
        "city": "Békéscsaba",
        "address": "Kazinczy utca 8",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.67718,
                "longitude": 21.08134,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.67628,
                21.08265,
                46.67808,
                21.08003
            ],
            "_northWest": {
                "latitude": 46.67628,
                "longitude": 21.08003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.67808,
                "longitude": 21.08265,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 39
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Kós Károly Technikum és Szakképző Iskola Ipari Úti Telephelye 5",
        "city": "Békéscsaba",
        "address": "Ipari út 5",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.69504,
                "longitude": 21.08753,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.69414,
                21.08884,
                46.69594,
                21.08622
            ],
            "_northWest": {
                "latitude": 46.69414,
                "longitude": 21.08622,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.69594,
                "longitude": 21.08884,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 40
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Kós Károly Technikum és Szakképző Iskola Ipari Úti Telephelye 5/5",
        "city": "Békéscsaba",
        "address": "Ipari út 5/5",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.69504,
                "longitude": 21.08753,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.69414,
                21.08884,
                46.69594,
                21.08622
            ],
            "_northWest": {
                "latitude": 46.69414,
                "longitude": 21.08622,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.69594,
                "longitude": 21.08884,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 41
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Nemes Tihamér Technikum és Kollégium",
        "city": "Békéscsaba",
        "address": "Kazinczy utca 7.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.67561,
                "longitude": 21.08266,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.67471,
                21.08397,
                46.67651,
                21.08135
            ],
            "_northWest": {
                "latitude": 46.67471,
                "longitude": 21.08135,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.67651,
                "longitude": 21.08397,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 42
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Nemes Tihamér Technikum és Kollégium (kollégium)",
        "city": "Békéscsaba",
        "address": "Trefort utca 2.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.67859,
                "longitude": 21.08439,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.67769,
                21.0857,
                46.67949,
                21.08308
            ],
            "_northWest": {
                "latitude": 46.67769,
                "longitude": 21.08308,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.67949,
                "longitude": 21.0857,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 43
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Széchenyi Székhely",
        "city": "Békéscsaba",
        "address": "Irányi utca 3-5.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.68015,
                "longitude": 21.09576,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.679249999999996,
                21.09707,
                46.68105,
                21.09445
            ],
            "_northWest": {
                "latitude": 46.679249999999996,
                "longitude": 21.09445,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.68105,
                "longitude": 21.09707,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 44
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Szent-Györgyi Albert Technikum és Kollégium",
        "city": "Békéscsaba",
        "address": "Gyula út 53-57",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.680416,
                "longitude": 21.1152384,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015012881877517259,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.676553282429325,
                21.122744840938758,
                46.68427871757068,
                21.10773195906124
            ],
            "_northWest": {
                "latitude": 46.676553282429325,
                "longitude": 21.10773195906124,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.68427871757068,
                "longitude": 21.122744840938758,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 45
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Szent-Györgyi Albert Technikum és Kollégium (Telephely)",
        "city": "Békéscsaba",
        "address": "Szigligeti utca 6",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.67373,
                "longitude": 21.08792,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.67283,
                21.08923,
                46.67463,
                21.08661
            ],
            "_northWest": {
                "latitude": 46.67283,
                "longitude": 21.08661,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.67463,
                "longitude": 21.08923,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 46
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Trefort Ágoston Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Békéscsaba",
        "address": "Puskin tér 1.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.68238,
                "longitude": 21.10746,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.68148,
                21.10877,
                46.68328,
                21.10615
            ],
            "_northWest": {
                "latitude": 46.68148,
                "longitude": 21.10615,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.68328,
                "longitude": 21.10877,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 47
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Vásárhelyi Pál Technikum és Kollégium",
        "city": "Békéscsaba",
        "address": "Deák utca 6.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.6803,
                "longitude": 21.10369,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.6794,
                21.105,
                46.681200000000004,
                21.10238
            ],
            "_northWest": {
                "latitude": 46.6794,
                "longitude": 21.10238,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.681200000000004,
                "longitude": 21.105,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 48
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SzC Zwack József Technikum és Szakképző Iskola Gyulai Úti Telephelye",
        "city": "Békéscsaba",
        "address": "Gyulai út 32/1",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.681470000000004,
                "longitude": 21.11442,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.68057,
                21.11573,
                46.682370000000006,
                21.11311
            ],
            "_northWest": {
                "latitude": 46.68057,
                "longitude": 21.11311,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.682370000000006,
                "longitude": 21.11573,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 49
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SzC Zwack József Technikum és Szakképző Iskola Pásztor Utcai Telephelye",
        "city": "Békéscsaba",
        "address": "Pásztor utca 17",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.66906,
                "longitude": 21.11711,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.66816,
                21.11842,
                46.66996,
                21.1158
            ],
            "_northWest": {
                "latitude": 46.66816,
                "longitude": 21.1158,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.66996,
                "longitude": 21.11842,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 50
    },
    {
        "region": "Dél-Alföld",
        "name": "Békéscsabai SZC Zwack József Technikum és Szakképző Iskola Székhely",
        "city": "Békéscsaba",
        "address": "Gyulai út 32",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.681470000000004,
                "longitude": 21.11442,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.68057,
                21.11573,
                46.682370000000006,
                21.11311
            ],
            "_northWest": {
                "latitude": 46.68057,
                "longitude": 21.11311,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.682370000000006,
                "longitude": 21.11573,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 51
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Arany János Gimnázium és Technikum",
        "city": "Berettyóújfalu",
        "address": "Kossuth L. utca 35.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.22749,
                "longitude": 21.5342,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.22659,
                21.535519999999998,
                47.228390000000005,
                21.53288
            ],
            "_northWest": {
                "latitude": 47.22659,
                "longitude": 21.53288,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.228390000000005,
                "longitude": 21.535519999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 52
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Bessenyei György Technikum",
        "city": "Berettyóújfalu",
        "address": "Honvéd utca 11.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.23741,
                "longitude": 21.53484,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.236509999999996,
                21.53616,
                47.23831,
                21.53352
            ],
            "_northWest": {
                "latitude": 47.236509999999996,
                "longitude": 21.53352,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.23831,
                "longitude": 21.53616,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 53
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Bocskai István Szakképző Iskola",
        "city": "Hajdúszoboszló",
        "address": "József Attila út 25/C",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.44827,
                "longitude": 21.39991,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.44737,
                21.40124,
                47.44917,
                21.398579999999995
            ],
            "_northWest": {
                "latitude": 47.44737,
                "longitude": 21.398579999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.44917,
                "longitude": 21.40124,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 54
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Bocskai István Szakképző Iskola és Kollégium",
        "city": "Biharkeresztes",
        "address": "Ady Endre utca 2",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.13015,
                "longitude": 21.71627,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.12925,
                21.71759,
                47.13105,
                21.71495
            ],
            "_northWest": {
                "latitude": 47.12925,
                "longitude": 21.71495,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.13105,
                "longitude": 21.71759,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 55
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Csiha Győző Technikum és Szakképző Iskola",
        "city": "Hajdúnánás",
        "address": "Baross  utca 11",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.83542,
                "longitude": 21.4221,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.83452,
                21.42344,
                47.83632,
                21.42076
            ],
            "_northWest": {
                "latitude": 47.83452,
                "longitude": 21.42076,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.83632,
                "longitude": 21.42344,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 56
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Eötvös József Szakképző Iskola Székhely",
        "city": "Berettyóújfalu",
        "address": "Eötvös utca 1.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.222899999999996,
                "longitude": 21.53327,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00002000000000279556,
            "height": -0.00005999999999772854,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.22287,
                21.533280000000005,
                47.22292999999999,
                21.53326
            ],
            "_northWest": {
                "latitude": 47.22287,
                "longitude": 21.53326,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.22292999999999,
                "longitude": 21.533280000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 57
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC József Attila Szakképző Iskola Széchenyi utcai Telephelye ",
        "city": "Polgár",
        "address": "Széchenyi utca 11-13",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.87241,
                "longitude": 21.11587,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.87151,
                21.11721,
                47.873310000000004,
                21.114530000000002
            ],
            "_northWest": {
                "latitude": 47.87151,
                "longitude": 21.114530000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.873310000000004,
                "longitude": 21.11721,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 58
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC József Attila Szakképző Iskola Székhely",
        "city": "Polgár",
        "address": "Kiss Ernő utca 10",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.872730000000004,
                "longitude": 21.11143,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.87183,
                21.112769999999998,
                47.873630000000006,
                21.11009
            ],
            "_northWest": {
                "latitude": 47.87183,
                "longitude": 21.11009,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.873630000000006,
                "longitude": 21.112769999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 59
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Karacs Ferenc Gimnázium, Technikum és Szakképző Iskola",
        "city": "Püspökladány",
        "address": "Gagarin utca 2.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.319410000000005,
                "longitude": 21.114620000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.31851,
                21.115950000000005,
                47.320310000000006,
                21.11329
            ],
            "_northWest": {
                "latitude": 47.31851,
                "longitude": 21.11329,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.320310000000006,
                "longitude": 21.115950000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 60
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Közgazdasági Technikum Székhely",
        "city": "Hajdúszoboszló",
        "address": "Gönczy Pál utca 17.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.44482,
                "longitude": 21.3866,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0029199999999995896,
            "height": -0.0012600000000020373,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.44419,
                21.388060000000003,
                47.44545,
                21.38514
            ],
            "_northWest": {
                "latitude": 47.44419,
                "longitude": 21.38514,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.44545,
                "longitude": 21.388060000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 61
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Szilágyi Dániel Szakképző Iskola Székhely",
        "city": "Hajdúhadház",
        "address": "Bocskai  tér 6",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.682900000000004,
                "longitude": 21.666359999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.682,
                21.667699999999996,
                47.683800000000005,
                21.66502
            ],
            "_northWest": {
                "latitude": 47.682,
                "longitude": 21.66502,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.683800000000005,
                "longitude": 21.667699999999996,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 62
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Veres Péter Gimnázium, Technikum és Szakképző Iskola Batthyány Utcai Telephelye",
        "city": "Balmazújváros",
        "address": "Batthyány utca 18.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.60992,
                "longitude": 21.34581,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.60902,
                21.34714,
                47.610820000000004,
                21.34448
            ],
            "_northWest": {
                "latitude": 47.60902,
                "longitude": 21.34448,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.610820000000004,
                "longitude": 21.34714,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 63
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Veres Péter Gimnázium, Technikum és Szakképző Iskola Böszörményi Utcai Telephelye",
        "city": "Balmazújváros",
        "address": "Böszörményi  utca 98.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.62536,
                "longitude": 21.356099999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.62446,
                21.35743,
                47.62626,
                21.354769999999995
            ],
            "_northWest": {
                "latitude": 47.62446,
                "longitude": 21.354769999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.62626,
                "longitude": 21.35743,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 64
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Veres Péter Gimnázium, Technikum és Szakképző Iskola Székhely",
        "city": "Balmazújváros",
        "address": "Batthyány utca 7",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.6105,
                "longitude": 21.34366,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.6096,
                21.34499,
                47.6114,
                21.34233
            ],
            "_northWest": {
                "latitude": 47.6096,
                "longitude": 21.34233,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.6114,
                "longitude": 21.34499,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 65
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Veress Ferenc Szakképző Iskola Kálvineum Telephely",
        "city": "Hajdúböszörmény",
        "address": "Kálvineum  utca 12",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.67704,
                "longitude": 21.505580000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.67614,
                21.50692,
                47.67794,
                21.504240000000003
            ],
            "_northWest": {
                "latitude": 47.67614,
                "longitude": 21.504240000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.67794,
                "longitude": 21.50692,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 66
    },
    {
        "region": "Észak-Alföld",
        "name": "Berettyóújfalui SZC Veress Ferenc Szakképző Iskola Székhely",
        "city": "Hajdúböszörmény",
        "address": "Enyingi Török Bálint utca 5/a",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.6786,
                "longitude": 21.50449,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.6777,
                21.50583,
                47.679500000000004,
                21.50315
            ],
            "_northWest": {
                "latitude": 47.6777,
                "longitude": 21.50315,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.679500000000004,
                "longitude": 21.50583,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 67
    },
    {
        "region": "Közép-Magyarország",
        "name": "BGSzC Budai Gimnázium és Szakgimnázium",
        "city": "Budapest",
        "address": "Márvány utca 32",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.49503,
                "longitude": 19.024459999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49413,
                19.02579,
                47.49593,
                19.023129999999995
            ],
            "_northWest": {
                "latitude": 47.49413,
                "longitude": 19.023129999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49593,
                "longitude": 19.02579,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 68
    },
    {
        "region": "Közép-Magyarország",
        "name": "BGSzC Budai Gimnázium és Szakgimnázium Pesti Magyar Színiakadémia Művészeti Intézményegysége",
        "city": "Budapest XII. kerület",
        "address": "Márvány utca 32",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.49503,
                "longitude": 19.024459999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49413,
                19.02579,
                47.49593,
                19.023129999999995
            ],
            "_northWest": {
                "latitude": 47.49413,
                "longitude": 19.023129999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49593,
                "longitude": 19.02579,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 69
    },
    {
        "region": "Közép-Magyarország",
        "name": "BKSZC Kézművesipari Technikum",
        "city": "Budapest IX. kerület",
        "address": "Tüzoltó utca 79",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.409147613761,
                "longitude": 18.998322019151,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015219351935328973,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.405284896190324,
                19.005931695118665,
                47.41301033133168,
                18.990712343183336
            ],
            "_northWest": {
                "latitude": 47.405284896190324,
                "longitude": 18.990712343183336,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.41301033133168,
                "longitude": 19.005931695118665,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 70
    },
    {
        "region": "Közép-Magyarország",
        "name": "BMSZC Bolyai János Műszaki Technikum és Kollégium",
        "city": "Budapest XIII. kerület",
        "address": "Váci út 21.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.522381,
                "longitude": 19.0627441,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015252167805847705,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.518518282429326,
                19.070370183902924,
                47.52624371757068,
                19.055118016097076
            ],
            "_northWest": {
                "latitude": 47.518518282429326,
                "longitude": 19.055118016097076,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52624371757068,
                "longitude": 19.070370183902924,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 71
    },
    {
        "region": "Közép-Magyarország",
        "name": "BMSZC Bolyai Kollégium",
        "city": "Budapest XIII. kerület",
        "address": "Angyalföldi út 2.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.5219749,
                "longitude": 19.063477,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015252049755709152,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.51811218242932,
                19.071103024877853,
                47.52583761757067,
                19.055850975122144
            ],
            "_northWest": {
                "latitude": 47.51811218242932,
                "longitude": 19.055850975122144,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52583761757067,
                "longitude": 19.071103024877853,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 72
    },
    {
        "region": "Közép-Magyarország",
        "name": "BMSZC Neumann János Informatikai Technikum",
        "city": "Budapest XIV. kerület",
        "address": "Kerepesi út 124.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50475,
                "longitude": 19.146430000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50385,
                19.147760000000005,
                47.50565,
                19.1451
            ],
            "_northWest": {
                "latitude": 47.50385,
                "longitude": 19.1451,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50565,
                "longitude": 19.147760000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 73
    },
    {
        "region": "Közép-Magyarország",
        "name": "BMSZC Than Károly Ökoiskola és Technikum",
        "city": "Budapest II. kerület",
        "address": "Lajos utca 1-5.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.518781,
                "longitude": 19.1753449,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015251121403984769,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.51491828242932,
                19.18297046070199,
                47.52264371757067,
                19.167719339298007
            ],
            "_northWest": {
                "latitude": 47.51491828242932,
                "longitude": 19.167719339298007,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52264371757067,
                "longitude": 19.18297046070199,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 74
    },
    {
        "region": "Észak-Alföld",
        "name": "BSZC Csiha Győző Technikum és SZI Bocskai utca 29. alatti telephelye",
        "city": "Hajdúnánás",
        "address": "Bocskai  utca 29",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.83982,
                "longitude": 21.42718,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.83892,
                21.42852,
                47.840720000000005,
                21.42584
            ],
            "_northWest": {
                "latitude": 47.83892,
                "longitude": 21.42584,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.840720000000005,
                "longitude": 21.42852,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 75
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Békésy György Technikum",
        "city": "Budapest XVII. kerület",
        "address": "Széchenyi utca 9-11.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.5510499,
                "longitude": 18.9601088,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015260508193094324,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.547187182429326,
                18.967739054096548,
                47.55491261757068,
                18.952478545903453
            ],
            "_northWest": {
                "latitude": 47.547187182429326,
                "longitude": 18.952478545903453,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.55491261757068,
                "longitude": 18.967739054096548,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 76
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Belvárosi Technikum",
        "city": "Budapest VII. kerület",
        "address": "Dohány utca 65",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.49995,
                "longitude": 19.07253,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49905,
                19.07386,
                47.50085,
                19.0712
            ],
            "_northWest": {
                "latitude": 47.49905,
                "longitude": 19.0712,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50085,
                "longitude": 19.07386,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 77
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Berzeviczy Gergely Két Tanítási Nyelvű Közgazdasági Technikum",
        "city": "Budapest IV. kerület",
        "address": "Baross  utca 72.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.489689999999996,
                "longitude": 19.07657,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.488789999999995,
                19.0779,
                47.49059,
                19.07524
            ],
            "_northWest": {
                "latitude": 47.488789999999995,
                "longitude": 19.07524,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49059,
                "longitude": 19.0779,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 78
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Budai Technikum",
        "city": "Budapest XII. kerület",
        "address": "Márvány utca 32",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.49503,
                "longitude": 19.024459999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49413,
                19.02579,
                47.49593,
                19.023129999999995
            ],
            "_northWest": {
                "latitude": 47.49413,
                "longitude": 19.023129999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49593,
                "longitude": 19.02579,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 79
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Csete Balázs Technikum",
        "city": "Budapest",
        "address": "Csete Balázs utca 6-8.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.43399,
                "longitude": 19.07482,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.43309,
                19.07615,
                47.43489,
                19.07349
            ],
            "_northWest": {
                "latitude": 47.43309,
                "longitude": 19.07349,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.43489,
                "longitude": 19.07615,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 80
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Dobos C. József Vendéglátóipari Technikum és Szakképző Iskola",
        "city": "Budapest XIII. kerület",
        "address": "Huba utca 7.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.524858,
                "longitude": 19.0657501,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015252887906832768,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.520995282429325,
                19.073376543953415,
                47.52872071757068,
                19.058123656046583
            ],
            "_northWest": {
                "latitude": 47.520995282429325,
                "longitude": 19.058123656046583,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52872071757068,
                "longitude": 19.073376543953415,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 81
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Giorgio Perlasca Vendéglátóipari Technikum és Szakképző Iskola",
        "city": "Budapest X. kerület",
        "address": "Maglódi út 8.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.48554,
                "longitude": 19.15606,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.48464,
                19.15739,
                47.48644,
                19.15473
            ],
            "_northWest": {
                "latitude": 47.48464,
                "longitude": 19.15473,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.48644,
                "longitude": 19.15739,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 82
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Harsányi János Technikum",
        "city": "Budapest IX. kerület",
        "address": "Ifjúmunkás utca 31.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.46617,
                "longitude": 19.11944,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.46527,
                19.12077,
                47.46707,
                19.11811
            ],
            "_northWest": {
                "latitude": 47.46527,
                "longitude": 19.11811,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.46707,
                "longitude": 19.12077,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 83
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Hunfalvy János Két Tanítási Nyelvű Közgazdasági Technikum",
        "city": "Budapest I. kerület",
        "address": "Ponty utca 3",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.5014529,
                "longitude": 19.0377961,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015246087543985709,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49759018242932,
                19.045419143771994,
                47.50531561757067,
                19.03017305622801
            ],
            "_northWest": {
                "latitude": 47.49759018242932,
                "longitude": 19.03017305622801,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50531561757067,
                "longitude": 19.045419143771994,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 84
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC II. Rákóczi Ferenc Technikum",
        "city": "Budapest VII. kerület",
        "address": "Wesselényi utca 38",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.49951,
                "longitude": 19.06686,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49861,
                19.06819,
                47.50041,
                19.065529999999995
            ],
            "_northWest": {
                "latitude": 47.49861,
                "longitude": 19.065529999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50041,
                "longitude": 19.06819,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 85
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Károlyi Mihály Két Tanítási Nyelvű Közgazdasági Technikum",
        "city": "Budapest XIII. kerület",
        "address": "Váci út 89",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.5370739,
                "longitude": 19.0690509,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015256440663783621,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.533211182429326,
                19.076679120331892,
                47.54093661757068,
                19.06142267966811
            ],
            "_northWest": {
                "latitude": 47.533211182429326,
                "longitude": 19.06142267966811,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.54093661757068,
                "longitude": 19.076679120331892,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 86
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Keleti Károly Közgazdasági Technikum",
        "city": "Budapest X. kerület",
        "address": "Gyakorló utca 21-23",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50199,
                "longitude": 19.1481,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50109,
                19.14943,
                47.50289,
                19.14677
            ],
            "_northWest": {
                "latitude": 47.50109,
                "longitude": 19.14677,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50289,
                "longitude": 19.14943,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 87
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Pesterzsébeti Technikum",
        "city": "Budapest XX. kerület",
        "address": "Vörösmarty utca 30",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50729,
                "longitude": 19.06568,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.506389999999996,
                19.06701,
                47.50819,
                19.06435
            ],
            "_northWest": {
                "latitude": 47.506389999999996,
                "longitude": 19.06435,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50819,
                "longitude": 19.06701,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 88
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Pestszentlőrinci Technikum",
        "city": "Budapest XVIII. kerület",
        "address": "Hengersor út 34.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.555279999999996,
                "longitude": 19.06897,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00014000000000180535,
            "height": -0.00013999999999469992,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.55521,
                19.06904,
                47.55534999999999,
                19.0689
            ],
            "_northWest": {
                "latitude": 47.55521,
                "longitude": 19.0689,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.55534999999999,
                "longitude": 19.06904,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 89
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Szász Ferenc Kereskedelmi Technikum és Szakképző Iskola",
        "city": "Budapest VIII. kerület",
        "address": "Szörény utca 2-4.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.498994,
                "longitude": 19.1068059,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015245373609047874,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49513128242933,
                19.114428586804525,
                47.50285671757068,
                19.099183213195477
            ],
            "_northWest": {
                "latitude": 47.49513128242933,
                "longitude": 19.099183213195477,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50285671757068,
                "longitude": 19.114428586804525,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 90
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Széchenyi István Kereskedelmi Technikum",
        "city": "Budapest VIII. kerület",
        "address": "Vas utca 9-11.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4944229,
                "longitude": 19.0661659,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015244046654856902,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49056018242933,
                19.07378792332743,
                47.49828561757068,
                19.058543876672573
            ],
            "_northWest": {
                "latitude": 47.49056018242933,
                "longitude": 19.058543876672573,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49828561757068,
                "longitude": 19.07378792332743,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 91
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Szent István Technikum és Kollégium",
        "city": "Budapest IX. kerület",
        "address": "Mester utca 56-58.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.47766,
                "longitude": 19.07543,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.47676,
                19.07676,
                47.47856,
                19.0741
            ],
            "_northWest": {
                "latitude": 47.47676,
                "longitude": 19.0741,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.47856,
                "longitude": 19.07676,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 92
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Teleki Blanka Közgazdasági Technikum",
        "city": "Budapest IX. kerület",
        "address": "Mester utca 23.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.47871,
                "longitude": 19.07315,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.47781,
                19.07448,
                47.47961,
                19.071819999999995
            ],
            "_northWest": {
                "latitude": 47.47781,
                "longitude": 19.071819999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.47961,
                "longitude": 19.07448,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 93
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Terézvárosi Technikum és Szakképző Iskola",
        "city": "Budapest VI. kerület",
        "address": "Szondi utca 41",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.51142,
                "longitude": 19.06609,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.51052,
                19.06742,
                47.51232,
                19.06476
            ],
            "_northWest": {
                "latitude": 47.51052,
                "longitude": 19.06476,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.51232,
                "longitude": 19.06742,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 94
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Varga István Közgazdasági Technikum",
        "city": "Budapest III. kerület",
        "address": "Hatvany Lajos utca 7.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.6017229,
                "longitude": 19.0669111,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015275281729948631,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.59786018242932,
                19.074548740864973,
                47.605585617570675,
                19.059273459135024
            ],
            "_northWest": {
                "latitude": 47.59786018242932,
                "longitude": 19.059273459135024,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.605585617570675,
                "longitude": 19.074548740864973,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 95
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gazdasági SZC Vásárhelyi Pál Technikum",
        "city": "Budapest XXI. kerület",
        "address": "Széchenyi utca 95.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.51578,
                "longitude": 19.07436,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.51488,
                19.07569,
                47.51668,
                19.073029999999996
            ],
            "_northWest": {
                "latitude": 47.51488,
                "longitude": 19.073029999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.51668,
                "longitude": 19.07569,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 96
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Bánki Donát Technikum Székhely",
        "city": "Budapest XIII. kerület",
        "address": "Váci út 179-183.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.553866,
                "longitude": 19.075577,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015261328154110743,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.55000328242932,
                19.083207664077054,
                47.557728717570676,
                19.067946335922944
            ],
            "_northWest": {
                "latitude": 47.55000328242932,
                "longitude": 19.067946335922944,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.557728717570676,
                "longitude": 19.083207664077054,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 97
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Bethlen Gábor Technikum Székhely",
        "city": "Budapest XV. kerület",
        "address": "Árendás köz 8.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.54176,
                "longitude": 19.1462,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.540859999999995,
                19.14753,
                47.54266,
                19.14487
            ],
            "_northWest": {
                "latitude": 47.540859999999995,
                "longitude": 19.14487,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.54266,
                "longitude": 19.14753,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 98
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Csonka János Technikum és Szakképző Iskola Székhely",
        "city": "Budapest XVI. kerület",
        "address": "Arany János  utca 55",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.55379,
                "longitude": 19.11949,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.55289,
                19.12082,
                47.55469,
                19.11816
            ],
            "_northWest": {
                "latitude": 47.55289,
                "longitude": 19.11816,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.55469,
                "longitude": 19.12082,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 99
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Eötvös Loránd Technikum; Székhely",
        "city": "Budapest XX. kerület",
        "address": "Török Fóris utca 89.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4377,
                "longitude": 19.107715,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0008499999999997954,
            "height": -0.0006800000000026785,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.43736,
                19.10814,
                47.43804,
                19.10729
            ],
            "_northWest": {
                "latitude": 47.43736,
                "longitude": 19.10729,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.43804,
                "longitude": 19.10814,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 100
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Fáy András Technikum Székhely",
        "city": "Budapest IX. kerület",
        "address": "Mester utca 60-62",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.47689,
                "longitude": 19.07665,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.475989999999996,
                19.07798,
                47.47779,
                19.07532
            ],
            "_northWest": {
                "latitude": 47.475989999999996,
                "longitude": 19.07532,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.47779,
                "longitude": 19.07798,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 101
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Ganz Ábrahám Két Tanítási Nyelvű Technikum",
        "city": "Budapest XIX. kerület",
        "address": "Üllői út 303.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.452169999999995,
                "longitude": 19.15685,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.451269999999994,
                19.15818,
                47.45307,
                19.155519999999996
            ],
            "_northWest": {
                "latitude": 47.451269999999994,
                "longitude": 19.155519999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.45307,
                "longitude": 19.15818,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 102
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Kossuth Lajos Két Tanítási Nyelvű Technikum  Székhely",
        "city": "Budapest XXI. kerület",
        "address": "Kossuth Lajos utca 12",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.45231,
                "longitude": 19.14985,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.451409999999996,
                19.15118,
                47.45321,
                19.14852
            ],
            "_northWest": {
                "latitude": 47.451409999999996,
                "longitude": 19.14852,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.45321,
                "longitude": 19.15118,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 103
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Magyar Hajózási Technikum Székhely",
        "city": "Budapest XIII. kerület",
        "address": "Jász utca 155",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.543040000000005,
                "longitude": 19.0905,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.54214,
                19.09183,
                47.543940000000006,
                19.089169999999996
            ],
            "_northWest": {
                "latitude": 47.54214,
                "longitude": 19.089169999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.543940000000006,
                "longitude": 19.09183,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 104
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Mechatronikai Technikum Székhely",
        "city": "Budapest XI. kerület",
        "address": "Rétköz utca 39.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.47034,
                "longitude": 19.002029999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.46944,
                19.00336,
                47.47124,
                19.000699999999995
            ],
            "_northWest": {
                "latitude": 47.46944,
                "longitude": 19.000699999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.47124,
                "longitude": 19.00336,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 105
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Öveges József Technikum és Szakképző Iskola",
        "city": "Budapest XI. kerület",
        "address": "Beregszász út 10",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.47037,
                "longitude": 19.00734,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.46947,
                19.00867,
                47.471270000000004,
                19.00601
            ],
            "_northWest": {
                "latitude": 47.46947,
                "longitude": 19.00601,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.471270000000004,
                "longitude": 19.00867,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 106
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Öveges József Technikum és Szakképző Iskola Székhely",
        "city": "Budapest XI. kerület",
        "address": "Fehérvári  út 10.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.47584,
                "longitude": 19.04726,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.47494,
                19.048590000000004,
                47.47674,
                19.04593
            ],
            "_northWest": {
                "latitude": 47.47494,
                "longitude": 19.04593,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.47674,
                "longitude": 19.048590000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 107
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Szily Kálmán Technikum és Kollégium Székhely",
        "city": "Budapest IX. kerület",
        "address": "Timót utca 3",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4522,
                "longitude": 19.093239,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015231805174281021,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.44833728242932,
                19.10085490258714,
                47.456062717570674,
                19.08562309741286
            ],
            "_northWest": {
                "latitude": 47.44833728242932,
                "longitude": 19.08562309741286,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.456062717570674,
                "longitude": 19.10085490258714,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 108
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Gépészeti SZC Szily Kálmán Technikum és Kollégium Tolnai Lajos utcai telephely",
        "city": "Budapest VIII. kerület",
        "address": "Tolnai Lajos utca 4-10",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.494969999999995,
                "longitude": 19.0741,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.494069999999994,
                19.075430000000004,
                47.49587,
                19.07277
            ],
            "_northWest": {
                "latitude": 47.494069999999994,
                "longitude": 19.07277,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49587,
                "longitude": 19.075430000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 109
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex Szakképzési Centrum Erzsébet Királyné Szépészeti Technikum Weiner",
        "city": "Budapest VI. kerület",
        "address": "Weiner Leó utca 6.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50693,
                "longitude": 19.05648,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.506029999999996,
                19.05781,
                47.50783,
                19.05515
            ],
            "_northWest": {
                "latitude": 47.506029999999996,
                "longitude": 19.05515,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50783,
                "longitude": 19.05781,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 110
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex Szakképzési Centrum Weiss Manfréd Technikum, Szakképző Iskola és Kollégium",
        "city": "Budapest",
        "address": "Tanműhely köz 7",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.41948,
                "longitude": 19.06023,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.41858,
                19.06156,
                47.42038,
                19.0589
            ],
            "_northWest": {
                "latitude": 47.41858,
                "longitude": 19.0589,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.42038,
                "longitude": 19.06156,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 111
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Erzsébet Királyné Szépészeti Technikum Székhely",
        "city": "Budapest XX. kerület",
        "address": "Kossuth Lajos utca 35.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.426,
                "longitude": 19.03859,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.4251,
                19.03992,
                47.4269,
                19.03726
            ],
            "_northWest": {
                "latitude": 47.4251,
                "longitude": 19.03726,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.4269,
                "longitude": 19.03992,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 112
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Gundel Károly  Vendéglátó és Turisztikai Technikum Székhely",
        "city": "Budapest IX. kerület",
        "address": "Ecseri út 7.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4682059,
                "longitude": 19.1066461,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01523644239892974,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.464343182429324,
                19.114264321199464,
                47.47206861757068,
                19.099027878800534
            ],
            "_northWest": {
                "latitude": 47.464343182429324,
                "longitude": 19.099027878800534,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.47206861757068,
                "longitude": 19.114264321199464,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 113
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Kaesz Gyula Faipari Technikum és Szakképző Iskola Székhely",
        "city": "Budapest XIV. kerület",
        "address": "Egressy út 36.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50886,
                "longitude": 19.10908,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50796,
                19.11041,
                47.50976,
                19.107749999999996
            ],
            "_northWest": {
                "latitude": 47.50796,
                "longitude": 19.107749999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50976,
                "longitude": 19.11041,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 114
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Kézművesipari Technikum",
        "city": "Budapest VIII. kerület",
        "address": "Práter utca 31",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.486664,
                "longitude": 19.0754589,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015241795058301477,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.48280128242932,
                19.08307979752915,
                47.490526717570674,
                19.06783800247085
            ],
            "_northWest": {
                "latitude": 47.48280128242932,
                "longitude": 19.06783800247085,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.490526717570674,
                "longitude": 19.08307979752915,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 115
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Kozma Lajos Faipari Technikum Székhely",
        "city": "Budapest IV. kerület",
        "address": "Deák Ferenc utca 40.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.43136,
                "longitude": 19.07698,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.43046,
                19.07831,
                47.43226,
                19.07565
            ],
            "_northWest": {
                "latitude": 47.43046,
                "longitude": 19.07565,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.43226,
                "longitude": 19.07831,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 116
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SzC Kreatív Technikum - Szabolcs utca",
        "city": "Budapest XIII. kerület",
        "address": "Szabolcs  utca 27.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.518909,
                "longitude": 19.0704769,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015251158605884996,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.515046282429324,
                19.07810247930294,
                47.52277171757068,
                19.062851320697057
            ],
            "_northWest": {
                "latitude": 47.515046282429324,
                "longitude": 19.062851320697057,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52277171757068,
                "longitude": 19.07810247930294,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 117
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Kreatív Technikum Székhely",
        "city": "Budapest XIII. kerület",
        "address": "Vág utca 12-14.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.5236649,
                "longitude": 19.0587081,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015252541042755752,
            "height": -0.0077254351413600375,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.519802182429316,
                19.06633437052138,
                47.527527617570684,
                19.051081829478623
            ],
            "_northWest": {
                "latitude": 47.519802182429316,
                "longitude": 19.051081829478623,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.527527617570684,
                "longitude": 19.06633437052138,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 118
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Mándy Iván Szakképző Iskola és Szakiskola Róbert Károly körúti telephely",
        "city": "Budapest XIII. kerület",
        "address": "Róbert Károly körút 49-51.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.52906,
                "longitude": 19.07201,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.52816,
                19.07334,
                47.52996,
                19.070679999999996
            ],
            "_northWest": {
                "latitude": 47.52816,
                "longitude": 19.070679999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52996,
                "longitude": 19.07334,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 119
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Mándy Iván Szakképző Iskola és Szakiskola Székhely",
        "city": "Budapest VIII. kerület",
        "address": "Elnök utca 3.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.479139,
                "longitude": 19.097672,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01523961224062731,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.47527628242933,
                19.105291806120313,
                47.48300171757068,
                19.090052193879686
            ],
            "_northWest": {
                "latitude": 47.47527628242933,
                "longitude": 19.090052193879686,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.48300171757068,
                "longitude": 19.105291806120313,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 120
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Pogány Frigyes Technikum Székhely",
        "city": "Budapest XVIII. kerület",
        "address": "Thököly út 11.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50267,
                "longitude": 19.08661,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50177,
                19.08794,
                47.50357,
                19.08528
            ],
            "_northWest": {
                "latitude": 47.50177,
                "longitude": 19.08528,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50357,
                "longitude": 19.08794,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 121
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Schulek Frigyes Két Tanítási Nyelvű Építőipari Technikum Székhely",
        "city": "Budapest VIII. kerület",
        "address": "Mosonyi utca 6",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4985739,
                "longitude": 19.0819659,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015245251643648317,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49471118242932,
                19.089588525821824,
                47.50243661757067,
                19.074343274178176
            ],
            "_northWest": {
                "latitude": 47.49471118242932,
                "longitude": 19.074343274178176,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50243661757067,
                "longitude": 19.089588525821824,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 122
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SzC Schulek Frigyes Két Tanítási Nyelvű Építőipari Technikum Telephely",
        "city": "Budapest VIII. kerület",
        "address": "Festetics utca 3.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4989079,
                "longitude": 19.0824501,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015245348611870213,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49504518242932,
                19.090072774305934,
                47.502770617570675,
                19.074827425694064
            ],
            "_northWest": {
                "latitude": 47.49504518242932,
                "longitude": 19.074827425694064,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.502770617570675,
                "longitude": 19.090072774305934,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 123
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Szamos Mátyás Technikum és Szakképző Iskola Telephely",
        "city": "Budapest XXI. kerület",
        "address": "Mansfeld Péter utca 25.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.470240000000004,
                "longitude": 19.27226,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.46934,
                19.27359,
                47.471140000000005,
                19.27093
            ],
            "_northWest": {
                "latitude": 47.46934,
                "longitude": 19.27093,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.471140000000005,
                "longitude": 19.27359,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 124
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Szamos Mátyás Technikum és Szakközépző Iskola Székhely",
        "city": "Budapest XXI. kerület",
        "address": "Petőfi tér 1.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4930309,
                "longitude": 19.05084,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015243642633407717,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.489168182429324,
                19.058461821316705,
                47.49689361757068,
                19.043218178683297
            ],
            "_northWest": {
                "latitude": 47.489168182429324,
                "longitude": 19.043218178683297,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49689361757068,
                "longitude": 19.058461821316705,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 125
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Újbudai Szakiskola",
        "city": "Budapest XI. kerület",
        "address": "Leiningen utca 27-35.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.486270000000005,
                "longitude": 19.04009,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0000800000000005241,
            "height": -0.000019999999999242846,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.48626,
                19.040129999999998,
                47.48628000000001,
                19.04005
            ],
            "_northWest": {
                "latitude": 47.48626,
                "longitude": 19.04005,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.48628000000001,
                "longitude": 19.040129999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 126
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Ybl Miklós Építőipari Technikum és Szakképző Iskola Öv utcai telephelye",
        "city": "Budapest XIV. kerület",
        "address": "Öv utca 29-33",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.520981,
                "longitude": 19.1449828,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015251760847576179,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.51711828242932,
                19.15260868042379,
                47.524843717570675,
                19.137356919576213
            ],
            "_northWest": {
                "latitude": 47.51711828242932,
                "longitude": 19.137356919576213,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.524843717570675,
                "longitude": 19.15260868042379,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 127
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Ybl Miklós Építőipari Technikum és Szakképző Iskola Pillangó utcai telephelye",
        "city": "Budapest XIV. kerület",
        "address": "Pillangó utca 32.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.505970000000005,
                "longitude": 19.114069999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50507,
                19.1154,
                47.506870000000006,
                19.112739999999995
            ],
            "_northWest": {
                "latitude": 47.50507,
                "longitude": 19.112739999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.506870000000006,
                "longitude": 19.1154,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 128
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Komplex SZC Ybl Miklós Építőipari Technikum és Szakképző IskolaSzékhely",
        "city": "Budapest XIV. kerület",
        "address": "Várna utca 21/B.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.5042339,
                "longitude": 19.113067,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015246895114209735,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.500371182429326,
                19.120690447557106,
                47.50809661757068,
                19.105443552442896
            ],
            "_northWest": {
                "latitude": 47.500371182429326,
                "longitude": 19.105443552442896,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50809661757068,
                "longitude": 19.120690447557106,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 129
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Bláthy Ottó Titusz Informatikai Technikum",
        "city": "Budapest III. kerület",
        "address": "Bécsi út 134.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.5407289,
                "longitude": 19.030051,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015257504105967712,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.53686618242932,
                19.037679752052984,
                47.544591617570674,
                19.022422247947016
            ],
            "_northWest": {
                "latitude": 47.53686618242932,
                "longitude": 19.022422247947016,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.544591617570674,
                "longitude": 19.037679752052984,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 130
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Egressy Gábor Két Tanítási Nyelvű Tecnikum",
        "city": "Budapest XIV. kerület",
        "address": "Egressy út 71.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.51327,
                "longitude": 19.11806,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.51237,
                19.11939,
                47.51417,
                19.11673
            ],
            "_northWest": {
                "latitude": 47.51237,
                "longitude": 19.11673,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.51417,
                "longitude": 19.11939,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 131
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Pataky István Híradásipari és Informatikai Technikum",
        "city": "Budapest X. kerület",
        "address": "Salgótarjáni út 53./B",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.4905099,
                "longitude": 19.118588,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01524291100244568,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.48664718242932,
                19.126209455501222,
                47.494372617570676,
                19.110966544498776
            ],
            "_northWest": {
                "latitude": 47.48664718242932,
                "longitude": 19.110966544498776,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.494372617570676,
                "longitude": 19.126209455501222,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 132
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Petrik Lajos Két Tanítási Nyelvű Technikum",
        "city": "Budapest",
        "address": "Thököly út 48-54.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50454,
                "longitude": 19.090040000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50364,
                19.091370000000005,
                47.50544,
                19.08871
            ],
            "_northWest": {
                "latitude": 47.50364,
                "longitude": 19.08871,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50544,
                "longitude": 19.091370000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 133
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Puskás Tivadar Távközlési és Informatikai Technikum",
        "city": "Budapest IX. kerület",
        "address": "Gyáli út 22.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.46848,
                "longitude": 19.10087,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.46758,
                19.1022,
                47.46938,
                19.09954
            ],
            "_northWest": {
                "latitude": 47.46758,
                "longitude": 19.09954,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.46938,
                "longitude": 19.1022,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 134
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Trefort Ágoston Két Tanítási Nyelvű Technikum",
        "city": "Budapest XIX. kerület",
        "address": "Kossuth tér 12.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.459922,
                "longitude": 19.14562,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015234041888973593,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.45605928242932,
                19.153237020944488,
                47.463784717570675,
                19.138002979055514
            ],
            "_northWest": {
                "latitude": 47.45605928242932,
                "longitude": 19.138002979055514,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.463784717570675,
                "longitude": 19.153237020944488,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 135
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Újpesti Két Tanítási Nyelvű Műszaki Technikum",
        "city": "Budapest IV. kerület",
        "address": "Görgey Artúr  utca 26.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.56524,
                "longitude": 19.0988,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.56434,
                19.10013,
                47.566140000000004,
                19.09747
            ],
            "_northWest": {
                "latitude": 47.56434,
                "longitude": 19.09747,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.566140000000004,
                "longitude": 19.10013,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 136
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Újpesti Két Tanítási Nyelvű Műszaki Technikum Váci Úti Telephelye",
        "city": "Budapest XIII. kerület",
        "address": "Váci  út 179-183.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.553866,
                "longitude": 19.075577,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015261328154110743,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.55000328242932,
                19.083207664077054,
                47.557728717570676,
                19.067946335922944
            ],
            "_northWest": {
                "latitude": 47.55000328242932,
                "longitude": 19.067946335922944,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.557728717570676,
                "longitude": 19.083207664077054,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 137
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Verebély László Technikum",
        "city": "Budapest XIII. kerület",
        "address": "Üteg  utca 13-15.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.529205,
                "longitude": 19.0763438,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015254151878117739,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.52534228242932,
                19.08397087593906,
                47.533067717570674,
                19.06871672406094
            ],
            "_northWest": {
                "latitude": 47.52534228242932,
                "longitude": 19.06871672406094,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.533067717570674,
                "longitude": 19.08397087593906,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 138
    },
    {
        "region": "Közép-Magyarország",
        "name": "Budapesti Műszaki SZC Wesselényi Miklós Műszaki Technikum",
        "city": "Budapest XIV. kerület",
        "address": "Várna utca 23.",
        "county": "Budapest",
        "bestView": {
            "center": {
                "latitude": 47.50453,
                "longitude": 19.112659999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50363,
                19.11399,
                47.505430000000004,
                19.111329999999995
            ],
            "_northWest": {
                "latitude": 47.50363,
                "longitude": 19.111329999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.505430000000004,
                "longitude": 19.11399,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 139
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Bem József Műszaki Technikum és Szakképző Iskola Székhely",
        "city": "Cegléd",
        "address": "Jászberényi út 2.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.17995,
                "longitude": 19.79945,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17905,
                19.80077,
                47.18085,
                19.79813
            ],
            "_northWest": {
                "latitude": 47.17905,
                "longitude": 19.79813,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.18085,
                "longitude": 19.80077,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 140
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Közgazdasági és Informatikai Technikum Székhely",
        "city": "Cegléd",
        "address": "Kossuth F. utca 32.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.177,
                "longitude": 19.80027,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.1761,
                19.80159,
                47.1779,
                19.79895
            ],
            "_northWest": {
                "latitude": 47.1761,
                "longitude": 19.79895,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.1779,
                "longitude": 19.80159,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 141
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Mihály Dénes Szakképző Iskola Kistarcsai Telephelye",
        "city": "Kistarcsa",
        "address": "Ifjúság  tér 3",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.547039999999996,
                "longitude": 19.26375,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.546139999999994,
                19.265080000000005,
                47.54794,
                19.26242
            ],
            "_northWest": {
                "latitude": 47.546139999999994,
                "longitude": 19.26242,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.54794,
                "longitude": 19.265080000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 142
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Mihály Dénes Szakképző Iskola Székhely",
        "city": "Gyömrő",
        "address": "Fő tér 2/b",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.42147,
                "longitude": 19.39454,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.42057,
                19.39587,
                47.42237,
                19.39321
            ],
            "_northWest": {
                "latitude": 47.42057,
                "longitude": 19.39321,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.42237,
                "longitude": 19.39587,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 143
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Nagykátai Ipari Szakképző Iskola  Székhely",
        "city": "Nagykáta",
        "address": "Csonka köz 6",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.41934,
                "longitude": 19.74451,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.41844,
                19.74584,
                47.42024,
                19.743179999999995
            ],
            "_northWest": {
                "latitude": 47.41844,
                "longitude": 19.743179999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.42024,
                "longitude": 19.74584,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 144
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Nagykátai Ipari Szakképző Iskola Csonka köz 9. Telephelye",
        "city": "Nagykáta",
        "address": "Csonka köz 9",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.41934,
                "longitude": 19.74493,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.41844,
                19.74626,
                47.42024,
                19.7436
            ],
            "_northWest": {
                "latitude": 47.41844,
                "longitude": 19.7436,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.42024,
                "longitude": 19.74626,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 145
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Nagykátai Ipari Szakképző Iskola Tüzelő utcai Telephelye",
        "city": "Nagykáta",
        "address": "Tüzelő utca 29.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.40542,
                "longitude": 19.73932,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.40452,
                19.74065,
                47.40632,
                19.73799
            ],
            "_northWest": {
                "latitude": 47.40452,
                "longitude": 19.73799,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.40632,
                "longitude": 19.74065,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 146
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Nagykátai Ipari Szakképző Iskola Víztorony utcai Telephelye",
        "city": "Nagykáta",
        "address": "Víztorony utca 1.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.4219,
                "longitude": 19.74663,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.421,
                19.74796,
                47.4228,
                19.7453
            ],
            "_northWest": {
                "latitude": 47.421,
                "longitude": 19.7453,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.4228,
                "longitude": 19.74796,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 147
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Szterényi József Technikum és Szakképző Iskola Székhely",
        "city": "Monor",
        "address": "Ipar utca 2",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.34657,
                "longitude": 19.417830000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.34567,
                19.419160000000005,
                47.34747,
                19.4165
            ],
            "_northWest": {
                "latitude": 47.34567,
                "longitude": 19.4165,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.34747,
                "longitude": 19.419160000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 148
    },
    {
        "region": "Közép-Magyarország",
        "name": "Ceglédi SZC Unghváry László Vendéglátóipari Technikum és Szakképző Iskola Székhely",
        "city": "Cegléd",
        "address": "Kossuth Ferenc utca 18.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.17443,
                "longitude": 19.7985,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17353,
                19.79982,
                47.17533,
                19.79718
            ],
            "_northWest": {
                "latitude": 47.17353,
                "longitude": 19.79718,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17533,
                "longitude": 19.79982,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 149
    },
    {
        "region": "Észak-Alföld",
        "name": "Damjanich János Szakképző Iskola és Kollégium",
        "city": "Martfű",
        "address": "Gesztenye sor 15.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.01959,
                "longitude": 20.28016,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.01869,
                20.28148,
                47.02049,
                20.27884
            ],
            "_northWest": {
                "latitude": 47.01869,
                "longitude": 20.27884,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.02049,
                "longitude": 20.28148,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 150
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Baross Gábor Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Debrecen",
        "address": "Budai Ézsaiás  utca 8/A",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.521915,
                "longitude": 21.641665,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0005900000000025329,
            "height": -0.0005699999999961847,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.52163,
                21.64196,
                47.5222,
                21.64137
            ],
            "_northWest": {
                "latitude": 47.52163,
                "longitude": 21.64137,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.5222,
                "longitude": 21.64196,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 151
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Beregszászi Pál Technikum",
        "city": "Debrecen",
        "address": "Jerikó  utca 17",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.544955,
                "longitude": 21.613165000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002469999999998862,
            "height": -0.005850000000002353,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.54203,
                21.614400000000003,
                47.547880000000006,
                21.61193
            ],
            "_northWest": {
                "latitude": 47.54203,
                "longitude": 21.61193,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.547880000000006,
                "longitude": 21.614400000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 152
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Bethlen Gábor Közgazdasági Technikum",
        "city": "Debrecen",
        "address": "Piac utca 8",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.531459999999996,
                "longitude": 21.62501,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.530559999999994,
                21.62634,
                47.53236,
                21.62368
            ],
            "_northWest": {
                "latitude": 47.530559999999994,
                "longitude": 21.62368,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.53236,
                "longitude": 21.62634,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 153
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SzC Brassai Sámuel Műszaki Technikum Burgundia utcai telephelye",
        "city": "Debrecen",
        "address": "Burgundia  utca 1.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.53227,
                "longitude": 21.6299,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.531369999999995,
                21.63123,
                47.53317,
                21.62857
            ],
            "_northWest": {
                "latitude": 47.531369999999995,
                "longitude": 21.62857,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.53317,
                "longitude": 21.63123,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 154
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Brassai Sámuel Műszaki Technikum Székhely ",
        "city": "Debrecen",
        "address": "Víztorony utca 3.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.53451,
                "longitude": 21.64417,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.533609999999996,
                21.6455,
                47.53541,
                21.64284
            ],
            "_northWest": {
                "latitude": 47.533609999999996,
                "longitude": 21.64284,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.53541,
                "longitude": 21.6455,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 155
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Építéstechnológiai és Műszaki Szakképző Iskolája Székhely",
        "city": "Debrecen",
        "address": "Kassai út 25.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.54099,
                "longitude": 21.640990000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.54009,
                21.642320000000005,
                47.54189,
                21.63966
            ],
            "_northWest": {
                "latitude": 47.54009,
                "longitude": 21.63966,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.54189,
                "longitude": 21.642320000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 156
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Irinyi János Technikum Székhely",
        "city": "Debrecen",
        "address": "Irinyi utca 1",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.52469,
                "longitude": 21.637430000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.52379,
                21.638760000000005,
                47.52559,
                21.6361
            ],
            "_northWest": {
                "latitude": 47.52379,
                "longitude": 21.6361,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52559,
                "longitude": 21.638760000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 157
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Kereskedelmi és Vendéglátóipari Technikum és Szakképző Iskola Székhely",
        "city": "Debrecen",
        "address": "Vénkert utca 2.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.53726,
                "longitude": 21.61396,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.53636,
                21.61529,
                47.538160000000005,
                21.612629999999996
            ],
            "_northWest": {
                "latitude": 47.53636,
                "longitude": 21.612629999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.538160000000005,
                "longitude": 21.61529,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 158
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Kreatív Technikum Székhely",
        "city": "Debrecen",
        "address": "Sétakert  utca 1-3.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.54461,
                "longitude": 21.6161,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.54371,
                21.61743,
                47.54551,
                21.61477
            ],
            "_northWest": {
                "latitude": 47.54371,
                "longitude": 21.61477,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.54551,
                "longitude": 21.61743,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 159
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Mechwart András Gépipari és Informatikai Technikum",
        "city": "Debrecen",
        "address": "Széchenyi utca 58.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.525855,
                "longitude": 21.616770000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.000460000000000349,
            "height": -0.0001699999999971169,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.52577,
                21.617000000000004,
                47.52594,
                21.61654
            ],
            "_northWest": {
                "latitude": 47.52577,
                "longitude": 21.61654,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52594,
                "longitude": 21.617000000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 160
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Péchy Mihály Építőipari Technikum Székhely",
        "city": "Debrecen",
        "address": "Varga utca 5.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.52579,
                "longitude": 21.62922,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.52489,
                21.63055,
                47.52669,
                21.62789
            ],
            "_northWest": {
                "latitude": 47.52489,
                "longitude": 21.62789,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.52669,
                "longitude": 21.63055,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 161
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Vegyipari Technikum Székhely",
        "city": "Debrecen",
        "address": "Csapó utca 29-35.",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.53212,
                "longitude": 21.62924,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.53122,
                21.63057,
                47.53302,
                21.62791
            ],
            "_northWest": {
                "latitude": 47.53122,
                "longitude": 21.62791,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.53302,
                "longitude": 21.63057,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 162
    },
    {
        "region": "Észak-Alföld",
        "name": "Debreceni SZC Vegyipari Technikum Tiszaújvárosi Telephelye",
        "city": "Tiszaújváros",
        "address": "Gyári út 1",
        "county": "Hajdú-Bihar",
        "bestView": {
            "center": {
                "latitude": 47.91713,
                "longitude": 21.026955,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.004509999999999792,
            "height": -0.0002599999999972624,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.917,
                21.02921,
                47.91726,
                21.024700000000003
            ],
            "_northWest": {
                "latitude": 47.917,
                "longitude": 21.024700000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.91726,
                "longitude": 21.02921,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 163
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Dunaferr Technikum és Szakképző Iskola Apáczai Csere János utcai telephelye",
        "city": "Dunaújváros",
        "address": "Apáczai Csere János utca 13.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.49675,
                "longitude": 19.0487721,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015244722152594647,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49288728242932,
                19.056394461076298,
                47.500612717570675,
                19.041149738923703
            ],
            "_northWest": {
                "latitude": 47.49288728242932,
                "longitude": 19.041149738923703,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.500612717570675,
                "longitude": 19.056394461076298,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 164
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Dunaferr Technikum és Szakképző Iskola Székhely",
        "city": "Dunaújváros",
        "address": "Vasmű tér 3.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.950469999999996,
                "longitude": 18.94768,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.949569999999994,
                18.948999999999998,
                46.95137,
                18.94636
            ],
            "_northWest": {
                "latitude": 46.949569999999994,
                "longitude": 18.94636,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.95137,
                "longitude": 18.948999999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 165
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Hild József Technikum, Szakképző Iskola és Szakiskola Székhely",
        "city": "Dunaújváros",
        "address": "Bercsényi Miklós utca 8.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.96673,
                "longitude": 18.92921,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.96583,
                18.93053,
                46.96763,
                18.92789
            ],
            "_northWest": {
                "latitude": 46.96583,
                "longitude": 18.92789,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.96763,
                "longitude": 18.93053,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 166
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Kereskedelmi és Vendéglátóipari Technikum és Szakképző Iskola Balogh Ádám Utcai Telephelye",
        "city": "Dunaújváros",
        "address": "Béri Balogh Ádám utca 1.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.96243,
                "longitude": 18.93169,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.961529999999996,
                18.93301,
                46.96333,
                18.93037
            ],
            "_northWest": {
                "latitude": 46.961529999999996,
                "longitude": 18.93037,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.96333,
                "longitude": 18.93301,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 167
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Kereskedelmi és Vendéglátóipari Technikum és Szakképző Iskola Építők Útja 3. Telephelye",
        "city": "Dunaújváros",
        "address": "Építők  útja 3.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.95823,
                "longitude": 18.94383,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.95733,
                18.945149999999998,
                46.95913,
                18.94251
            ],
            "_northWest": {
                "latitude": 46.95733,
                "longitude": 18.94251,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.95913,
                "longitude": 18.945149999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 168
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Kereskedelmi és Vendéglátóipari Technikum és Szakképző Iskola Székhely",
        "city": "Dunaújváros",
        "address": "Kossuth Lajos utca 10/A.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.962,
                "longitude": 18.93888,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.9611,
                18.9402,
                46.962900000000005,
                18.93756
            ],
            "_northWest": {
                "latitude": 46.9611,
                "longitude": 18.93756,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.962900000000005,
                "longitude": 18.9402,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 169
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Lorántffy Zsuzsanna Technikum és Kollégium Bocskai István utcai Telephelye",
        "city": "Dunaújváros",
        "address": "Bocskai István utca 1/a.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.9610689,
                "longitude": 18.9293568,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015091443161665552,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.957206182429324,
                18.936902521580834,
                46.96493161757068,
                18.921811078419168
            ],
            "_northWest": {
                "latitude": 46.957206182429324,
                "longitude": 18.921811078419168,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.96493161757068,
                "longitude": 18.936902521580834,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 170
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Lorántffy Zsuzsanna Technikum és Kollégium Lajos Király körút 29. Telephelye",
        "city": "Dunaújváros",
        "address": "Lajos Király  körút 29.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.95885,
                "longitude": 18.9186,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.95795,
                18.91992,
                46.95975,
                18.91728
            ],
            "_northWest": {
                "latitude": 46.95795,
                "longitude": 18.91728,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.95975,
                "longitude": 18.91992,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 171
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Lorántffy Zsuzsanna Technikum és Kollégium Székhely",
        "city": "Dunaújváros",
        "address": "Radnóti Miklós utca 6.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.960305000000005,
                "longitude": 18.926775,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00030999999999892225,
            "height": -0.0003299999999981651,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.96014000000001,
                18.92693,
                46.96047,
                18.92662
            ],
            "_northWest": {
                "latitude": 46.96014000000001,
                "longitude": 18.92662,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.96047,
                "longitude": 18.92693,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 172
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Rudas Közgazdasági Technikum és Kollégium Székhely",
        "city": "Dunaújváros",
        "address": "Római körút 47-49.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 46.97362,
                "longitude": 18.932859999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.972719999999995,
                18.934179999999998,
                46.97452,
                18.93154
            ],
            "_northWest": {
                "latitude": 46.972719999999995,
                "longitude": 18.93154,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.97452,
                "longitude": 18.934179999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 173
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Dunaújvárosi SZC Szabolcs Vezér Technikum Székhely",
        "city": "Pusztaszabolcs",
        "address": "Mátyás király utca 16-20.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.141194999999996,
                "longitude": 18.75453,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0022899999999950182,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.14005,
                18.75583,
                47.14233999999999,
                18.75323
            ],
            "_northWest": {
                "latitude": 47.14005,
                "longitude": 18.75323,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.14233999999999,
                "longitude": 18.75583,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 174
    },
    {
        "region": "Közép-Magyarország",
        "name": "Érdi SZC Csonka János Műszaki Technikum",
        "city": "Szigetszentmiklós",
        "address": "Csonka János utca 5",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.33413,
                "longitude": 19.0073,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.33323,
                19.00863,
                47.33503,
                19.00597
            ],
            "_northWest": {
                "latitude": 47.33323,
                "longitude": 19.00597,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.33503,
                "longitude": 19.00863,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 175
    },
    {
        "region": "Közép-Magyarország",
        "name": "Érdi SZC Eötvös József Technikum",
        "city": "Gyál",
        "address": "Erdősor utca 65.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.3799,
                "longitude": 19.20591,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.379,
                19.20724,
                47.3808,
                19.20458
            ],
            "_northWest": {
                "latitude": 47.379,
                "longitude": 19.20458,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.3808,
                "longitude": 19.20724,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 176
    },
    {
        "region": "Közép-Magyarország",
        "name": "Érdi SZC Kiskunlacházi Technikum és Szakképző Iskola",
        "city": "Kiskunlacháza",
        "address": "Rákóczi utca 63.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.18663,
                "longitude": 19.01541,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.18573,
                19.01673,
                47.18753,
                19.01409
            ],
            "_northWest": {
                "latitude": 47.18573,
                "longitude": 19.01409,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.18753,
                "longitude": 19.01673,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 177
    },
    {
        "region": "Közép-Magyarország",
        "name": "Érdi SZC Kós Károly Technikum",
        "city": "Érd",
        "address": "Ercsi út 4.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.37542,
                "longitude": 18.916249999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.37452,
                18.91758,
                47.37632,
                18.914919999999995
            ],
            "_northWest": {
                "latitude": 47.37452,
                "longitude": 18.914919999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.37632,
                "longitude": 18.91758,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 178
    },
    {
        "region": "Közép-Magyarország",
        "name": "Érdi SZC Kossuth Zsuzsanna Szakképző Iskola és Kollégium",
        "city": "Dabas",
        "address": "Jözsef Attila utca 107",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.19597,
                "longitude": 19.29922,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.19507,
                19.300539999999998,
                47.196870000000004,
                19.2979
            ],
            "_northWest": {
                "latitude": 47.19507,
                "longitude": 19.2979,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.196870000000004,
                "longitude": 19.300539999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 179
    },
    {
        "region": "Közép-Magyarország",
        "name": "Érdi SZC Százhalombattai Széchenyi István Technikum és Gimnázium",
        "city": "Százhalombatta",
        "address": "Iskola utca 3",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.32184,
                "longitude": 18.90239,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.32094,
                18.90372,
                47.32274,
                18.90106
            ],
            "_northWest": {
                "latitude": 47.32094,
                "longitude": 18.90106,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.32274,
                "longitude": 18.90372,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 180
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Esztergomi SZC Balassa Bálint Gazdasági Technikum és Szakképző Iskola",
        "city": "Esztergom",
        "address": "Szent István tér 7-9.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.7999,
                "longitude": 18.738770000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0025599999999990075,
            "height": -0.00217999999999563,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.79881,
                18.740050000000004,
                47.80099,
                18.73749
            ],
            "_northWest": {
                "latitude": 47.79881,
                "longitude": 18.73749,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.80099,
                "longitude": 18.740050000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 181
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Esztergomi SZC Bottyán János Technikum",
        "city": "Esztergom",
        "address": "Főapát utca 1.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.79036,
                "longitude": 18.74025,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.78946,
                18.74159,
                47.79126,
                18.73891
            ],
            "_northWest": {
                "latitude": 47.78946,
                "longitude": 18.73891,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.79126,
                "longitude": 18.74159,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 182
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Esztergomi SZC Géza Fejedelem Technikum és Szakképző Iskola",
        "city": "Esztergom",
        "address": "Budai Nagy Antal utca 24",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.779235,
                "longitude": 18.74481,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0037599999999997635,
            "height": -0.006370000000003984,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.77605,
                18.74669,
                47.78242,
                18.74293
            ],
            "_northWest": {
                "latitude": 47.77605,
                "longitude": 18.74293,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.78242,
                "longitude": 18.74669,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 183
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Gulyás Lajos utcai telephely",
        "city": "Mosonmagyaróvár",
        "address": "Gulyás Lajos utca 5",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.870999999999995,
                "longitude": 17.273220000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.870099999999994,
                17.27456,
                47.8719,
                17.271880000000003
            ],
            "_northWest": {
                "latitude": 47.870099999999994,
                "longitude": 17.271880000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.8719,
                "longitude": 17.27456,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 184
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Baksa Kálmán Két Tanítási Nyelvű Gimnázium",
        "city": "Győr",
        "address": "Örkény István  utca 8-10",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.663225,
                "longitude": 17.6326,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.000019999999999242846,
            "height": -0.00010999999999938836,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.663169999999994,
                17.63261,
                47.66328,
                17.63259
            ],
            "_northWest": {
                "latitude": 47.663169999999994,
                "longitude": 17.63259,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.66328,
                "longitude": 17.63261,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 185
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Baross Gábor Két Tanítási Nyelvű Közgazdasági Technikum Székhely",
        "city": "Győr",
        "address": "Bem tér 20-22.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.678560000000004,
                "longitude": 17.63609,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.67766,
                17.63743,
                47.679460000000006,
                17.63475
            ],
            "_northWest": {
                "latitude": 47.67766,
                "longitude": 17.63475,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.679460000000006,
                "longitude": 17.63743,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 186
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Bercsényi Miklós Közlekedési és Sportiskolai Technikum",
        "city": "Győr ",
        "address": "Cinka Panna utca 2.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.684525,
                "longitude": 17.616865,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0010300000000000864,
            "height": -0.0007499999999964757,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.68415,
                17.61738,
                47.6849,
                17.61635
            ],
            "_northWest": {
                "latitude": 47.68415,
                "longitude": 17.61635,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.6849,
                "longitude": 17.61738,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 187
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Bolyai János Általános Iskola",
        "city": "Mosonmagyaróvár",
        "address": "Szent István király  út 6",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.87055,
                "longitude": 17.27057,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.86965,
                17.27191,
                47.87145,
                17.26923
            ],
            "_northWest": {
                "latitude": 47.86965,
                "longitude": 17.26923,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.87145,
                "longitude": 17.27191,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 188
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Bolyai János Általános Iskola 025",
        "city": "Mosonmagyaróvár",
        "address": "Régi vámház tér 6.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.873720000000006,
                "longitude": 17.2684,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00007999999999697138,
            "height": -0.000019999999999242846,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.87371,
                17.26844,
                47.87373000000001,
                17.26836
            ],
            "_northWest": {
                "latitude": 47.87371,
                "longitude": 17.26836,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.87373000000001,
                "longitude": 17.26844,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 189
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Bolyai János Technikum",
        "city": "Mosonmagyaróvár",
        "address": "Régi Vámház tér 6.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.873720000000006,
                "longitude": 17.2684,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00007999999999697138,
            "height": -0.000019999999999242846,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.87371,
                17.26844,
                47.87373000000001,
                17.26836
            ],
            "_northWest": {
                "latitude": 47.87371,
                "longitude": 17.26836,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.87373000000001,
                "longitude": 17.26844,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 190
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Deák Ferenc Közgazdasági Technikum",
        "city": "Győr",
        "address": "Bisinger sétány 32.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.68481,
                "longitude": 17.64065,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.68391,
                17.64199,
                47.68571,
                17.639310000000002
            ],
            "_northWest": {
                "latitude": 47.68391,
                "longitude": 17.639310000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.68571,
                "longitude": 17.64199,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 191
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Gábor László Építő- és Faipari Szakképző Iskola",
        "city": "Győr",
        "address": "Nádor tér 4",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.6723,
                "longitude": 17.63317,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.003439999999997667,
            "height": -0.0023199999999974352,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.67114,
                17.63489,
                47.67346,
                17.63145
            ],
            "_northWest": {
                "latitude": 47.67114,
                "longitude": 17.63145,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.67346,
                "longitude": 17.63489,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 192
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Gábor László Építő- és Faipari Szakképző Iskola Szabóky Adolf Szakiskolai Intézményegysége",
        "city": "Győr",
        "address": "Szabolcska Mihály utca 26",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.678399999999996,
                "longitude": 17.65174,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.677499999999995,
                17.65308,
                47.6793,
                17.6504
            ],
            "_northWest": {
                "latitude": 47.677499999999995,
                "longitude": 17.6504,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.6793,
                "longitude": 17.65308,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 193
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Glück Frigyes Turisztikai és Vendéglátóipari Szakképző Iskola",
        "city": "Mosonmagyaróvár",
        "address": "Mosonvár utca 15.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.85524,
                "longitude": 17.272370000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.85434,
                17.27371,
                47.85614,
                17.271030000000003
            ],
            "_northWest": {
                "latitude": 47.85434,
                "longitude": 17.271030000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.85614,
                "longitude": 17.27371,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 194
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Haller János Általános Iskola",
        "city": "Mosonmagyaróvár",
        "address": "Szent István király út 97",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.8607,
                "longitude": 17.27462,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.8598,
                17.275959999999998,
                47.8616,
                17.27328
            ],
            "_northWest": {
                "latitude": 47.8598,
                "longitude": 17.27328,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.8616,
                "longitude": 17.275959999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 195
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Haller János Általános Iskola Mosonvár úti telephelye",
        "city": "Mosonmagyaróvár",
        "address": "Mosonvár utca 15",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.85524,
                "longitude": 17.272370000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.85434,
                17.27371,
                47.85614,
                17.271030000000003
            ],
            "_northWest": {
                "latitude": 47.85434,
                "longitude": 17.271030000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.85614,
                "longitude": 17.27371,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 196
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Hild József Építőipari Technikum",
        "city": "Győr",
        "address": "Szent István út 1.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.871629999999996,
                "longitude": 17.269550000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.870729999999995,
                17.27089,
                47.87253,
                17.268210000000003
            ],
            "_northWest": {
                "latitude": 47.870729999999995,
                "longitude": 17.268210000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.87253,
                "longitude": 17.27089,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 197
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Hunyadi Mátyás Technikum Székhely",
        "city": "Mosonmagyaróvár",
        "address": "Dr. Gyárfás József utca 3",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.8696334,
                "longitude": 17.2734333,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015354068299764378,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.86577068242932,
                17.281110334149883,
                47.873496117570674,
                17.26575626585012
            ],
            "_northWest": {
                "latitude": 47.86577068242932,
                "longitude": 17.26575626585012,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.873496117570674,
                "longitude": 17.281110334149883,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 198
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Jedlik Ányos Gépipari és Informatikai Technikum és Kollégium",
        "city": "Győr",
        "address": "Szent István út 7",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.87093,
                "longitude": 17.26988,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.87003,
                17.27122,
                47.87183,
                17.26854
            ],
            "_northWest": {
                "latitude": 47.87003,
                "longitude": 17.26854,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.87183,
                "longitude": 17.27122,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 199
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Jedlik Ányos Gépipari és Informatikai Technikum és Kollégium Árpád úti telephelye",
        "city": "Győr",
        "address": "Árpád út 10",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.7479,
                "longitude": 17.3878,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.747,
                17.389139999999998,
                47.7488,
                17.38646
            ],
            "_northWest": {
                "latitude": 47.747,
                "longitude": 17.38646,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.7488,
                "longitude": 17.389139999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 200
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Jedlik Ányos Gépipari és Informatikai Technikum és Kollégium Baross Gábor Úti Telephelye",
        "city": "Győr",
        "address": "Baross Gábor út 51",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.67926,
                "longitude": 17.63875,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.67836,
                17.64009,
                47.68016,
                17.637410000000003
            ],
            "_northWest": {
                "latitude": 47.67836,
                "longitude": 17.637410000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.68016,
                "longitude": 17.64009,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 201
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Kossuth Lajos Technikum és Kollégium",
        "city": "Győr",
        "address": "Kossuth Lajos utca 7",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.6852,
                "longitude": 17.62501,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.6843,
                17.62635,
                47.6861,
                17.62367
            ],
            "_northWest": {
                "latitude": 47.6843,
                "longitude": 17.62367,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.6861,
                "longitude": 17.62635,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 202
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Kossuth Lajos Technikum és Kollégium fiúkollégium",
        "city": "Győr ",
        "address": "Dr. Róth Emil utca 1.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.685155,
                "longitude": 17.625699,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01529969459647873,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.681292282429325,
                17.63334884729824,
                47.68901771757068,
                17.61804915270176
            ],
            "_northWest": {
                "latitude": 47.681292282429325,
                "longitude": 17.61804915270176,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.68901771757068,
                "longitude": 17.63334884729824,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 203
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Kossuth Lajos Technikum és Kollégium lánykollégium",
        "city": "Győr",
        "address": "Kossuth Lajos utca 22./A",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.7254,
                "longitude": 17.59997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.7245,
                17.601309999999998,
                47.7263,
                17.59863
            ],
            "_northWest": {
                "latitude": 47.7245,
                "longitude": 17.59863,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.7263,
                "longitude": 17.601309999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 204
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Krúdy Gyula Turisztikai és Vendéglátóipari Technikum",
        "city": "Győr",
        "address": "Örkény István utca 8-10.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.663225,
                "longitude": 17.6326,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.000019999999999242846,
            "height": -0.00010999999999938836,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.663169999999994,
                17.63261,
                47.66328,
                17.63259
            ],
            "_northWest": {
                "latitude": 47.663169999999994,
                "longitude": 17.63259,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.66328,
                "longitude": 17.63261,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 205
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Lukács Sándor Járműipari és Gépészeti Technikum és Kollégium",
        "city": "Győr",
        "address": "Mártírok útja 13-15.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.69488,
                "longitude": 17.65762,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.693979999999996,
                17.65896,
                47.69578,
                17.656280000000002
            ],
            "_northWest": {
                "latitude": 47.693979999999996,
                "longitude": 17.656280000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.69578,
                "longitude": 17.65896,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 206
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Móra Ferenc Általános Iskola",
        "city": "Győr",
        "address": "Kodály Zoltán  utca 20_24",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.674890000000005,
                "longitude": 17.65494,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.67399,
                17.65628,
                47.675790000000006,
                17.6536
            ],
            "_northWest": {
                "latitude": 47.67399,
                "longitude": 17.6536,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.675790000000006,
                "longitude": 17.65628,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 207
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Pálffy Miklós Kereskedelmi és Logisztikai Technikum",
        "city": "Győr",
        "address": "Földes Gábor utca 34-36.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.67545,
                "longitude": 17.6537,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00870000000000104,
            "height": -0.001840000000001396,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.67453,
                17.658050000000003,
                47.67637,
                17.64935
            ],
            "_northWest": {
                "latitude": 47.67453,
                "longitude": 17.64935,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.67637,
                "longitude": 17.658050000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 208
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Pattantyús-Ábrahám Géza Technikum",
        "city": "Győr",
        "address": "Ikva utca 70.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.6639,
                "longitude": 17.63945,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.663,
                17.64079,
                47.6648,
                17.63811
            ],
            "_northWest": {
                "latitude": 47.663,
                "longitude": 17.63811,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.6648,
                "longitude": 17.64079,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 209
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Győri SZC Sport és Kreatív Technikum",
        "city": "Győr",
        "address": "Kodály Zoltán utca 20-24",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.674800000000005,
                "longitude": 17.65612,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.6739,
                17.65746,
                47.675700000000006,
                17.654780000000002
            ],
            "_northWest": {
                "latitude": 47.6739,
                "longitude": 17.654780000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.675700000000006,
                "longitude": 17.65746,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 210
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Ady Endre-Bay Zoltán Technikum és Szakképző Iskola",
        "city": "Sarkad",
        "address": "Piac tér 4",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.74483,
                "longitude": 21.38635,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.74393,
                21.38766,
                46.74573,
                21.38504
            ],
            "_northWest": {
                "latitude": 46.74393,
                "longitude": 21.38504,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.74573,
                "longitude": 21.38766,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 211
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Dévaványai Technikum, Szakképző Iskola és Kollégium",
        "city": "Dévaványa",
        "address": "Mezőtúri út 2",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 47.0286159,
                "longitude": 20.9421956,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015110528323134531,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.02475318242932,
                20.94975086416157,
                47.032478617570675,
                20.934640335838434
            ],
            "_northWest": {
                "latitude": 47.02475318242932,
                "longitude": 20.934640335838434,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.032478617570675,
                "longitude": 20.94975086416157,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 212
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Dévaványai Technikum, Szakképző Iskola és Kollégium Kisújszállási Utcai Telephelye",
        "city": "Dévaványa",
        "address": "Kisújszállási utca 41",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 47.03766,
                "longitude": 20.944095,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0033299999999982788,
            "height": -0.004939999999997724,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.03519,
                20.94576,
                47.040130000000005,
                20.94243
            ],
            "_northWest": {
                "latitude": 47.03519,
                "longitude": 20.94243,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.040130000000005,
                "longitude": 20.94576,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 213
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Dévaványai Technikum, Szakképző Iskola és Kollégium Mátyás Utcai Telephelye",
        "city": "Dévaványa",
        "address": "Mátyás utca 1.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 47.025059999999996,
                "longitude": 20.94906,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.024159999999995,
                20.95038,
                47.02596,
                20.94774
            ],
            "_northWest": {
                "latitude": 47.024159999999995,
                "longitude": 20.94774,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.02596,
                "longitude": 20.95038,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 214
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SzC Harruckern János Technikum, Szakképző Iskola és Kollégium",
        "city": "Gyula",
        "address": "Szent István utca 38.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.9283477,
                "longitude": 20.8100369,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01508222275812443,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.92448498242933,
                20.817578011379062,
                46.93221041757068,
                20.802495788620938
            ],
            "_northWest": {
                "latitude": 46.92448498242933,
                "longitude": 20.802495788620938,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.93221041757068,
                "longitude": 20.817578011379062,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 215
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SzC Harruckern János Technikum, Szakképző Iskola és Kollégium Ady Endre utca 3. Telephelye",
        "city": "Gyula",
        "address": "Ady Endre  utca 3.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.65084,
                "longitude": 21.27171,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.64994,
                21.27302,
                46.651740000000004,
                21.2704
            ],
            "_northWest": {
                "latitude": 46.64994,
                "longitude": 21.2704,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.651740000000004,
                "longitude": 21.27302,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 216
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SzC Harruckern János Technikum, Szakképző Iskola és Kollégium Elek, Szent István Utca 2. Telephelye",
        "city": "Elek",
        "address": "Szent István utca 2",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.52883,
                "longitude": 21.25332,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.52793,
                21.25463,
                46.52973,
                21.25201
            ],
            "_northWest": {
                "latitude": 46.52793,
                "longitude": 21.25201,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.52973,
                "longitude": 21.25463,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 217
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SzC Harruckern János Technikum, Szakképző Iskola és Kollégium Elek, Szent István Utca 4-6. Telephelye",
        "city": "Elek",
        "address": "Szent István utca 4-6",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.52872,
                "longitude": 21.25352,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002619999999996736,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.52782,
                21.25483,
                46.52962,
                21.252210000000005
            ],
            "_northWest": {
                "latitude": 46.52782,
                "longitude": 21.252210000000005,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.52962,
                "longitude": 21.25483,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 218
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SzC Harruckern János Technikum, Szakképző Iskola és Kollégium Gyula, Szent István utca 69. Telephelye",
        "city": "Gyula",
        "address": "Szent István út 69",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.63586,
                "longitude": 21.28107,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.63496,
                21.28238,
                46.63676,
                21.27976
            ],
            "_northWest": {
                "latitude": 46.63496,
                "longitude": 21.27976,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.63676,
                "longitude": 21.28238,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 219
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SzC Harruckern János Technikum, Szakképző Iskola és Kollégium Karácsony János Utcai Telephelye",
        "city": "Gyula",
        "address": "Karácsony János utca 13",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.6547686,
                "longitude": 21.2747453,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015005761259686778,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.65090588242932,
                21.282248180629843,
                46.65863131757067,
                21.267242419370156
            ],
            "_northWest": {
                "latitude": 46.65090588242932,
                "longitude": 21.267242419370156,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.65863131757067,
                "longitude": 21.282248180629843,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 220
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SzC Harruckern János Technikum, Szakképző Iskola és Kollégium Szabadkígyósi Telephelye",
        "city": "Szabadkígyós",
        "address": "Szabadkígyós",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.600643157958984,
                "longitude": 21.102771759033203,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.08721923828125,
            "height": -0.08965301513671875,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.555816650390625,
                21.146381378173828,
                46.645469665527344,
                21.059162139892578
            ],
            "_northWest": {
                "latitude": 46.555816650390625,
                "longitude": 21.059162139892578,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.645469665527344,
                "longitude": 21.146381378173828,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 221
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Kossuth Lajos Technikum, Szakképző Iskola és Kollégium Gyopáros Úti Telephelye",
        "city": "Orosháza",
        "address": "Gyopáros út 3",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.5505436,
                "longitude": 20.6743268,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014976924941009884,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.54668088242932,
                20.681815262470504,
                46.554406317570674,
                20.666838337529494
            ],
            "_northWest": {
                "latitude": 46.54668088242932,
                "longitude": 20.666838337529494,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.554406317570674,
                "longitude": 20.681815262470504,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 222
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Kossuth Lajos Technikum, Szakképző Iskola és Kollégium Kossuth Téri Telephelye",
        "city": "Orosháza",
        "address": "Kossuth  tér 4.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.557055,
                "longitude": 20.65959,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0038599999999995305,
            "height": -0.0008899999999982811,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.55661,
                20.661520000000003,
                46.5575,
                20.65766
            ],
            "_northWest": {
                "latitude": 46.55661,
                "longitude": 20.65766,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.5575,
                "longitude": 20.661520000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 223
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Kossuth Lajos Technikum, Szakképző Iskola és Kollégium Székács József utcai telephelye",
        "city": "Orosháza",
        "address": "Székács József utca 15",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.56152,
                "longitude": 20.67794,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.56062,
                20.67925,
                46.56242,
                20.67663
            ],
            "_northWest": {
                "latitude": 46.56062,
                "longitude": 20.67663,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.56242,
                "longitude": 20.67925,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 224
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Kossuth Lajos Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Orosháza",
        "address": "Kossuth tér 1.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.557114999999996,
                "longitude": 20.65983,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0015199999999992997,
            "height": -0.000030000000002416982,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.55709999999999,
                20.66059,
                46.55713,
                20.65907
            ],
            "_northWest": {
                "latitude": 46.55709999999999,
                "longitude": 20.65907,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.55713,
                "longitude": 20.66059,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 225
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Székely Mihály Technikum, Szakképző Iskola és Kollégium",
        "city": "Szarvas",
        "address": "Vajda Péter utca 18.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.8671,
                "longitude": 20.5428,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.8662,
                20.54412,
                46.868,
                20.54148
            ],
            "_northWest": {
                "latitude": 46.8662,
                "longitude": 20.54148,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.868,
                "longitude": 20.54412,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 226
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Székely Mihály Technikum, Szakképző Iskola és Kollégium - Székhely",
        "city": "Szarvas",
        "address": "Vajda Péter utca 20.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.86764,
                "longitude": 20.543219999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.86674,
                20.544539999999998,
                46.86854,
                20.5419
            ],
            "_northWest": {
                "latitude": 46.86674,
                "longitude": 20.5419,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.86854,
                "longitude": 20.544539999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 227
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Szigeti Endre Technikum és Szakképző Iskola",
        "city": "Szeghalom",
        "address": "Ady Endre utca 3.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.8647754,
                "longitude": 20.5572821,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015064355122945017,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.86091268242932,
                20.56481427756147,
                46.868638117570676,
                20.549749922438526
            ],
            "_northWest": {
                "latitude": 46.86091268242932,
                "longitude": 20.549749922438526,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.868638117570676,
                "longitude": 20.56481427756147,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 228
    },
    {
        "region": "Dél-Alföld",
        "name": "Gyulai SZC Szigeti Endre Technikum és Szakképző Iskola Telephelye",
        "city": "Szeghalom",
        "address": "Ady Endre  utca 6.",
        "county": "Békés",
        "bestView": {
            "center": {
                "latitude": 46.8652237,
                "longitude": 20.5563451,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015064480909053657,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.861360982429325,
                20.56387734045453,
                46.86908641757068,
                20.548812859545475
            ],
            "_northWest": {
                "latitude": 46.861360982429325,
                "longitude": 20.548812859545475,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.86908641757068,
                "longitude": 20.56387734045453,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 229
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Bornemissza Gergely Technikum, Szakképző Iskola és Kollégiuma Székhely",
        "city": "Eger",
        "address": "Kertész út 128",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.8917444,
                "longitude": 20.3904101,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015360622011250769,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.887881682429324,
                20.398090411005626,
                47.89560711757068,
                20.382729788994375
            ],
            "_northWest": {
                "latitude": 47.887881682429324,
                "longitude": 20.382729788994375,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.89560711757068,
                "longitude": 20.398090411005626,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 230
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Bornemissza Gergely Technikum, Szakképző Iskola és Kollégiuma Vincellériskola Utcai Telephelye",
        "city": "Eger",
        "address": "Vincellériskola  utca 1",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.884550000000004,
                "longitude": 20.37849,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.88365,
                20.37983,
                47.885450000000006,
                20.37715
            ],
            "_northWest": {
                "latitude": 47.88365,
                "longitude": 20.37715,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.885450000000006,
                "longitude": 20.37983,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 231
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Damjanich János Technikum, Szakképző Iskola és Kollégium Bajcsy-Zsilinszky Endre úti telephelye",
        "city": "Hatvan",
        "address": "Bajcsy-Zsilinszky Endre út 8.",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 48.29551,
                "longitude": 20.43052,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.29461,
                20.431870000000004,
                48.29641,
                20.42917
            ],
            "_northWest": {
                "latitude": 48.29461,
                "longitude": 20.42917,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.29641,
                "longitude": 20.431870000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 232
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Damjanich János Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Hatvan",
        "address": "Vécsey  út 2/a.",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.66736908943875,
                "longitude": 19.679598661704002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015294481041060237,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.663506371868074,
                19.687245902224532,
                47.67123180700943,
                19.671951421183472
            ],
            "_northWest": {
                "latitude": 47.663506371868074,
                "longitude": 19.671951421183472,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.67123180700943,
                "longitude": 19.687245902224532,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 233
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC József Attila Technikum, Szakképző Iskola és Kollégium Kenyérgyár Úti Telephelye",
        "city": "Gyöngyös",
        "address": "Kenyérgyár út 9",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.7663658,
                "longitude": 19.9217869,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015323563810134999,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.762503082429326,
                19.92944868190507,
                47.77022851757068,
                19.914125118094933
            ],
            "_northWest": {
                "latitude": 47.762503082429326,
                "longitude": 19.914125118094933,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.77022851757068,
                "longitude": 19.92944868190507,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 234
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC József Attila Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Gyöngyös",
        "address": "Kócsag utca 36-38.",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.776175,
                "longitude": 19.914164999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00035000000000096065,
            "height": -0.001649999999997931,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.77535,
                19.914339999999996,
                47.777,
                19.91399
            ],
            "_northWest": {
                "latitude": 47.77535,
                "longitude": 19.91399,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.777,
                "longitude": 19.914339999999996,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 235
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Kossuth Zsuzsanna Technikum, Szakképző Iskola, Kollégium és Könyvtár",
        "city": "Eger",
        "address": "Bem tábornok utca 3.",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.90235,
                "longitude": 20.363275,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0015299999999989211,
            "height": -0.0001200000000025625,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.902289999999994,
                20.364040000000003,
                47.90241,
                20.36251
            ],
            "_northWest": {
                "latitude": 47.902289999999994,
                "longitude": 20.36251,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.90241,
                "longitude": 20.364040000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 236
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Március 15. Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Lőrinci",
        "address": "Kastélykert",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.760645,
                "longitude": 19.661165,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0002500000000011937,
            "height": -0.0007699999999957186,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.76026,
                19.66129,
                47.76102999999999,
                19.66104
            ],
            "_northWest": {
                "latitude": 47.76026,
                "longitude": 19.66104,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.76102999999999,
                "longitude": 19.66129,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 237
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Sárvári Kálmán Technikum, Szakképző Iskola és Kollégium Donát úti Telephely",
        "city": "Eger",
        "address": "Donát  út",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.909475,
                "longitude": 20.379925,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0024099999999975807,
            "height": -0.0006099999999946704,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.90917,
                20.38113,
                47.90978,
                20.37872
            ],
            "_northWest": {
                "latitude": 47.90917,
                "longitude": 20.37872,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.90978,
                "longitude": 20.38113,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 238
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Sárvári Kálmán Technikum, Szakképző Iskola és Kollégium Kisvölgy utcai Telephely",
        "city": "Eger",
        "address": "Kisvölgy utca 46",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.906530000000004,
                "longitude": 20.366970000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.90563,
                20.36831,
                47.907430000000005,
                20.365630000000003
            ],
            "_northWest": {
                "latitude": 47.90563,
                "longitude": 20.365630000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.907430000000005,
                "longitude": 20.36831,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 239
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Sárvári Kálmán Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Eger",
        "address": "Pozsonyi utca 4-6",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.88237,
                "longitude": 20.379640000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.88147,
                20.38098,
                47.88327,
                20.378300000000003
            ],
            "_northWest": {
                "latitude": 47.88147,
                "longitude": 20.378300000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.88327,
                "longitude": 20.38098,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 240
    },
    {
        "region": "Észak-Magyarország",
        "name": "Heves Megyei SZC Szent Lőrinc Vendéglátó és Idegenforgalmi Technikum és Szakképző Iskola",
        "city": "Eger",
        "address": "II. Rákóczi Ferenc utca 95/A",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.92006,
                "longitude": 20.36901,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.91916,
                20.37035,
                47.92096,
                20.36767
            ],
            "_northWest": {
                "latitude": 47.91916,
                "longitude": 20.36767,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.92096,
                "longitude": 20.37035,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 241
    },
    {
        "region": "Észak-Magyarország",
        "name": "HMSZC Remenyik Zsigmond Technikum",
        "city": "Füzesabony",
        "address": "Ifjúság út 17.",
        "county": "Heves",
        "bestView": {
            "center": {
                "latitude": 47.74719,
                "longitude": 20.41619,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.74629,
                20.41753,
                47.748090000000005,
                20.41485
            ],
            "_northWest": {
                "latitude": 47.74629,
                "longitude": 20.41485,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.748090000000005,
                "longitude": 20.41753,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 242
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Corvin Mátyás Technikum és Szakképző Iskola",
        "city": "Hódmezővásárhely ",
        "address": "Városház utca 1.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.4137,
                "longitude": 20.31639,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.4128,
                20.31769,
                46.4146,
                20.315089999999998
            ],
            "_northWest": {
                "latitude": 46.4128,
                "longitude": 20.315089999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.4146,
                "longitude": 20.31769,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 243
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Cseresnyés Kollégium",
        "city": "Hódmezővásárhely ",
        "address": "Oldalkosár utca 1.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.41351,
                "longitude": 20.31924,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.41261,
                20.32054,
                46.414410000000004,
                20.31794
            ],
            "_northWest": {
                "latitude": 46.41261,
                "longitude": 20.31794,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.414410000000004,
                "longitude": 20.32054,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 244
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Csongrádi Sághy Mihály Technikum, Szakképző Iskola és Kollégium",
        "city": "Csongrád",
        "address": "Gyöngyvirág utca 16/A",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.7054165,
                "longitude": 20.1633446,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015019832298563074,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.70155378242932,
                20.17085451614928,
                46.709279217570675,
                20.155834683850717
            ],
            "_northWest": {
                "latitude": 46.70155378242932,
                "longitude": 20.155834683850717,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.709279217570675,
                "longitude": 20.17085451614928,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 245
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Eötvös József Technikum",
        "city": "Hódmezővásárhely ",
        "address": "Szent Antal utca 5-9.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.415369999999996,
                "longitude": 20.32054,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.414469999999994,
                20.32184,
                46.41627,
                20.31924
            ],
            "_northWest": {
                "latitude": 46.414469999999994,
                "longitude": 20.31924,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.41627,
                "longitude": 20.32184,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 246
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Kalmár Zsigmond Szakképző Iskola",
        "city": "Hódmezővásárhely ",
        "address": "Bajcsy-Zsilinszky Endre utca 7-9.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.41673,
                "longitude": 20.33091,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.41583,
                20.33221,
                46.41763,
                20.32961
            ],
            "_northWest": {
                "latitude": 46.41583,
                "longitude": 20.32961,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.41763,
                "longitude": 20.33221,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 247
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Makói Návay Lajos Technikum és Kollégium",
        "city": "Makó ",
        "address": "Posta utca 4-6.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.21383,
                "longitude": 20.4752,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.21293,
                20.4765,
                46.21473,
                20.4739
            ],
            "_northWest": {
                "latitude": 46.21293,
                "longitude": 20.4739,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.21473,
                "longitude": 20.4765,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 248
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Makói Návay Lajos Technikum és Kollégium Bajza utcai telephely",
        "city": "Makó ",
        "address": "Bajza  utca 71-73.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.21861,
                "longitude": 20.49162,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.21771,
                20.49292,
                46.21951,
                20.49032
            ],
            "_northWest": {
                "latitude": 46.21771,
                "longitude": 20.49032,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.21951,
                "longitude": 20.49292,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 249
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Makói Návay Lajos Technikum és Kollégium Pulitzer Kollégium Telephely",
        "city": "Makó ",
        "address": "Posta utca 4-6.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.21383,
                "longitude": 20.4752,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.21293,
                20.4765,
                46.21473,
                20.4739
            ],
            "_northWest": {
                "latitude": 46.21293,
                "longitude": 20.4739,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.21473,
                "longitude": 20.4765,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 250
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Szentesi Boros Sámuel Technikum ",
        "city": "Szentes ",
        "address": "Ady Endre utca 6-8.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.65415,
                "longitude": 20.25777,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.65325,
                20.25908,
                46.65505,
                20.25646
            ],
            "_northWest": {
                "latitude": 46.65325,
                "longitude": 20.25646,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.65505,
                "longitude": 20.25908,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 251
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Szentesi Pollák Antal Technikum",
        "city": "Szentes ",
        "address": "Apponyi  tér 1.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.65403,
                "longitude": 20.26626,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.65313,
                20.26757,
                46.65493,
                20.26495
            ],
            "_northWest": {
                "latitude": 46.65313,
                "longitude": 20.26495,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.65493,
                "longitude": 20.26757,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 252
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Szentesi Zsoldos Ferenc Technikum",
        "city": "Szentes ",
        "address": "Szent Imre herceg utca 1.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.6501,
                "longitude": 20.25588,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.6492,
                20.25719,
                46.651,
                20.25457
            ],
            "_northWest": {
                "latitude": 46.6492,
                "longitude": 20.25457,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.651,
                "longitude": 20.25719,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 253
    },
    {
        "region": "Dél-Alföld",
        "name": "Hódmezővásárhelyi SZC Szentesi Zsoldos Ferenc Technikum Petőfi utcai Telephelye",
        "city": "Szentes ",
        "address": "Petőfi utca 15.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.64964,
                "longitude": 20.25787,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.64874,
                20.25918,
                46.65054,
                20.25656
            ],
            "_northWest": {
                "latitude": 46.64874,
                "longitude": 20.25656,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.65054,
                "longitude": 20.25918,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 254
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Barcsi Szakképző Iskola",
        "city": "Barcs",
        "address": "Barátság utca 9-11.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 45.961045,
                "longitude": 17.45552,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00003999999999848569,
            "height": -0.0007499999999964757,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.96067,
                17.45554,
                45.96142,
                17.4555
            ],
            "_northWest": {
                "latitude": 45.96067,
                "longitude": 17.4555,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.96142,
                "longitude": 17.45554,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 255
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Eötvös Loránd Műszaki Technikum és Kollégium",
        "city": "Kaposvár",
        "address": "Pázmány Péter utca 17.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.36253,
                "longitude": 17.80046,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.36163,
                17.80176,
                46.36343,
                17.79916
            ],
            "_northWest": {
                "latitude": 46.36163,
                "longitude": 17.79916,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.36343,
                "longitude": 17.80176,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 256
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Eötvös Loránd Műszaki Technikum és Kollégium Kollégiumi Intézményegysége",
        "city": "Kaposvár",
        "address": "Damjanich utca 17.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.36216,
                "longitude": 17.799509999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.36126,
                17.80081,
                46.363060000000004,
                17.798209999999997
            ],
            "_northWest": {
                "latitude": 46.36126,
                "longitude": 17.798209999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.363060000000004,
                "longitude": 17.80081,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 257
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Eötvös Loránd Műszaki Technikum és Kollégium Virág utcai telephelye",
        "city": "Kaposvár",
        "address": "Virág utca 32.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.361000000000004,
                "longitude": 17.8035,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.3601,
                17.8048,
                46.361900000000006,
                17.8022
            ],
            "_northWest": {
                "latitude": 46.3601,
                "longitude": 17.8022,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.361900000000006,
                "longitude": 17.8048,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 258
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Jálics Ernő Szakképző Iskola és Szakiskola",
        "city": "Kadarkút",
        "address": "Fő utca 1",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.230000000000004,
                "longitude": 17.61567,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.2291,
                17.616970000000002,
                46.230900000000005,
                17.61437
            ],
            "_northWest": {
                "latitude": 46.2291,
                "longitude": 17.61437,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.230900000000005,
                "longitude": 17.616970000000002,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 259
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Jálics Ernő Szakképző Iskola és Szakiskola Rákóczi utcai telephelye",
        "city": "Kadarkút",
        "address": "Rákóczi utca 10.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.23225,
                "longitude": 17.61822,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.23135,
                17.61952,
                46.23315,
                17.61692
            ],
            "_northWest": {
                "latitude": 46.23135,
                "longitude": 17.61692,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.23315,
                "longitude": 17.61952,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 260
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Lamping József Technikum és Szakképző Iskola",
        "city": "Kaposvár",
        "address": "Cseri út 6.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.35353,
                "longitude": 17.77738,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.35263,
                17.77868,
                46.35443,
                17.77608
            ],
            "_northWest": {
                "latitude": 46.35263,
                "longitude": 17.77608,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.35443,
                "longitude": 17.77868,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 261
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Nagyatádi Ady Endre Technikum és Gimnázium",
        "city": "Nagyatád",
        "address": "Dózsa György utca 13.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.232169999999996,
                "longitude": 17.35746,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.231269999999995,
                17.35876,
                46.23307,
                17.35616
            ],
            "_northWest": {
                "latitude": 46.231269999999995,
                "longitude": 17.35616,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.23307,
                "longitude": 17.35876,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 262
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Nagyatádi Ady Endre Technikum és Gimnázium Baross Gábor utcai telephelye",
        "city": "Nagyatád",
        "address": "Baross Gábor utca 10.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.22949,
                "longitude": 17.35846,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.22859,
                17.35976,
                46.23039,
                17.35716
            ],
            "_northWest": {
                "latitude": 46.22859,
                "longitude": 17.35716,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.23039,
                "longitude": 17.35976,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 263
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Nagyatádi Szakképző Iskola",
        "city": "Nagyatád",
        "address": "Baross G. utca 6.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.22938,
                "longitude": 17.35998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.22848,
                17.36128,
                46.23028,
                17.35868
            ],
            "_northWest": {
                "latitude": 46.22848,
                "longitude": 17.35868,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.23028,
                "longitude": 17.36128,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 264
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Noszlopy Gáspár Közgazdasági Technikum",
        "city": "Kaposvár",
        "address": "Szent Imre utca 2.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.361805000000004,
                "longitude": 17.793840000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00009999999999976694,
            "height": -0.006589999999995655,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.35851000000001,
                17.793890000000005,
                46.3651,
                17.79379
            ],
            "_northWest": {
                "latitude": 46.35851000000001,
                "longitude": 17.79379,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.3651,
                "longitude": 17.793890000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 265
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Noszlopy Gáspár Közgazdasági Technikum - Németh I.",
        "city": "Kaposvár",
        "address": "Németh István fasor 8.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.36037,
                "longitude": 17.7956,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.35947,
                17.7969,
                46.361270000000005,
                17.7943
            ],
            "_northWest": {
                "latitude": 46.35947,
                "longitude": 17.7943,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.361270000000005,
                "longitude": 17.7969,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 266
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Rudnay Gyula Szakképző Iskola és Kollégium",
        "city": "Tab",
        "address": "Virág utca 12-14.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.73389,
                "longitude": 18.03365,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.73299,
                18.03496,
                46.734790000000004,
                18.03234
            ],
            "_northWest": {
                "latitude": 46.73299,
                "longitude": 18.03234,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.734790000000004,
                "longitude": 18.03496,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 267
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kaposvári SZC Széchenyi István Technikum és Szakképző Iskola",
        "city": "Kaposvár",
        "address": "Rippl-Rónai utca 15.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.35904,
                "longitude": 17.78596,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.35814,
                17.78726,
                46.35994,
                17.78466
            ],
            "_northWest": {
                "latitude": 46.35814,
                "longitude": 17.78466,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.35994,
                "longitude": 17.78726,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 268
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Hámori András Technikum és Szakképző Iskola Székhely",
        "city": "Tiszafüred",
        "address": "Ady Endre út 4/A.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.51149,
                "longitude": 20.63779,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.51059,
                20.63912,
                47.51239,
                20.63646
            ],
            "_northWest": {
                "latitude": 47.51059,
                "longitude": 20.63646,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.51239,
                "longitude": 20.63912,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 269
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Kunszentmártoni Technikum és Szakképző Iskola Rákóczi utcai telephelye",
        "city": "Kunszentmárton",
        "address": "Rákóczi  utca 2",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 46.84052,
                "longitude": 20.28327,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002619999999996736,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.83962,
                20.28458,
                46.84142,
                20.281960000000005
            ],
            "_northWest": {
                "latitude": 46.83962,
                "longitude": 20.281960000000005,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.84142,
                "longitude": 20.28458,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 270
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Kunszentmártoni Technikum és Szakképző Iskola Székhely",
        "city": "Kunszentmárton",
        "address": "Kossuth Lajos utca 37.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 46.843875,
                "longitude": 20.29329,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0001600000000010482,
            "height": -0.00009000000000014552,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.84383,
                20.29337,
                46.84392,
                20.29321
            ],
            "_northWest": {
                "latitude": 46.84383,
                "longitude": 20.29321,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.84392,
                "longitude": 20.29337,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 271
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Kunszentmártoni Technikum és Szakképző Iskola Végh utcai telephelye",
        "city": "Kunszentmárton",
        "address": "Végh utca 33",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 46.83949,
                "longitude": 20.29366,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.838589999999996,
                20.29497,
                46.84039,
                20.29235
            ],
            "_northWest": {
                "latitude": 46.838589999999996,
                "longitude": 20.29235,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.84039,
                "longitude": 20.29497,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 272
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Lábassy János Technikum és Szakképző Iskola",
        "city": "Törökszentmiklós",
        "address": "Almásy út 51",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.17355,
                "longitude": 20.41071,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17265,
                20.41203,
                47.17445,
                20.409390000000002
            ],
            "_northWest": {
                "latitude": 47.17265,
                "longitude": 20.409390000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17445,
                "longitude": 20.41203,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 273
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Mezőtúri Szakképző Iskola és Kollégium Székhely",
        "city": "Mezőtúr",
        "address": "Földvári út 8",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.002179999999996,
                "longitude": 20.61462,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.001279999999994,
                20.61594,
                47.00308,
                20.6133
            ],
            "_northWest": {
                "latitude": 47.001279999999994,
                "longitude": 20.6133,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.00308,
                "longitude": 20.61594,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 274
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Nagy László Gimnázium, Technikum és Szakképző Iskola",
        "city": "Kunhegyes",
        "address": "Kossuth Lajos  út 30-32.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.31194,
                "longitude": 20.47975,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.31104,
                20.48108,
                47.31284,
                20.47842
            ],
            "_northWest": {
                "latitude": 47.31104,
                "longitude": 20.47842,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.31284,
                "longitude": 20.48108,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 275
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Nagy László Gimnázium, Technikum és Szakképző Iskola - Tiszaburai telephely",
        "city": "Tiszabura",
        "address": "Kossuth út 62/B.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.3428379,
                "longitude": 20.4340528,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01520022788758979,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.33897518242932,
                20.441652913943795,
                47.346700617570676,
                20.426452686056205
            ],
            "_northWest": {
                "latitude": 47.33897518242932,
                "longitude": 20.426452686056205,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.346700617570676,
                "longitude": 20.441652913943795,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 276
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Nagy László Gimnázium, Technikum és Szakképző Iskola Székhely",
        "city": "Kunhegyes",
        "address": "Kossuth Lajos utca 15-17",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.37457,
                "longitude": 20.63389,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.37367,
                20.63522,
                47.37547,
                20.63256
            ],
            "_northWest": {
                "latitude": 47.37367,
                "longitude": 20.63256,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.37547,
                "longitude": 20.63522,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 277
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Teleki Blanka Gimnázium, Technikum és Kollégium",
        "city": "Mezőtúr",
        "address": "Dózsa György utca 17.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.0008,
                "longitude": 20.62543,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.9999,
                20.62675,
                47.0017,
                20.62411
            ],
            "_northWest": {
                "latitude": 46.9999,
                "longitude": 20.62411,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.0017,
                "longitude": 20.62675,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 278
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SzC Teleki Blanka Gimnázium, Technikum és Kollégium Dózsa György utca 19. telephelye ",
        "city": "Mezőtúr",
        "address": "Dózsa György utca 19.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.00125,
                "longitude": 20.62444,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.00035,
                20.62576,
                47.00215,
                20.62312
            ],
            "_northWest": {
                "latitude": 47.00035,
                "longitude": 20.62312,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.00215,
                "longitude": 20.62576,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 279
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Ványai Ambrus Technikum, Szakképző Iskola és Kollégium",
        "city": "Túrkeve",
        "address": "József Attila utca 23",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.10856,
                "longitude": 20.73236,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.107659999999996,
                20.73368,
                47.10946,
                20.73104
            ],
            "_northWest": {
                "latitude": 47.107659999999996,
                "longitude": 20.73104,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.10946,
                "longitude": 20.73368,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 280
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Varró István Technikum, Szakképző Iskola és Kollégium Laktanya úti telephelye",
        "city": "Karcag",
        "address": "Laktanya út 1",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.306294,
                "longitude": 20.938618,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015189717662970281,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.302431282429325,
                20.946212858831487,
                47.31015671757068,
                20.931023141168517
            ],
            "_northWest": {
                "latitude": 47.302431282429325,
                "longitude": 20.931023141168517,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.31015671757068,
                "longitude": 20.946212858831487,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 281
    },
    {
        "region": "Észak-Alföld",
        "name": "Karcagi SZC Varró István Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Karcag",
        "address": "Varró István utca 8",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.31149,
                "longitude": 20.9163,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.31059,
                20.91763,
                47.31239,
                20.91497
            ],
            "_northWest": {
                "latitude": 47.31059,
                "longitude": 20.91497,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.31239,
                "longitude": 20.91763,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 282
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Fazekas István Szakiskola",
        "city": "Kecskemét",
        "address": "Erzsébet körút 73",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.90244,
                "longitude": 19.70309,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.90154,
                19.70441,
                46.90334,
                19.70177
            ],
            "_northWest": {
                "latitude": 46.90154,
                "longitude": 19.70177,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.90334,
                "longitude": 19.70441,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 283
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Gáspár András Technikum",
        "city": "Kecskemét",
        "address": "Hunyadi tér 2",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.914950000000005,
                "longitude": 19.70571,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.000740000000000407,
            "height": -0.0010200000000040177,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.91444,
                19.70608,
                46.91546000000001,
                19.70534
            ],
            "_northWest": {
                "latitude": 46.91444,
                "longitude": 19.70534,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.91546000000001,
                "longitude": 19.70608,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 284
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Gróf Károlyi Sándor Technikum ",
        "city": "Kecskemét",
        "address": "Bibó István utca 1.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.90891,
                "longitude": 19.6831,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.90801,
                19.68442,
                46.90981,
                19.68178
            ],
            "_northWest": {
                "latitude": 46.90801,
                "longitude": 19.68178,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.90981,
                "longitude": 19.68442,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 285
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Kada Elek Technikum",
        "city": "Kecskemét",
        "address": "Katona József tér 4.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.90566,
                "longitude": 19.69359,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0007599999999996498,
            "height": -0.0005799999999993588,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.90537,
                19.69397,
                46.90595,
                19.69321
            ],
            "_northWest": {
                "latitude": 46.90537,
                "longitude": 19.69321,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.90595,
                "longitude": 19.69397,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 286
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Kandó Kálmán Technikum",
        "city": "Kecskemét",
        "address": "Bethlen körút 63.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.91103,
                "longitude": 19.70018,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00043999999999755346,
            "height": -0.00035999999999347665,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.910849999999996,
                19.7004,
                46.91121,
                19.69996
            ],
            "_northWest": {
                "latitude": 46.910849999999996,
                "longitude": 19.69996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.91121,
                "longitude": 19.7004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 287
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Kollégium Hunyadi János tér 4. sz. alatti telephelye",
        "city": "Kecskemét",
        "address": "Hunyadi János  tér 4",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.915745,
                "longitude": 19.70628,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00039999999999906777,
            "height": -0.0005699999999961847,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.91546,
                19.70648,
                46.91603,
                19.70608
            ],
            "_northWest": {
                "latitude": 46.91546,
                "longitude": 19.70608,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.91603,
                "longitude": 19.70648,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 288
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Kollégium Nyíri út 32. sz. alatti telephelye ",
        "city": "Kecskemét",
        "address": "Nyíri  út 32.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.90851000000001,
                "longitude": 19.68006,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.005479999999998597,
            "height": -0.0026200000000002888,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.9072,
                19.6828,
                46.90982000000001,
                19.67732
            ],
            "_northWest": {
                "latitude": 46.9072,
                "longitude": 19.67732,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.90982000000001,
                "longitude": 19.6828,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 289
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Kollégium Nyíri út 73. sz. alatti telephelye ",
        "city": "Kecskemét",
        "address": "Nyíri út 73.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.91418,
                "longitude": 19.66457,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.91328,
                19.66589,
                46.91508,
                19.66325
            ],
            "_northWest": {
                "latitude": 46.91328,
                "longitude": 19.66325,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.91508,
                "longitude": 19.66589,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 290
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Széchenyi István Technikum",
        "city": "Kecskemét",
        "address": "Nyíri út 32",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.914500000000004,
                "longitude": 19.663075,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0078099999999992065,
            "height": -0.0025400000000033174,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.91323,
                19.66698,
                46.91577000000001,
                19.65917
            ],
            "_northWest": {
                "latitude": 46.91323,
                "longitude": 19.65917,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.91577000000001,
                "longitude": 19.66698,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 291
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Szent-Györgyi Albert Technikum",
        "city": "Kecskemét",
        "address": "Nyíri  út 73",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.91418,
                "longitude": 19.66457,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.91328,
                19.66589,
                46.91508,
                19.66325
            ],
            "_northWest": {
                "latitude": 46.91328,
                "longitude": 19.66325,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.91508,
                "longitude": 19.66589,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 292
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Tiszakécskei Kiss Bálint Szakképző Iskola",
        "city": "Tiszakécske ",
        "address": "Kossuth Lajos  utca 65.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.92931,
                "longitude": 20.10507,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.92841,
                20.10639,
                46.93021,
                20.10375
            ],
            "_northWest": {
                "latitude": 46.92841,
                "longitude": 20.10375,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.93021,
                "longitude": 20.10639,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 293
    },
    {
        "region": "Dél-Alföld",
        "name": "Kecskeméti SZC Virágh Gedeon Technikum",
        "city": "Kunszentmiklós",
        "address": "Apostol Pál utca 2-6.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 47.02756,
                "longitude": 19.12123,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.02666,
                19.12255,
                47.02846,
                19.11991
            ],
            "_northWest": {
                "latitude": 47.02666,
                "longitude": 19.11991,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.02846,
                "longitude": 19.12255,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 294
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi Szakképzési Centrum Vári Szabó István Szakképző Iskola és Kollégium",
        "city": "Kiskunhalas",
        "address": "Kazinczy utca 5",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.440245000000004,
                "longitude": 19.47755,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.006160000000001276,
            "height": -0.003109999999999502,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.43869000000001,
                19.48063,
                46.4418,
                19.47447
            ],
            "_northWest": {
                "latitude": 46.43869000000001,
                "longitude": 19.47447,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.4418,
                "longitude": 19.48063,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 295
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Dékáni Árpád Technikum",
        "city": "Kiskunhalas",
        "address": "Kossuth utca 23",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.4307,
                "longitude": 19.48543,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.4298,
                19.48673,
                46.4316,
                19.48413
            ],
            "_northWest": {
                "latitude": 46.4298,
                "longitude": 19.48413,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.4316,
                "longitude": 19.48673,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 296
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Kiskőrösi Wattay Technikum és Kollégium Izsáki úti telephely",
        "city": "Kiskőrös",
        "address": "Izsáki út 16.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.63109838548882,
                "longitude": 19.29846389439568,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014999198234527,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.62723566791814,
                19.305963493512944,
                46.634961103059496,
                19.290964295278417
            ],
            "_northWest": {
                "latitude": 46.62723566791814,
                "longitude": 19.290964295278417,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.634961103059496,
                "longitude": 19.305963493512944,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 297
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Kiskőrösi Wattay Technikum és Kollégium Kollégiumi telephely",
        "city": "Kiskőrös",
        "address": "Árpád utca 18.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.6197393,
                "longitude": 19.2897134,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014996051654875941,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.61587658242932,
                19.297211425827438,
                46.623602017570676,
                19.282215374172562
            ],
            "_northWest": {
                "latitude": 46.61587658242932,
                "longitude": 19.282215374172562,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.623602017570676,
                "longitude": 19.297211425827438,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 298
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Kiskőrösi Wattay Technikum és Kollégium Sárkány József utcai telephely",
        "city": "Kiskőrös",
        "address": "Sárkány József utca 11.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.6314009,
                "longitude": 19.2895246,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014999282060166763,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.627538182429326,
                19.297024241030083,
                46.63526361757068,
                19.282024958969917
            ],
            "_northWest": {
                "latitude": 46.627538182429326,
                "longitude": 19.282024958969917,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.63526361757068,
                "longitude": 19.297024241030083,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 299
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Kiskőrösi Wattay Technikum és Kollégium Székhely",
        "city": "Kiskőrös",
        "address": "Árpád utca 20.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.6192462,
                "longitude": 19.2901206,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014995915104513813,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.61538348242932,
                19.29761855755226,
                46.623108917570676,
                19.282622642447745
            ],
            "_northWest": {
                "latitude": 46.61538348242932,
                "longitude": 19.282622642447745,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.623108917570676,
                "longitude": 19.29761855755226,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 300
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Kiskunfélegyházi Kossuth Lajos Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Kiskunfélegyháza",
        "address": "Kossuth Lajos utca 34",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.70767,
                "longitude": 19.83658,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.70677,
                19.83789,
                46.70857,
                19.83527
            ],
            "_northWest": {
                "latitude": 46.70677,
                "longitude": 19.83527,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.70857,
                "longitude": 19.83789,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 301
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Kiskunfélegyházi Közgazdasági Technikum ",
        "city": "Kiskunfélegyháza",
        "address": "Oskola  utca 1-3.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.71337,
                "longitude": 19.85175,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.712469999999996,
                19.85306,
                46.71427,
                19.85044
            ],
            "_northWest": {
                "latitude": 46.712469999999996,
                "longitude": 19.85044,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.71427,
                "longitude": 19.85306,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 302
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Kiskunfélegyházi Közgazdasági Technikum - Telephely- 013",
        "city": "Kiskunfélegyháza",
        "address": "Korona utca 4",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.71316,
                "longitude": 19.84935,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.71226,
                19.85066,
                46.71406,
                19.84804
            ],
            "_northWest": {
                "latitude": 46.71226,
                "longitude": 19.84804,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.71406,
                "longitude": 19.85066,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 303
    },
    {
        "region": "Dél-Alföld",
        "name": "Kiskunhalasi SZC Vári Szabó István Szakképző Iskola és Kollégium Tanműhely",
        "city": "Kiskunhalas",
        "address": "Kőrösi út 1",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.44513,
                "longitude": 19.472459999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002619999999996736,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.44423,
                19.473769999999995,
                46.44603,
                19.47115
            ],
            "_northWest": {
                "latitude": 46.44423,
                "longitude": 19.47115,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.44603,
                "longitude": 19.473769999999995,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 304
    },
    {
        "region": "Észak-Alföld",
        "name": "Kisvárdai SZC Ady Endre Technikum és Kollégium",
        "city": "Csenger",
        "address": "Honvéd  utca 5.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.835300000000004,
                "longitude": 22.679740000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.8344,
                22.68108,
                47.836200000000005,
                22.678400000000003
            ],
            "_northWest": {
                "latitude": 47.8344,
                "longitude": 22.678400000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.836200000000005,
                "longitude": 22.68108,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 305
    },
    {
        "region": "Észak-Alföld",
        "name": "Kisvárdai SZC Ady Endre Technikum és Kollégium Ady Endre utcai telephelye",
        "city": "Csenger",
        "address": "Ady Endre utca 6",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.8358,
                "longitude": 22.68122,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.8349,
                22.68256,
                47.8367,
                22.67988
            ],
            "_northWest": {
                "latitude": 47.8349,
                "longitude": 22.67988,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.8367,
                "longitude": 22.68256,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 306
    },
    {
        "region": "Észak-Alföld",
        "name": "Kisvárdai SZC Fehérgyarmati Petőfi Sándor Technikum",
        "city": "Fehérgyarmat",
        "address": "Május 14.  tér 16.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.99207,
                "longitude": 22.51362,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.99117,
                22.51496,
                47.99297,
                22.51228
            ],
            "_northWest": {
                "latitude": 47.99117,
                "longitude": 22.51228,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.99297,
                "longitude": 22.51496,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 307
    },
    {
        "region": "Észak-Alföld",
        "name": "Kisvárdai SZC II. Rákóczi Ferenc Technikum és Szakképző Iskola",
        "city": "Kisvárda",
        "address": "Mártírok útja 8",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 48.224199999999996,
                "longitude": 22.081020000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.223299999999995,
                22.082370000000004,
                48.2251,
                22.07967
            ],
            "_northWest": {
                "latitude": 48.223299999999995,
                "longitude": 22.07967,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.2251,
                "longitude": 22.082370000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 308
    },
    {
        "region": "Észak-Alföld",
        "name": "Kisvárdai SZC Kandó Kálmán Technikum és Dr. Béres József Kollégium",
        "city": "Záhony",
        "address": "Kárpát út 4.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.342824,
                "longitude": 20.435108,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015200223885933895,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.338961282429324,
                20.442708111942967,
                47.34668671757068,
                20.427507888057033
            ],
            "_northWest": {
                "latitude": 47.338961282429324,
                "longitude": 20.427507888057033,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.34668671757068,
                "longitude": 20.442708111942967,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 309
    },
    {
        "region": "Észak-Alföld",
        "name": "Kisvárdai SZC Móricz Zsigmond Szakképző Iskola",
        "city": "Fehérgyarmat",
        "address": "Iskola köz 2",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.99212,
                "longitude": 22.50871,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.99122,
                22.51005,
                47.99302,
                22.50737
            ],
            "_northWest": {
                "latitude": 47.99122,
                "longitude": 22.50737,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.99302,
                "longitude": 22.51005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 310
    },
    {
        "region": "Észak-Alföld",
        "name": "Kisvárdai SZC Móricz Zsigmond Szakképző Iskola Tarpai telephelye",
        "city": "Tarpa",
        "address": "Kossuth utca 18.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 48.104420000000005,
                "longitude": 22.527639999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.10352,
                22.52899,
                48.105320000000006,
                22.526289999999996
            ],
            "_northWest": {
                "latitude": 48.10352,
                "longitude": 22.526289999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.105320000000006,
                "longitude": 22.52899,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 311
    },
    {
        "region": "Dél-Alföld",
        "name": "Kossuth _ KOLLÉGIUM",
        "city": "Kiskunfélegyháza",
        "address": "Kossuth Lajos utca 34",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.70767,
                "longitude": 19.83658,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.70677,
                19.83789,
                46.70857,
                19.83527
            ],
            "_northWest": {
                "latitude": 46.70677,
                "longitude": 19.83527,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.70857,
                "longitude": 19.83789,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 312
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Kossuth téri épület",
        "city": "Szigetvár",
        "address": "Kossuth Lajos tér 13.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 46.0462,
                "longitude": 17.80343,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.0453,
                17.80473,
                46.0471,
                17.80213
            ],
            "_northWest": {
                "latitude": 46.0453,
                "longitude": 17.80213,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.0471,
                "longitude": 17.80473,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 313
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Középrigóc",
        "city": "Barcs",
        "address": "Középrigóc",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 45.9796257019043,
                "longitude": 17.519466400146484,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.003665924072265625,
            "height": -0.006317138671875,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.97646713256836,
                17.521299362182617,
                45.982784271240234,
                17.51763343811035
            ],
            "_northWest": {
                "latitude": 45.97646713256836,
                "longitude": 17.51763343811035,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.982784271240234,
                "longitude": 17.521299362182617,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 314
    },
    {
        "region": "Észak-Alföld",
        "name": "Kreatív Technikum és Szakképző Iskola",
        "city": "Szolnok",
        "address": "Áchim András  utca 12-14.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.17727,
                "longitude": 20.21138,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17637,
                20.212699999999998,
                47.17817,
                20.21006
            ],
            "_northWest": {
                "latitude": 47.17637,
                "longitude": 20.21006,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17817,
                "longitude": 20.212699999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 315
    },
    {
        "region": "Észak-Alföld",
        "name": "Mátészalkai Szakképzési Centrum Gépészeti Technikum és Kollégium Székhely",
        "city": "Mátészalka",
        "address": "Kölcsey utca 12",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.95337,
                "longitude": 22.31768,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.95247,
                22.31902,
                47.95427,
                22.31634
            ],
            "_northWest": {
                "latitude": 47.95247,
                "longitude": 22.31634,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.95427,
                "longitude": 22.31902,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 316
    },
    {
        "region": "Észak-Alföld",
        "name": "Mátészalkai SZC Bethlen Gábor Technikum, Szakképző Iskola és Kollégium",
        "city": "Nyírbátor",
        "address": "Füveskert utca 9.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.834755,
                "longitude": 22.14264,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0009399999999999409,
            "height": -0.002769999999998163,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.83337,
                22.14311,
                47.83614,
                22.14217
            ],
            "_northWest": {
                "latitude": 47.83337,
                "longitude": 22.14217,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.83614,
                "longitude": 22.14311,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 317
    },
    {
        "region": "Észak-Alföld",
        "name": "Mátészalkai SZC Bethlen Gábor Technikum, Szakképző Iskola és Kollégium - Kollégium",
        "city": "Nyírbátor",
        "address": "Kossuth utca 26.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.83749,
                "longitude": 22.131030000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.83659,
                22.13237,
                47.838390000000004,
                22.129690000000004
            ],
            "_northWest": {
                "latitude": 47.83659,
                "longitude": 22.129690000000004,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.838390000000004,
                "longitude": 22.13237,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 318
    },
    {
        "region": "Észak-Alföld",
        "name": "Mátészalkai SZC Budai Nagy Antal Technikum és Szakgimnázium",
        "city": "Nagykálló",
        "address": "Korányi Frigyes út 27.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.87629,
                "longitude": 21.83936,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.875389999999996,
                21.8407,
                47.87719,
                21.83802
            ],
            "_northWest": {
                "latitude": 47.875389999999996,
                "longitude": 21.83802,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.87719,
                "longitude": 21.8407,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 319
    },
    {
        "region": "Észak-Alföld",
        "name": "Mátészalkai SZC Déri Miksa Technikum, Szakképző iskola és Kollégium",
        "city": "Mátészalka",
        "address": "Baross László utca 9-11",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.9459,
                "longitude": 22.31173,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.945,
                22.31307,
                47.9468,
                22.31039
            ],
            "_northWest": {
                "latitude": 47.945,
                "longitude": 22.31039,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.9468,
                "longitude": 22.31307,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 320
    },
    {
        "region": "Észak-Alföld",
        "name": "Mátészalkai SZC Kállay Rudolf Szakképző Iskola",
        "city": "Nagykálló",
        "address": "Kossuth út 8.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 48.0600794,
                "longitude": 22.1925985,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01541077604434804,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.05621668242932,
                22.200303888022173,
                48.063942117570676,
                22.184893111977825
            ],
            "_northWest": {
                "latitude": 48.05621668242932,
                "longitude": 22.184893111977825,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.063942117570676,
                "longitude": 22.200303888022173,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 321
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Andrássy Gyula Gépipari Technikum",
        "city": "Miskolc",
        "address": "Soltész Nagy Kálmán utca 10",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.10232,
                "longitude": 20.79761,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.10142,
                20.79896,
                48.10322,
                20.796259999999997
            ],
            "_northWest": {
                "latitude": 48.10142,
                "longitude": 20.796259999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.10322,
                "longitude": 20.79896,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 322
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Baross Gábor Szakgimnáziuma ",
        "city": "Miskolc",
        "address": "Rácz Ádám utca 54-56",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.10346,
                "longitude": 20.73814,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.10256,
                20.739490000000004,
                48.10436,
                20.73679
            ],
            "_northWest": {
                "latitude": 48.10256,
                "longitude": 20.73679,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.10436,
                "longitude": 20.739490000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 323
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Berzeviczy Gergely Szakgimnáziuma Székhely",
        "city": "Miskolc",
        "address": "Hősök tere 1",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.105175,
                "longitude": 20.785255,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0005300000000012517,
            "height": -0.00014999999999787406,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.10510000000001,
                20.785519999999998,
                48.10525,
                20.78499
            ],
            "_northWest": {
                "latitude": 48.10510000000001,
                "longitude": 20.78499,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.10525,
                "longitude": 20.785519999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 324
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Bláthy Ottó Villamosipari Technikum",
        "city": "Miskolc",
        "address": "Soltész Nagy Kálmán utca 7",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.103,
                "longitude": 20.79795,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.1021,
                20.7993,
                48.1039,
                20.7966
            ],
            "_northWest": {
                "latitude": 48.1021,
                "longitude": 20.7966,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.1039,
                "longitude": 20.7993,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 325
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Kandó Kálmán Informatikai Technikum",
        "city": "Miskolc",
        "address": "Palóczy László utca 3.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.10528,
                "longitude": 20.780315,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0004099999999986892,
            "height": -0.00003999999999848569,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.10526,
                20.780520000000003,
                48.1053,
                20.78011
            ],
            "_northWest": {
                "latitude": 48.10526,
                "longitude": 20.78011,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.1053,
                "longitude": 20.780520000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 326
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Kós Károly Építőipari, Kreatív Technikum és Szakképző Iskola",
        "city": "Miskolc",
        "address": "Gagarin utca 54",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.094899999999996,
                "longitude": 20.7068,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.093999999999994,
                20.708150000000003,
                48.0958,
                20.70545
            ],
            "_northWest": {
                "latitude": 48.093999999999994,
                "longitude": 20.70545,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.0958,
                "longitude": 20.708150000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 327
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Mezőcsáti Gimnázium és Szakképző Iskola",
        "city": "Mezőcsát",
        "address": "Kossuth Lajos utca 12.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.10419,
                "longitude": 20.782339999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.10329,
                20.78369,
                48.105090000000004,
                20.780989999999996
            ],
            "_northWest": {
                "latitude": 48.10329,
                "longitude": 20.780989999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.105090000000004,
                "longitude": 20.78369,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 328
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Mezőkövesdi Szent László Gimnázium és Közgazdasági Technikum",
        "city": "Mezőkövesd",
        "address": "Mátyás kiráy út 146",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 47.81297,
                "longitude": 20.57765,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.81207,
                20.578989999999997,
                47.81387,
                20.57631
            ],
            "_northWest": {
                "latitude": 47.81207,
                "longitude": 20.57631,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.81387,
                "longitude": 20.578989999999997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 329
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Szemere Bertalan Technikum, Szakképző Iskola és Kollégium",
        "city": "Miskolc",
        "address": "Ifjúság utca 16-20.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.08596,
                "longitude": 20.78306,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.08506,
                20.78441,
                48.08686,
                20.781709999999997
            ],
            "_northWest": {
                "latitude": 48.08506,
                "longitude": 20.781709999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.08686,
                "longitude": 20.78441,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 330
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Szemere Bertalan Technikum, Szakképző Iskola és Kollégium - Mezőkövesdi telephelye",
        "city": "Mezőkövesd",
        "address": "Damjanich utca 1.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.2190699,
                "longitude": 20.7063922,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015458570557377982,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.215207182429324,
                20.71412148527869,
                48.22293261757068,
                20.69866291472131
            ],
            "_northWest": {
                "latitude": 48.215207182429324,
                "longitude": 20.69866291472131,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.22293261757068,
                "longitude": 20.71412148527869,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 331
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Szemere Bertalan Technikum, Szakképző Iskola és Kollégium - Téglagyár utcai telephelye",
        "city": "Miskolc",
        "address": "Téglagyár utca 1.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.09159,
                "longitude": 20.72764,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.090689999999995,
                20.728990000000003,
                48.09249,
                20.72629
            ],
            "_northWest": {
                "latitude": 48.090689999999995,
                "longitude": 20.72629,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.09249,
                "longitude": 20.728990000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 332
    },
    {
        "region": "Észak-Magyarország",
        "name": "Miskolci SZC Szentpáli István Kereskedelmi és Vendéglátó Technikum és Szakképző Iskola",
        "city": "Miskolc",
        "address": "Herman Ottó utca 2.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.101929999999996,
                "longitude": 20.74955,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.101029999999994,
                20.7509,
                48.10283,
                20.748199999999997
            ],
            "_northWest": {
                "latitude": 48.101029999999994,
                "longitude": 20.748199999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.10283,
                "longitude": 20.7509,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 333
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Mohácsi Radnóti Miklós Technikum kőműves tanműhely és parkoló",
        "city": "Mohács",
        "address": "Mohácsi Jenő  utca 1/b",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 45.99676,
                "longitude": 18.678359999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0025799999999982504,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.99586,
                18.679649999999995,
                45.99766,
                18.67707
            ],
            "_northWest": {
                "latitude": 45.99586,
                "longitude": 18.67707,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.99766,
                "longitude": 18.679649999999995,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 334
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Mohácsi Radnóti Miklós Technikum Sportmalom",
        "city": "Mohács",
        "address": "Mohácsi Jenő utca 2/b.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 45.99673,
                "longitude": 18.67866,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002580000000001803,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.99583,
                18.67995,
                45.99763,
                18.67737
            ],
            "_northWest": {
                "latitude": 45.99583,
                "longitude": 18.67737,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.99763,
                "longitude": 18.67995,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 335
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Mohácsi Radnóti Miklós Technikum tankonyha",
        "city": "Mohács",
        "address": "Pécsi út 6/b.",
        "county": "Baranya",
        "bestView": {
            "center": {
                "latitude": 45.996125,
                "longitude": 18.67772,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0011800000000015132,
            "height": -0.00009000000000014552,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                45.99608,
                18.678310000000003,
                45.99617,
                18.67713
            ],
            "_northWest": {
                "latitude": 45.99608,
                "longitude": 18.67713,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 45.99617,
                "longitude": 18.678310000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 336
    },
    {
        "region": "Dél-Alföld",
        "name": "Molnártelepi tanműhely",
        "city": "Kiskunfélegyháza",
        "address": "Molnár telep",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.71864700317383,
                "longitude": 19.868746757507324,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.22772789001464844,
            "height": -0.230926513671875,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.60318374633789,
                19.98261070251465,
                46.834110260009766,
                19.7548828125
            ],
            "_northWest": {
                "latitude": 46.60318374633789,
                "longitude": 19.7548828125,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.834110260009766,
                "longitude": 19.98261070251465,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 337
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Nagykanizsai SZC Cserháti Sándor Technikum és Kollégium",
        "city": "Nagykanizsa",
        "address": "Ady Endre utca 74/a",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.442979,
                "longitude": 16.986582,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014947332386938683,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.439116282429325,
                16.994055666193468,
                46.44684171757068,
                16.97910833380653
            ],
            "_northWest": {
                "latitude": 46.439116282429325,
                "longitude": 16.97910833380653,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.44684171757068,
                "longitude": 16.994055666193468,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 338
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Nagykanizsai SZC Thúry György Technikum",
        "city": "Nagykanizsa",
        "address": "Ady Endre utca 29.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.45122,
                "longitude": 16.98819,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.45032,
                16.9895,
                46.45212,
                16.98688
            ],
            "_northWest": {
                "latitude": 46.45032,
                "longitude": 16.98688,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.45212,
                "longitude": 16.9895,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 339
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Nagykanizsai SZC Zsigmondy Vilmos Technikum",
        "city": "Nagykanizsa",
        "address": "Hunyadi János utca 18.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.457475,
                "longitude": 16.995065,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01495131055253296,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.453612282429326,
                17.002540655276267,
                46.46133771757068,
                16.987589344723734
            ],
            "_northWest": {
                "latitude": 46.453612282429326,
                "longitude": 16.987589344723734,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.46133771757068,
                "longitude": 17.002540655276267,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 340
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Nagykanizsai SZC Zsigmondy Vilmos Technikum Erdész utcai telephely",
        "city": "Nagykanizsa",
        "address": "Erdész utca 30.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.44282,
                "longitude": 17.00194,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.441919999999996,
                17.00325,
                46.44372,
                17.00063
            ],
            "_northWest": {
                "latitude": 46.441919999999996,
                "longitude": 17.00063,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.44372,
                "longitude": 17.00325,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 341
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Nagykanizsai SZC Zsigmondy Vilmos Technikum Petőfi utca 3. telephely",
        "city": "Nagykanizsa",
        "address": "Petőfi utca 3.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.45653,
                "longitude": 16.99874,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.45563,
                17.00005,
                46.45743,
                16.99743
            ],
            "_northWest": {
                "latitude": 46.45563,
                "longitude": 16.99743,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.45743,
                "longitude": 17.00005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 342
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Nagykanizsai SZC Zsigmondy Vilmos Technikum Petőfi utca 5. telephely",
        "city": "Nagykanizsa",
        "address": "Petőfi utca 5.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.45651,
                "longitude": 16.99852,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0001600000000010482,
            "height": -0.00014000000000180535,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.45644,
                16.9986,
                46.45658,
                16.99844
            ],
            "_northWest": {
                "latitude": 46.45644,
                "longitude": 16.99844,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.45658,
                "longitude": 16.9986,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 343
    },
    {
        "region": "Észak-Magyarország",
        "name": "NMSZC Mikszáth Kálmán Technikum és Szakképző Iskola",
        "city": "Balassagyarmat",
        "address": "Hétvezér út 26",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.0713918,
                "longitude": 19.2863184,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01541416300513987,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.067529082429324,
                19.29402548150257,
                48.07525451757068,
                19.27861131849743
            ],
            "_northWest": {
                "latitude": 48.067529082429324,
                "longitude": 19.27861131849743,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.07525451757068,
                "longitude": 19.29402548150257,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 344
    },
    {
        "region": "Észak-Magyarország",
        "name": "NMSZC Mikszáth Kálmán Technikum és Szakképző Iskola József Attila út 3. alatti Telephelye",
        "city": "Balassagyarmat",
        "address": "József Attila út 3",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.03717,
                "longitude": 19.168799999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.03627,
                19.170139999999996,
                48.038070000000005,
                19.16746
            ],
            "_northWest": {
                "latitude": 48.03627,
                "longitude": 19.16746,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.038070000000005,
                "longitude": 19.170139999999996,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 345
    },
    {
        "region": "Észak-Magyarország",
        "name": "NMSZC Mikszáth Kálmán Technikum és Szakképző Iskola Nógrádsápi Telephelye",
        "city": "Nógrádsáp",
        "address": "Hunyadi út 2",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.0220499,
                "longitude": 19.1500808,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015399405220300366,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.01818718242932,
                19.15778050261015,
                48.025912617570675,
                19.14238109738985
            ],
            "_northWest": {
                "latitude": 48.01818718242932,
                "longitude": 19.14238109738985,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.025912617570675,
                "longitude": 19.15778050261015,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 346
    },
    {
        "region": "Észak-Magyarország",
        "name": "NMSZC Mikszáth Kálmán Technikum és Szakképző Iskola Rétsági Telephelye",
        "city": "Rétság",
        "address": "Rákóczi út 32",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 47.928709999999995,
                "longitude": 19.137410000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.927809999999994,
                19.13875,
                47.92961,
                19.136070000000004
            ],
            "_northWest": {
                "latitude": 47.927809999999994,
                "longitude": 19.136070000000004,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.92961,
                "longitude": 19.13875,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 347
    },
    {
        "region": "Észak-Magyarország",
        "name": "NMSZC Mikszáth Kálmán Technikum és Szakképző Iskola, Civitas Fortissima Téri Telephelye",
        "city": "Balassagyarmat",
        "address": "Civitas Fortissima tér 2",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.07669,
                "longitude": 19.290264999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0018700000000002603,
            "height": -0.0007799999999988927,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.0763,
                19.291199999999996,
                48.077079999999995,
                19.28933
            ],
            "_northWest": {
                "latitude": 48.0763,
                "longitude": 19.28933,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.077079999999995,
                "longitude": 19.291199999999996,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 348
    },
    {
        "region": "Észak-Magyarország",
        "name": "Nógrád Megyei Szakképzési Centrum Szent-Györgyi Technikum",
        "city": "Balassagyarmat",
        "address": "Rákóczi fejedelem út 50.",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.08024,
                "longitude": 19.29776,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.07934,
                19.29911,
                48.081140000000005,
                19.29641
            ],
            "_northWest": {
                "latitude": 48.07934,
                "longitude": 19.29641,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.081140000000005,
                "longitude": 19.29911,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 349
    },
    {
        "region": "Észak-Magyarország",
        "name": "Nógrád Megyei SZC Borbély Lajos Technikum, Szakképző Iskola  és Kollégium ",
        "city": "Salgótarján",
        "address": "Csokonai út 29",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.0734,
                "longitude": 19.79101,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.0725,
                19.79236,
                48.0743,
                19.78966
            ],
            "_northWest": {
                "latitude": 48.0725,
                "longitude": 19.78966,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.0743,
                "longitude": 19.79236,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 350
    },
    {
        "region": "Észak-Magyarország",
        "name": "Nógrád Megyei SZC Fáy András Technikum, Szakképző Iskola és Kollégium Telephely (Pásztó)",
        "city": "Pásztó",
        "address": "Rákóczi út 5",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 47.94713,
                "longitude": 19.73697,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.94623,
                19.73831,
                47.94803,
                19.73563
            ],
            "_northWest": {
                "latitude": 47.94623,
                "longitude": 19.73563,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.94803,
                "longitude": 19.73831,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 351
    },
    {
        "region": "Észak-Magyarország",
        "name": "Nógrád Megyei SZC Kereskedelmi és Vendéglátóipari Technikum és Szakképző Iskola",
        "city": "Salgótarján",
        "address": "Zemlinszky Rezső út 4.",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.1013192,
                "longitude": 19.8007083,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015423133427276525,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.09745648242932,
                19.80841986671364,
                48.105181917570675,
                19.792996733286362
            ],
            "_northWest": {
                "latitude": 48.09745648242932,
                "longitude": 19.792996733286362,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.105181917570675,
                "longitude": 19.80841986671364,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 352
    },
    {
        "region": "Észak-Magyarország",
        "name": "Nógrád Megyei SZC Stromfeld Aurél Technikum",
        "city": "Salgótarján",
        "address": "Rákóczi út 60.",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.094629999999995,
                "longitude": 19.801029999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.093729999999994,
                19.80238,
                48.09553,
                19.799679999999995
            ],
            "_northWest": {
                "latitude": 48.093729999999994,
                "longitude": 19.799679999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.09553,
                "longitude": 19.80238,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 353
    },
    {
        "region": "Észak-Magyarország",
        "name": "Nógrád Megyei SZC Szondi György Technikum és Szakképző Iskola Székhely",
        "city": "Balassagyarmat",
        "address": "Régimalom út 2",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.074889999999996,
                "longitude": 19.285400000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.073989999999995,
                19.286750000000005,
                48.07579,
                19.28405
            ],
            "_northWest": {
                "latitude": 48.073989999999995,
                "longitude": 19.28405,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.07579,
                "longitude": 19.286750000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 354
    },
    {
        "region": "Észak-Magyarország",
        "name": "Nógrád Megyei SZC Táncsics Mihály Technikum Székhely",
        "city": "Salgótarján",
        "address": "Május 1. út 58.",
        "county": "Nógrád",
        "bestView": {
            "center": {
                "latitude": 48.107735,
                "longitude": 19.80787,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00013999999999825263,
            "height": -0.0001299999999986312,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.10767,
                19.807940000000002,
                48.1078,
                19.8078
            ],
            "_northWest": {
                "latitude": 48.10767,
                "longitude": 19.8078,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.1078,
                "longitude": 19.807940000000002,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 355
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Bánki Donát Műszaki Technikum és Kollégium Székhely",
        "city": "Nyíregyháza",
        "address": "Korányi Frigyes utca 15",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.1128564,
                "longitude": 17.5636953,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015134427438120213,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.10899368242932,
                17.57126251371906,
                47.116719117570675,
                17.55612808628094
            ],
            "_northWest": {
                "latitude": 47.10899368242932,
                "longitude": 17.55612808628094,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.116719117570675,
                "longitude": 17.57126251371906,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 356
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Bencs László Szakképző Iskola Székhely",
        "city": "Nyíregyháza",
        "address": "Tiszavasvári út 12.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.956770000000006,
                "longitude": 21.66354,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.026859999999999218,
            "height": -0.01798000000000144,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.94778000000001,
                21.67697,
                47.96576,
                21.65011
            ],
            "_northWest": {
                "latitude": 47.94778000000001,
                "longitude": 21.65011,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.96576,
                "longitude": 21.67697,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 357
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Inczédy György Szakképző Iskola és Kollégium Székhely",
        "city": "Nyíregyháza",
        "address": "Árok utca 53.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.962955,
                "longitude": 21.702955,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0004899999999992133,
            "height": -0.00019000000000346517,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.96286,
                21.7032,
                47.96305,
                21.70271
            ],
            "_northWest": {
                "latitude": 47.96286,
                "longitude": 21.70271,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.96305,
                "longitude": 21.7032,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 358
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Sipkay Barna Technikum Székhely",
        "city": "Nyíregyháza",
        "address": "Krúdy Gyula utca 32",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.96767,
                "longitude": 21.72171,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0012599999999984846,
            "height": -0.00003999999999848569,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.96765,
                21.722340000000003,
                47.96769,
                21.72108
            ],
            "_northWest": {
                "latitude": 47.96765,
                "longitude": 21.72108,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.96769,
                "longitude": 21.722340000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 359
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Széchenyi István Technikum és Kollégium Árok Utcai Telephelye",
        "city": "Nyíregyháza",
        "address": "Árok utca 48.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.96241,
                "longitude": 21.703989999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.96151,
                21.705329999999996,
                47.96331,
                21.70265
            ],
            "_northWest": {
                "latitude": 47.96151,
                "longitude": 21.70265,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.96331,
                "longitude": 21.705329999999996,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 360
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Széchenyi István Technikum és Kollégium Székhely",
        "city": "Nyíregyháza",
        "address": "Városmajor utca 4.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.9655,
                "longitude": 21.70539,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.9646,
                21.70673,
                47.9664,
                21.704050000000002
            ],
            "_northWest": {
                "latitude": 47.9646,
                "longitude": 21.704050000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.9664,
                "longitude": 21.70673,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 361
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Teleki Blanka Szakképző Iskola és Kollégium Bocskai Utcai Telephelye",
        "city": "Tiszalök",
        "address": "Bocskai utca 17.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 48.014070000000004,
                "longitude": 21.378410000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.01317,
                21.37975,
                48.014970000000005,
                21.377070000000003
            ],
            "_northWest": {
                "latitude": 48.01317,
                "longitude": 21.377070000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.014970000000005,
                "longitude": 21.37975,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 362
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Teleki Blanka Szakképző Iskola és Kollégium Székhely ",
        "city": "Tiszalök",
        "address": "Ady Endre utca 35.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 48.01909,
                "longitude": 21.36675,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.01819,
                21.36809,
                48.01999,
                21.36541
            ],
            "_northWest": {
                "latitude": 48.01819,
                "longitude": 21.36541,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.01999,
                "longitude": 21.36809,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 363
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Tiszavasvári Szakképző Iskola és Kollégium Székhely ",
        "city": "Tiszavasvári",
        "address": "Petőfi Sándor utca 1.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.951320457241216,
                "longitude": 21.36911342228782,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015378319671484064,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.94745773967054,
                21.376802582123563,
                47.95518317481189,
                21.36142426245208
            ],
            "_northWest": {
                "latitude": 47.94745773967054,
                "longitude": 21.36142426245208,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.95518317481189,
                "longitude": 21.376802582123563,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 364
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Vásárhelyi Pál Technikum Székhely",
        "city": "Nyíregyháza",
        "address": "Vasvári Pál utca 16",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.9611,
                "longitude": 21.71177,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.9602,
                21.71311,
                47.962,
                21.710430000000002
            ],
            "_northWest": {
                "latitude": 47.9602,
                "longitude": 21.710430000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.962,
                "longitude": 21.71311,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 365
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Wesselényi Miklós Technikum és Kollégium Hengersor Telephelye ",
        "city": "Nyíregyháza",
        "address": "Hengersor sor 10",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.96583,
                "longitude": 21.73018,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.964929999999995,
                21.73152,
                47.96673,
                21.72884
            ],
            "_northWest": {
                "latitude": 47.964929999999995,
                "longitude": 21.72884,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.96673,
                "longitude": 21.73152,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 366
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Wesselényi Miklós Technikum és Kollégium Székhely",
        "city": "Nyíregyháza",
        "address": "Dugonics  utca 10-12",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.94966,
                "longitude": 21.695819999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002140000000000697,
            "height": -0.0005799999999993588,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.94937,
                21.696889999999996,
                47.94995,
                21.69475
            ],
            "_northWest": {
                "latitude": 47.94937,
                "longitude": 21.69475,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.94995,
                "longitude": 21.696889999999996,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 367
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Wesselényi Miklós Technikum és Kollégium Vay Ádám Utcai Telephelye ",
        "city": "Nyíregyháza",
        "address": "Vay Ádám körút 4-6",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.959270000000004,
                "longitude": 21.71566,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.95837,
                21.717,
                47.960170000000005,
                21.71432
            ],
            "_northWest": {
                "latitude": 47.95837,
                "longitude": 21.71432,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.960170000000005,
                "longitude": 21.717,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 368
    },
    {
        "region": "Észak-Alföld",
        "name": "Nyíregyházi SZC Zay Anna Technikum és Kollégium Székhely",
        "city": "Nyíregyháza",
        "address": "Család út 11.",
        "county": "Szabolcs-Szatmár-Bereg",
        "bestView": {
            "center": {
                "latitude": 47.9530684,
                "longitude": 21.7355086,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01537883978225807,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.94920568242932,
                21.74319801989113,
                47.956931117570676,
                21.72781918010887
            ],
            "_northWest": {
                "latitude": 47.94920568242932,
                "longitude": 21.72781918010887,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.956931117570676,
                "longitude": 21.74319801989113,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 369
    },
    {
        "region": "Dél-Alföld",
        "name": "Öreglaktanya",
        "city": "Kiskunfélegyháza",
        "address": "Kossuth utca 35.",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.70797,
                "longitude": 19.83654,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.70707,
                19.83785,
                46.708870000000005,
                19.83523
            ],
            "_northWest": {
                "latitude": 46.70707,
                "longitude": 19.83523,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.708870000000005,
                "longitude": 19.83785,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 370
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Bródy Imre Technikum Székhely",
        "city": "Ózd",
        "address": "Petőfi Sándor út 20.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.22466,
                "longitude": 20.28933,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.22376,
                20.29068,
                48.22556,
                20.28798
            ],
            "_northWest": {
                "latitude": 48.22376,
                "longitude": 20.28798,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.22556,
                "longitude": 20.29068,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 371
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Deák Ferenc Technikum és Szakképző Iskola Székhely",
        "city": "Kazincbarcika",
        "address": "Herbolyai út 9",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.23982,
                "longitude": 20.615319999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.23892,
                20.61667,
                48.24072,
                20.613969999999995
            ],
            "_northWest": {
                "latitude": 48.23892,
                "longitude": 20.613969999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.24072,
                "longitude": 20.61667,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 372
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Gábor Áron Technikum és Szakképző Iskola Székhely",
        "city": "Ózd",
        "address": "Bolyki főút 2",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.22507,
                "longitude": 20.26059,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.22417,
                20.261940000000003,
                48.225970000000004,
                20.25924
            ],
            "_northWest": {
                "latitude": 48.22417,
                "longitude": 20.25924,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.225970000000004,
                "longitude": 20.261940000000003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 373
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Gábor Áron Technikum és Szakképző Iskola Telephely",
        "city": "Ózd",
        "address": "Árpád vezér út 13",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.220749999999995,
                "longitude": 20.266910000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.219849999999994,
                20.268260000000005,
                48.22165,
                20.26556
            ],
            "_northWest": {
                "latitude": 48.219849999999994,
                "longitude": 20.26556,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.22165,
                "longitude": 20.268260000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 374
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Pattantyús-Ábrahám Géza Szakképző Iskola  Székhely",
        "city": "Sajószentpéter",
        "address": "Kálvin tér 2",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.21763,
                "longitude": 20.722990000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.21673,
                20.724340000000005,
                48.21853,
                20.72164
            ],
            "_northWest": {
                "latitude": 48.21673,
                "longitude": 20.72164,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.21853,
                "longitude": 20.724340000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 375
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Pattantyús-Ábrahám Géza Szakképző Iskola Gagarin utcai tanműhely",
        "city": "Sajószentpéter",
        "address": "Gagarin  utca 3",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.2128,
                "longitude": 20.70289,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.2119,
                20.70424,
                48.2137,
                20.70154
            ],
            "_northWest": {
                "latitude": 48.2119,
                "longitude": 20.70154,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.2137,
                "longitude": 20.70424,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 376
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Pattantyús-Ábrahám Géza Szakképző Iskola Kandó Kálmán utcai tanműhely",
        "city": "Sajószentpéter",
        "address": "Kandó Kálmán  utca 4",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.21467,
                "longitude": 20.70055,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0028199999999998226,
            "height": -0.0016000000000033765,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.21387,
                20.70196,
                48.215469999999996,
                20.69914
            ],
            "_northWest": {
                "latitude": 48.21387,
                "longitude": 20.69914,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.215469999999996,
                "longitude": 20.70196,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 377
    },
    {
        "region": "Észak-Magyarország",
        "name": "Ózdi SZC Surányi Endre Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Kazincbarcika",
        "address": "Irinyi János utca 1",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.25031,
                "longitude": 20.64463,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.24941,
                20.64598,
                48.25121,
                20.643279999999997
            ],
            "_northWest": {
                "latitude": 48.24941,
                "longitude": 20.643279999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.25121,
                "longitude": 20.64598,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 378
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Acsády Ignác  Tecnikum és Szakképző Iskola Székhely",
        "city": "Pápa",
        "address": "Erkel Ferenc  utca 39.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.32351,
                "longitude": 17.45988,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.32261,
                17.46121,
                47.32441,
                17.458549999999995
            ],
            "_northWest": {
                "latitude": 47.32261,
                "longitude": 17.458549999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.32441,
                "longitude": 17.46121,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 379
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SzC Acsády Ignác Technikum és  Szakképző Iskola Sümegi Telephely",
        "city": "Sümeg",
        "address": "Kossuth Lajos utca 2",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 46.9777,
                "longitude": 17.2815,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.9768,
                17.28282,
                46.9786,
                17.28018
            ],
            "_northWest": {
                "latitude": 46.9768,
                "longitude": 17.28018,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.9786,
                "longitude": 17.28282,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 380
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Egry József Szakgimnáziuma, Szakközépiskolája és Kollégiuma Székhely",
        "city": "Zánka",
        "address": "",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 46.8676815032959,
                "longitude": 17.684276580810547,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.09654617309570312,
            "height": -0.054561614990234375,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.84040069580078,
                17.7325496673584,
                46.894962310791016,
                17.636003494262695
            ],
            "_northWest": {
                "latitude": 46.84040069580078,
                "longitude": 17.636003494262695,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.894962310791016,
                "longitude": 17.7325496673584,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 381
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Faller Jenő Technikum, Szakképző Iskola és Kollégium",
        "city": "Várpalota ",
        "address": "Szent István út 1",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.20139,
                "longitude": 18.15577,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.20049,
                18.15709,
                47.202290000000005,
                18.15445
            ],
            "_northWest": {
                "latitude": 47.20049,
                "longitude": 18.15445,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.202290000000005,
                "longitude": 18.15709,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 382
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Faller Jenő Technikum, Szakképző Iskola és Kollégium Bartók Béla Úti Telephelye",
        "city": "Várpalota",
        "address": "Bartók Béla út 4",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.04846,
                "longitude": 18.007179999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.04756,
                18.008499999999998,
                47.04936,
                18.00586
            ],
            "_northWest": {
                "latitude": 47.04756,
                "longitude": 18.00586,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.04936,
                "longitude": 18.008499999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 383
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Faller Jenő Technikum, Szakképző Iskola és Kollégium Bögre Zártkert Telephelye",
        "city": "Várpalota",
        "address": "Bögre Zártkert dűlő",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.212869999999995,
                "longitude": 18.22388,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.001020000000000465,
            "height": -0.00019999999999953388,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.21276999999999,
                18.22439,
                47.21297,
                18.223370000000003
            ],
            "_northWest": {
                "latitude": 47.21276999999999,
                "longitude": 18.223370000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.21297,
                "longitude": 18.22439,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 384
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Faller Jenő Technikum, Szakképző Iskola és Kollégium Fehérvári Úti Telephelye",
        "city": "Várpalota",
        "address": "Fehérvári  út 39",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.20004,
                "longitude": 18.16387,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.19914,
                18.16519,
                47.20094,
                18.16255
            ],
            "_northWest": {
                "latitude": 47.19914,
                "longitude": 18.16255,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.20094,
                "longitude": 18.16519,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 385
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Jókai Mór Közgazdasági Technikum és Kollégium Székhely",
        "city": "Pápa",
        "address": "Veszprémi út 45",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.318889999999996,
                "longitude": 17.48032,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.317989999999995,
                17.48165,
                47.31979,
                17.47899
            ],
            "_northWest": {
                "latitude": 47.317989999999995,
                "longitude": 17.47899,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.31979,
                "longitude": 17.48165,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 386
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Pápai SZC Reguly Antal Szakképző Iskola és Kollégium ",
        "city": "Zirc",
        "address": "Alkotmány utca 16.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.26894,
                "longitude": 17.8726,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.26804,
                17.87393,
                47.26984,
                17.871269999999996
            ],
            "_northWest": {
                "latitude": 47.26804,
                "longitude": 17.871269999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.26984,
                "longitude": 17.87393,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 387
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Sárvár székhely",
        "city": "Sárvár",
        "address": "Móricz Zsigmond  utca 2",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.25512,
                "longitude": 16.93884,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.25422,
                16.94016,
                47.25602,
                16.93752
            ],
            "_northWest": {
                "latitude": 47.25422,
                "longitude": 16.93752,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.25602,
                "longitude": 16.94016,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 388
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Sárvár székhely",
        "city": "Sárvár",
        "address": "Móricz Zsigmond utca 2.",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.25512,
                "longitude": 16.93884,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.25422,
                16.94016,
                47.25602,
                16.93752
            ],
            "_northWest": {
                "latitude": 47.25422,
                "longitude": 16.93752,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.25602,
                "longitude": 16.94016,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 389
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Siófoki SZC Bacsák György Technikum és Szakképző Iskola ",
        "city": "Fonyód",
        "address": "Béke utca 1.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.748419999999996,
                "longitude": 17.56068,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.747519999999994,
                17.56199,
                46.74932,
                17.55937
            ],
            "_northWest": {
                "latitude": 46.747519999999994,
                "longitude": 17.55937,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.74932,
                "longitude": 17.56199,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 390
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Siófoki SZC Baross Gábor Technikum és szakképző Iskola",
        "city": "Siófok",
        "address": "Bakony utca 2.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.9035,
                "longitude": 18.02779,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.9026,
                18.02911,
                46.9044,
                18.02647
            ],
            "_northWest": {
                "latitude": 46.9026,
                "longitude": 18.02647,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.9044,
                "longitude": 18.02911,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 391
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Siófoki SZC Krúdy Gyula Technikum és Gimnázium Székhely",
        "city": "Siófok",
        "address": "Koch Róbert utca 8",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.897845000000004,
                "longitude": 18.042305,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.004290000000001015,
            "height": -0.004470000000004859,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.895610000000005,
                18.044449999999998,
                46.90008,
                18.04016
            ],
            "_northWest": {
                "latitude": 46.895610000000005,
                "longitude": 18.04016,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.90008,
                "longitude": 18.044449999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 392
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Siófoki SZC Marcali Szakképző Iskola",
        "city": "Marcali",
        "address": "Hősök  tere 3.",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.57781,
                "longitude": 17.41435,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.57691,
                17.41566,
                46.57871,
                17.41304
            ],
            "_northWest": {
                "latitude": 46.57691,
                "longitude": 17.41304,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.57871,
                "longitude": 17.41566,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 393
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Siófoki SZC Mathiász János  Technikum és Gimnázium",
        "city": "Balatonboglár",
        "address": "Szabadság utca 41",
        "county": "Somogy",
        "bestView": {
            "center": {
                "latitude": 46.77481,
                "longitude": 17.65833,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.77391,
                17.65964,
                46.775710000000004,
                17.65702
            ],
            "_northWest": {
                "latitude": 46.77391,
                "longitude": 17.65702,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.775710000000004,
                "longitude": 17.65964,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 394
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Berg Gusztáv Szakközépző Iskola Székhely",
        "city": "Kapuvár",
        "address": "Berg Gusztáv  utca 2.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.59262,
                "longitude": 17.0345,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.591719999999995,
                17.035830000000004,
                47.59352,
                17.03317
            ],
            "_northWest": {
                "latitude": 47.591719999999995,
                "longitude": 17.03317,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.59352,
                "longitude": 17.035830000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 395
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SzC Berg Gusztáv Szakközépző Iskola Tanműhely",
        "city": "Kapuvár",
        "address": "Kossuth Lajos  utca 12.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.590630000000004,
                "longitude": 17.02798,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.58973,
                17.02931,
                47.591530000000006,
                17.02665
            ],
            "_northWest": {
                "latitude": 47.58973,
                "longitude": 17.02665,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.591530000000006,
                "longitude": 17.02931,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 396
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Fáy András Két Tanítási Nyelvű Közgazdasági Technikum Székhely",
        "city": "Sopron",
        "address": "Teleki Pál út 26.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.691355,
                "longitude": 16.57913,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.004560000000001452,
            "height": -0.00274999999999892,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.689980000000006,
                16.581409999999998,
                47.69273,
                16.57685
            ],
            "_northWest": {
                "latitude": 47.689980000000006,
                "longitude": 16.57685,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.69273,
                "longitude": 16.581409999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 397
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Handler Nándor Technikum",
        "city": "Sopron",
        "address": "Halász utca 9-15.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.68742,
                "longitude": 16.59554,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.68652,
                16.59688,
                47.688320000000004,
                16.5942
            ],
            "_northWest": {
                "latitude": 47.68652,
                "longitude": 16.5942,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.688320000000004,
                "longitude": 16.59688,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 398
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SzC Handler Nándor Technikum Tanműhely",
        "city": "Sopron",
        "address": "Hőközpont utca 1.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.67577,
                "longitude": 16.594839999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.67487,
                16.596179999999997,
                47.67667,
                16.5935
            ],
            "_northWest": {
                "latitude": 47.67487,
                "longitude": 16.5935,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.67667,
                "longitude": 16.596179999999997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 399
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Hunyadi János Technikum Székhely",
        "city": "Csorna",
        "address": "Soproni út 97.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.61139,
                "longitude": 17.24417,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.61049,
                17.2455,
                47.61229,
                17.24284
            ],
            "_northWest": {
                "latitude": 47.61049,
                "longitude": 17.24284,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.61229,
                "longitude": 17.2455,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 400
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Kossuth Lajos Szakképző Iskola",
        "city": "Csorna",
        "address": "Kossuth Lajos  utca 30.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.609300000000005,
                "longitude": 17.25283,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.6084,
                17.25416,
                47.610200000000006,
                17.2515
            ],
            "_northWest": {
                "latitude": 47.6084,
                "longitude": 17.2515,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.610200000000006,
                "longitude": 17.25416,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 401
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Porpáczy Aladár Technikum és Kollégium Madách Sétányi Székhelye",
        "city": "Fertőd",
        "address": "Madách sétány 2/D",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.61956,
                "longitude": 16.866210000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.61866,
                16.867540000000005,
                47.62046,
                16.86488
            ],
            "_northWest": {
                "latitude": 47.61866,
                "longitude": 16.86488,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.62046,
                "longitude": 16.867540000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 402
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SzC Vas- és Villamosipari Technikum Mechatronika",
        "city": "Sopron",
        "address": "Dózsa György utca 27.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.62407,
                "longitude": 16.88775,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.62317,
                16.88908,
                47.624970000000005,
                16.88642
            ],
            "_northWest": {
                "latitude": 47.62317,
                "longitude": 16.88642,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.624970000000005,
                "longitude": 16.88908,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 403
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Vas- és Villamosipari Technikum Székhely",
        "city": "Sopron",
        "address": "Ferenczy János utca 7.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.688069999999996,
                "longitude": 16.58459,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.687169999999995,
                16.585929999999998,
                47.68897,
                16.58325
            ],
            "_northWest": {
                "latitude": 47.687169999999995,
                "longitude": 16.58325,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.68897,
                "longitude": 16.585929999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 404
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SzC Vas- és Villamosipari Technikum Tanműhely",
        "city": "Sopron",
        "address": "Ferenczy János utca 60.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.689769999999996,
                "longitude": 16.58699,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.688869999999994,
                16.58833,
                47.69067,
                16.58565
            ],
            "_northWest": {
                "latitude": 47.688869999999994,
                "longitude": 16.58565,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.69067,
                "longitude": 16.58833,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 405
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Vendéglátó, Kereskedelmi Technikum és Kollégium (Kollégium)",
        "city": "Sopron",
        "address": "Ferenczy J. utca 60",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.689769999999996,
                "longitude": 16.58699,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.688869999999994,
                16.58833,
                47.69067,
                16.58565
            ],
            "_northWest": {
                "latitude": 47.688869999999994,
                "longitude": 16.58565,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.69067,
                "longitude": 16.58833,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 406
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Soproni SZC Vendéglátó, Kereskedelmi Technikum és Kollégium Székhely",
        "city": "Sopron",
        "address": "Ferenczy J. utca 1-3.",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.68611,
                "longitude": 16.582349999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.68521,
                16.583689999999997,
                47.68701,
                16.58101
            ],
            "_northWest": {
                "latitude": 47.68521,
                "longitude": 16.58101,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.68701,
                "longitude": 16.583689999999997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 407
    },
    {
        "region": "Dél-Alföld",
        "name": "Szakiskola",
        "city": "Kiskunfélegyháza",
        "address": "Kun utca 2",
        "county": "Bács-Kiskun",
        "bestView": {
            "center": {
                "latitude": 46.71259,
                "longitude": 19.85654,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.71169,
                19.85785,
                46.71349,
                19.85523
            ],
            "_northWest": {
                "latitude": 46.71169,
                "longitude": 19.85523,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.71349,
                "longitude": 19.85785,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 408
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Csonka János Technikum Székhely",
        "city": "Szeged",
        "address": "Temesvári körút 38.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.24949,
                "longitude": 20.16706,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.24859,
                20.16836,
                46.25039,
                20.16576
            ],
            "_northWest": {
                "latitude": 46.24859,
                "longitude": 20.16576,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25039,
                "longitude": 20.16836,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 409
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Csonka János Technikum Telephely",
        "city": "Szeged",
        "address": "Közép fasor 23-25.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.248940000000005,
                "longitude": 20.16894,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.24804,
                20.17024,
                46.249840000000006,
                20.16764
            ],
            "_northWest": {
                "latitude": 46.24804,
                "longitude": 20.16764,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.249840000000006,
                "longitude": 20.17024,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 410
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Déri Miksa Műszaki Technikum Székhely",
        "city": "Szeged",
        "address": "Kálvária tér 7",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.253465000000006,
                "longitude": 20.133295,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0006699999999995043,
            "height": -0.00010999999999938836,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.25341,
                20.13363,
                46.25352000000001,
                20.13296
            ],
            "_northWest": {
                "latitude": 46.25341,
                "longitude": 20.13296,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25352000000001,
                "longitude": 20.13363,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 411
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Gábor Dénes Technikum és Szakgimnázium Székhely",
        "city": "Szeged",
        "address": "Mars tér 14.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.25611,
                "longitude": 20.13867,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.25521,
                20.13997,
                46.25701,
                20.13737
            ],
            "_northWest": {
                "latitude": 46.25521,
                "longitude": 20.13737,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25701,
                "longitude": 20.13997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 412
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Gábor Dénes Technikum és SzakgimnáziumTanműhely",
        "city": "Szeged",
        "address": "Budai Nagy Antal utca 134.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.258759999999995,
                "longitude": 20.18463,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.257859999999994,
                20.18593,
                46.25966,
                20.183329999999998
            ],
            "_northWest": {
                "latitude": 46.257859999999994,
                "longitude": 20.183329999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25966,
                "longitude": 20.18593,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 413
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC József Attila Általános Iskola és Szakképző Iskola Balatoni Utcai Telephelye",
        "city": "Szeged",
        "address": "Balatoni utca 11.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.28132,
                "longitude": 20.17276,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.28042,
                20.17406,
                46.28222,
                20.17146
            ],
            "_northWest": {
                "latitude": 46.28042,
                "longitude": 20.17146,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.28222,
                "longitude": 20.17406,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 414
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC József Attila Általános Iskola és Szakképző Iskola Székhely",
        "city": "Szeged",
        "address": "Gyík utca 3.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.266175000000004,
                "longitude": 20.17107,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0015000000000000568,
            "height": -0.0021300000000010755,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.26511000000001,
                20.17182,
                46.26724,
                20.17032
            ],
            "_northWest": {
                "latitude": 46.26511000000001,
                "longitude": 20.17032,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.26724,
                "longitude": 20.17182,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 415
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Kőrösy József Közgazdasági Technikum Székhely",
        "city": "Szeged",
        "address": "Stefánia Na 14.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.2554601,
                "longitude": 20.1551453,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0148961471141007,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.251597382429324,
                20.16259337355705,
                46.25932281757068,
                20.14769722644295
            ],
            "_northWest": {
                "latitude": 46.251597382429324,
                "longitude": 20.14769722644295,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25932281757068,
                "longitude": 20.16259337355705,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 416
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Krúdy Gyula Szakképző Iskola Maros utcai Telephelye",
        "city": "Szeged",
        "address": "Maros utca 42-44.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.258160000000004,
                "longitude": 20.162219999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.25726,
                20.16352,
                46.259060000000005,
                20.160919999999997
            ],
            "_northWest": {
                "latitude": 46.25726,
                "longitude": 20.160919999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.259060000000005,
                "longitude": 20.16352,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 417
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Krúdy Gyula Szakképző Iskola Székhely",
        "city": "Szeged",
        "address": "József Attila  sugárút 120.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.27068,
                "longitude": 20.157559999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00009999999999976694,
            "height": -0.00021999999999877673,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.27057,
                20.15761,
                46.27079,
                20.157509999999995
            ],
            "_northWest": {
                "latitude": 46.27057,
                "longitude": 20.157509999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.27079,
                "longitude": 20.15761,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 418
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Móravárosi Szakképző Iskola Székhely",
        "city": "Szeged",
        "address": "Kálvária sugárút 84-86.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.25324,
                "longitude": 20.12752,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.25234,
                20.12882,
                46.25414,
                20.12622
            ],
            "_northWest": {
                "latitude": 46.25234,
                "longitude": 20.12622,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25414,
                "longitude": 20.12882,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 419
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Tóth János Mórahalmi Szakképző Iskola és Garabonciás Kollégium",
        "city": "Mórahalom",
        "address": "Dosztig köz 3.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.21901,
                "longitude": 19.88886,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.001200000000000756,
            "height": -0.00026000000000436785,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.21888,
                19.88946,
                46.219139999999996,
                19.888260000000002
            ],
            "_northWest": {
                "latitude": 46.21888,
                "longitude": 19.888260000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.219139999999996,
                "longitude": 19.88946,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 420
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Tóth János Mórahalmi Szakképző Iskola és Garabonciás Kollégium k.",
        "city": "Mórahalom",
        "address": "Kölcsey  utca 1/B.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.21506,
                "longitude": 19.89623,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.21416,
                19.89753,
                46.21596,
                19.89493
            ],
            "_northWest": {
                "latitude": 46.21416,
                "longitude": 19.89493,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.21596,
                "longitude": 19.89753,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 421
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Tóth János Mórahalmi Szakképző Iskola és Garabonciás Kollégium Röszkei úti Kollégium",
        "city": "Mórahalom",
        "address": "Röszkei  út 47.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.21096,
                "longitude": 19.89047,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.21006,
                19.89177,
                46.21186,
                19.88917
            ],
            "_northWest": {
                "latitude": 46.21006,
                "longitude": 19.88917,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.21186,
                "longitude": 19.89177,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 422
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Vasvári Pál Gazdasági és Informatikai Technikum Székhely",
        "city": "Szeged",
        "address": "Gutenberg utca 11.",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.25334,
                "longitude": 20.14316,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002599999999997493,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.25244,
                20.144460000000002,
                46.25424,
                20.14186
            ],
            "_northWest": {
                "latitude": 46.25244,
                "longitude": 20.14186,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25424,
                "longitude": 20.144460000000002,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 423
    },
    {
        "region": "Dél-Alföld",
        "name": "Szegedi SZC Vedres István Technikum Székhely",
        "city": "Szeged",
        "address": "Horváth Mihály utca 2-6",
        "county": "Csongrád",
        "bestView": {
            "center": {
                "latitude": 46.25506,
                "longitude": 20.15203,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.25416,
                20.15333,
                46.25596,
                20.15073
            ],
            "_northWest": {
                "latitude": 46.25416,
                "longitude": 20.15073,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.25596,
                "longitude": 20.15333,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 424
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Árpád Technikum Szakképző Iskola és Kollégium  Székhely",
        "city": "Székesfehérvár",
        "address": "Seregélyesi út 88-90",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.18672,
                "longitude": 18.4571,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.18582,
                18.45842,
                47.18762,
                18.45578
            ],
            "_northWest": {
                "latitude": 47.18582,
                "longitude": 18.45578,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.18762,
                "longitude": 18.45842,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 425
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Bugát Pál Technikum",
        "city": "Székesfehérvár",
        "address": "Gyümölcs utca 15.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.18824,
                "longitude": 18.41783,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.18734,
                18.41915,
                47.18914,
                18.41651
            ],
            "_northWest": {
                "latitude": 47.18734,
                "longitude": 18.41651,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.18914,
                "longitude": 18.41915,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 426
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Deák Ferenc Technikum és Szakképző Iskola",
        "city": "Székesfehérvár",
        "address": "Károly János utca 32.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.18534,
                "longitude": 18.41871,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.184439999999995,
                18.42003,
                47.18624,
                18.41739
            ],
            "_northWest": {
                "latitude": 47.184439999999995,
                "longitude": 18.41739,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.18624,
                "longitude": 18.42003,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 427
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Hunyadi Mátyás Szakgimnáziuma",
        "city": "Székesfehérvár",
        "address": "Várkörút körút 35.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.1893693,
                "longitude": 18.4131532,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.01515622826902785,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.185506582429326,
                18.420731314134514,
                47.19323201757068,
                18.405575085865486
            ],
            "_northWest": {
                "latitude": 47.185506582429326,
                "longitude": 18.405575085865486,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.19323201757068,
                "longitude": 18.420731314134514,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 428
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC I. István Technikum Petőfi utcai telephely",
        "city": "Székesfehérvár",
        "address": "Petőfi utca 5.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.18991,
                "longitude": 18.41241,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.189009999999996,
                18.41373,
                47.19081,
                18.41109
            ],
            "_northWest": {
                "latitude": 47.189009999999996,
                "longitude": 18.41109,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.19081,
                "longitude": 18.41373,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 429
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC I. István Technikum Székhely",
        "city": "Székesfehérvár",
        "address": "Vár körút 31.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.192745,
                "longitude": 18.407275,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.004549999999998278,
            "height": -0.006009999999996296,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.18974,
                18.409549999999996,
                47.195750000000004,
                18.405
            ],
            "_northWest": {
                "latitude": 47.18974,
                "longitude": 18.405,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.195750000000004,
                "longitude": 18.409549999999996,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 430
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Jáky József Technikum",
        "city": "Székesfehérvár",
        "address": "Deák Ferenc utca 11.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.188559999999995,
                "longitude": 18.42005,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.187659999999994,
                18.42137,
                47.18946,
                18.41873
            ],
            "_northWest": {
                "latitude": 47.187659999999994,
                "longitude": 18.41873,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.18946,
                "longitude": 18.42137,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 431
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Perczel Mór Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Mór",
        "address": "Dózsa György  út 2.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.400233446337445,
                "longitude": 19.00473756563443,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015216777060182096,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.39637072876677,
                19.01234595416452,
                47.40409616390812,
                18.997129177104338
            ],
            "_northWest": {
                "latitude": 47.39637072876677,
                "longitude": 18.997129177104338,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.40409616390812,
                "longitude": 19.01234595416452,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 432
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Perczel Mór Technikum, Szakképző Iskola és Kollégium Tanműhely",
        "city": "Mór",
        "address": "Iskola utca 1.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.38373,
                "longitude": 18.220779999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.38283,
                18.22211,
                47.38463,
                18.219449999999995
            ],
            "_northWest": {
                "latitude": 47.38283,
                "longitude": 18.219449999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.38463,
                "longitude": 18.22211,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 433
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Széchenyi István Műszaki Technikum Székhely",
        "city": "Székesfehérvár",
        "address": "Budai út 45",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.19115,
                "longitude": 18.42817,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.19025,
                18.42949,
                47.19205,
                18.42685
            ],
            "_northWest": {
                "latitude": 47.19025,
                "longitude": 18.42685,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.19205,
                "longitude": 18.42949,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 434
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Váci Mihály Technikum, Szakképző Iskola és Kollégium",
        "city": "Székesfehérvár",
        "address": "Berényi út 105",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.206845,
                "longitude": 18.43996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0004999999999988347,
            "height": -0.00030999999999892225,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.20669,
                18.44021,
                47.207,
                18.439709999999998
            ],
            "_northWest": {
                "latitude": 47.20669,
                "longitude": 18.439709999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.207,
                "longitude": 18.44021,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 435
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Vajda János Technikum",
        "city": "Bicske",
        "address": "Kossuth tér 3",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.4917,
                "longitude": 18.63459,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.4908,
                18.63592,
                47.4926,
                18.63326
            ],
            "_northWest": {
                "latitude": 47.4908,
                "longitude": 18.63326,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.4926,
                "longitude": 18.63592,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 436
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Székesfehérvári SZC Vörösmarty Mihály Technikum és Szakképző Iskola Székhely",
        "city": "Székesfehérvár",
        "address": "Balatoni út 143.",
        "county": "Fejér",
        "bestView": {
            "center": {
                "latitude": 47.15915,
                "longitude": 18.40052,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.158249999999995,
                18.40184,
                47.16005,
                18.3992
            ],
            "_northWest": {
                "latitude": 47.158249999999995,
                "longitude": 18.3992,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.16005,
                "longitude": 18.40184,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 437
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Encsi Aba Sámuel Szakképző Iskola",
        "city": "Encs",
        "address": "Rákóczi Ferenc út 59.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 47.490809999999996,
                "longitude": 18.31738,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.489909999999995,
                18.31871,
                47.49171,
                18.31605
            ],
            "_northWest": {
                "latitude": 47.489909999999995,
                "longitude": 18.31605,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49171,
                "longitude": 18.31871,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 438
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Encsi Aba Sámuel Szakképző Iskola (telephely)",
        "city": "Encs",
        "address": "Szabadság utca 10.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.331649999999996,
                "longitude": 21.1282,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.330749999999995,
                21.12955,
                48.33255,
                21.12685
            ],
            "_northWest": {
                "latitude": 48.330749999999995,
                "longitude": 21.12685,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.33255,
                "longitude": 21.12955,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 439
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Műszaki és Szolgáltatási Technikum és Szakképző Iskola",
        "city": "Szerencs",
        "address": "Ondi út 8.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.16433,
                "longitude": 21.2066,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.16343,
                21.207950000000004,
                48.16523,
                21.20525
            ],
            "_northWest": {
                "latitude": 48.16343,
                "longitude": 21.20525,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.16523,
                "longitude": 21.207950000000004,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 440
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Műszaki és Szolgáltatási Technikum és Szakképző Iskola (Rákóczi úti telephely)",
        "city": "Szerencs",
        "address": "Rákóczi  út 93.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.162549999999996,
                "longitude": 21.204659999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002700000000000813,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.161649999999995,
                21.20601,
                48.16345,
                21.203309999999995
            ],
            "_northWest": {
                "latitude": 48.161649999999995,
                "longitude": 21.203309999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.16345,
                "longitude": 21.20601,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 441
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Sátoraljaújhelyi Kossuth Lajos Technikum, Szakképző Iskola és Gimnázium",
        "city": "Sátoraljaújhely",
        "address": "Deák utca 10",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.40084,
                "longitude": 21.65681,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.39994,
                21.65816,
                48.401740000000004,
                21.65546
            ],
            "_northWest": {
                "latitude": 48.39994,
                "longitude": 21.65546,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.401740000000004,
                "longitude": 21.65816,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 442
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Sátoraljaújhelyi Trefort Ágoston Szakképző Iskola",
        "city": "Sátoraljaújhely",
        "address": "Fejes István utca 14.",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.391645,
                "longitude": 21.653015,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.006289999999999907,
            "height": -0.0011299999999963006,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.39108,
                21.65616,
                48.39220999999999,
                21.64987
            ],
            "_northWest": {
                "latitude": 48.39108,
                "longitude": 21.64987,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.39220999999999,
                "longitude": 21.65616,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 443
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Tiszaújvárosi Brassai Sámuel Technikum és Szakképző Iskola",
        "city": "Tiszaújváros",
        "address": "Rózsa út 10",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 47.93183,
                "longitude": 21.04302,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.93093,
                21.044359999999998,
                47.93273,
                21.04168
            ],
            "_northWest": {
                "latitude": 47.93093,
                "longitude": 21.04168,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.93273,
                "longitude": 21.044359999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 444
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Tiszaújvárosi Brassai Sámuel Technikum és Szakképző Iskola (Munkácsy úti telephely)",
        "city": "Tiszaújváros",
        "address": "Munkácsy  út 13",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 47.93049,
                "longitude": 21.042540000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.92959,
                21.04388,
                47.93139,
                21.041200000000003
            ],
            "_northWest": {
                "latitude": 47.92959,
                "longitude": 21.041200000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.93139,
                "longitude": 21.04388,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 445
    },
    {
        "region": "Észak-Magyarország",
        "name": "Szerencsi SZC Tokaji Ferenc Technikum, Szakgimnázium és Gimnázium",
        "city": "Tokaj",
        "address": "Bajcsy-Zsilinszky Endre út 18-20",
        "county": "Borsod-Abaúj-Zemplén",
        "bestView": {
            "center": {
                "latitude": 48.11806,
                "longitude": 21.41179,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00269999999999726,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                48.11716,
                21.41314,
                48.11896,
                21.41044
            ],
            "_northWest": {
                "latitude": 48.11716,
                "longitude": 21.41044,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 48.11896,
                "longitude": 21.41314,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 446
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Baross Gábor Műszaki Technikum és Szakképző Iskola",
        "city": "Szolnok",
        "address": "Bán utca 9.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.17,
                "longitude": 20.17417,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.1691,
                20.17549,
                47.1709,
                20.17285
            ],
            "_northWest": {
                "latitude": 47.1691,
                "longitude": 20.17285,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.1709,
                "longitude": 20.17549,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 447
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Jendrassik György Gépipari Technikum",
        "city": "Szolnok",
        "address": "Baross utca 37.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.17751,
                "longitude": 20.18311,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17661,
                20.18443,
                47.17841,
                20.18179
            ],
            "_northWest": {
                "latitude": 47.17661,
                "longitude": 20.18179,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17841,
                "longitude": 20.18443,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 448
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Jendrassik György Gépipari Technikum DKA telephely",
        "city": "Szolnok",
        "address": "Baross utca 46",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.177080000000004,
                "longitude": 20.1838,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17618,
                20.18512,
                47.177980000000005,
                20.18248
            ],
            "_northWest": {
                "latitude": 47.17618,
                "longitude": 20.18248,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.177980000000005,
                "longitude": 20.18512,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 449
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Kereskedelmi és Vendéglátóipari Technikum és Szakképző Iskola",
        "city": "Szolnok",
        "address": "Károly Róbert utca 2",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.1786,
                "longitude": 20.180419999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.1777,
                20.181739999999998,
                47.179500000000004,
                20.1791
            ],
            "_northWest": {
                "latitude": 47.1777,
                "longitude": 20.1791,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.179500000000004,
                "longitude": 20.181739999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 450
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Klapka György Technikum és Szakképző Iskola Kossuth úti Telephelye",
        "city": "Jászberény",
        "address": "Kossuth út 59.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.491569999999996,
                "longitude": 19.89665,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.490669999999994,
                19.89798,
                47.49247,
                19.89532
            ],
            "_northWest": {
                "latitude": 47.490669999999994,
                "longitude": 19.89532,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.49247,
                "longitude": 19.89798,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 451
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Klapka György Technikum és Szakképző Iskola Székhely",
        "city": "Jászberény",
        "address": "Hatvani  út 2.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.50213,
                "longitude": 19.9092,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50123,
                19.91053,
                47.50303,
                19.907869999999996
            ],
            "_northWest": {
                "latitude": 47.50123,
                "longitude": 19.907869999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50303,
                "longitude": 19.91053,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 452
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Pálfy- Vízügyi Technikum Székhely",
        "city": "Szolnok",
        "address": "Tiszaparti   sétány 2-3",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.171009999999995,
                "longitude": 20.19253,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.170109999999994,
                20.19385,
                47.17191,
                20.19121
            ],
            "_northWest": {
                "latitude": 47.170109999999994,
                "longitude": 20.19121,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17191,
                "longitude": 20.19385,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 453
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Pálfy-Vízügyi Technikum  Tószegi úti Telephelye",
        "city": "Szolnok",
        "address": "Tószegi  út 24",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.141819999999996,
                "longitude": 20.15861,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.140919999999994,
                20.15993,
                47.14272,
                20.15729
            ],
            "_northWest": {
                "latitude": 47.140919999999994,
                "longitude": 20.15729,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.14272,
                "longitude": 20.15993,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 454
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Pálfy-Vízügyi Technikum Tiszaligeti Telephelye",
        "city": "Szolnok",
        "address": "Tiszaligeti  sétány",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.167785,
                "longitude": 20.19398,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.02646000000000015,
            "height": -0.017989999999997508,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.15879,
                20.20721,
                47.17678,
                20.18075
            ],
            "_northWest": {
                "latitude": 47.15879,
                "longitude": 20.18075,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17678,
                "longitude": 20.20721,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 455
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SzC Petőfi Sándor Építészeti és Faipari  Technikum és Szakképző Iskola Székhely",
        "city": "Szolnok",
        "address": "Petőfi utca 1",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.17595,
                "longitude": 20.18838,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17505,
                20.1897,
                47.17685,
                20.18706
            ],
            "_northWest": {
                "latitude": 47.17505,
                "longitude": 20.18706,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17685,
                "longitude": 20.1897,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 456
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SzC Petőfi Sándor Építészeti és Faipari Technikum és Szakképző Iskola Sárkány utcai tanműhely",
        "city": "Szolnok",
        "address": "Sárkány utca 6",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.19246,
                "longitude": 20.17956,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.191559999999996,
                20.18088,
                47.19336,
                20.17824
            ],
            "_northWest": {
                "latitude": 47.191559999999996,
                "longitude": 20.17824,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.19336,
                "longitude": 20.18088,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 457
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Rózsa Imre Technikum",
        "city": "Újszász",
        "address": "Dózsa György út 23.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.28951,
                "longitude": 20.06878,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.28861,
                20.07011,
                47.29041,
                20.06745
            ],
            "_northWest": {
                "latitude": 47.28861,
                "longitude": 20.06745,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.29041,
                "longitude": 20.07011,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 458
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Sipos Orbán Szakképző Iskola és Kollégium Székhely",
        "city": "Szolnok ",
        "address": "Gyermekváros utca 1.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.194320000000005,
                "longitude": 20.191110000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.19342,
                20.19243,
                47.195220000000006,
                20.189790000000002
            ],
            "_northWest": {
                "latitude": 47.19342,
                "longitude": 20.189790000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.195220000000006,
                "longitude": 20.19243,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 459
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Vásárhelyi Pál Két Tanítási Nyelvű Technikum (Székhely)",
        "city": "Szolnok",
        "address": "Baross utca 43",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.17796,
                "longitude": 20.18064,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17706,
                20.18196,
                47.17886,
                20.17932
            ],
            "_northWest": {
                "latitude": 47.17706,
                "longitude": 20.17932,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17886,
                "longitude": 20.18196,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 460
    },
    {
        "region": "Észak-Alföld",
        "name": "Szolnoki SZC Vásárhelyi Pál Két Tanítási Nyelvű Technikum (Telephely)",
        "city": "Szolnok",
        "address": "Béla király utca 4.",
        "county": "Jász-Nagykun-Szolnok",
        "bestView": {
            "center": {
                "latitude": 47.17844,
                "longitude": 20.18112,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.17754,
                20.18244,
                47.17934,
                20.1798
            ],
            "_northWest": {
                "latitude": 47.17754,
                "longitude": 20.1798,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.17934,
                "longitude": 20.18244,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 461
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Szombathelyi Műszaki SZC Gépipari és Informatikai  Technikum",
        "city": "Szombathely",
        "address": "Rohonci út 1",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.2337389,
                "longitude": 16.6097166,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015168911704989796,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.22987618242932,
                16.617301055852494,
                47.237601617570675,
                16.602132144147504
            ],
            "_northWest": {
                "latitude": 47.22987618242932,
                "longitude": 16.602132144147504,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.237601617570675,
                "longitude": 16.617301055852494,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 462
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC  Kultsár István Technikum és Szakgimnázium",
        "city": "Komárom",
        "address": "Klapka György út 56.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.74659,
                "longitude": 18.108130000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.745689999999996,
                18.10947,
                47.74749,
                18.106790000000004
            ],
            "_northWest": {
                "latitude": 47.745689999999996,
                "longitude": 18.106790000000004,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.74749,
                "longitude": 18.10947,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 463
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Alapy Gáspár Technikum és Szakképző Iskola ",
        "city": "Komárom",
        "address": "Táncsics Mihály  út 73.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.57374,
                "longitude": 18.413710000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.57284,
                18.415040000000005,
                47.57464,
                18.41238
            ],
            "_northWest": {
                "latitude": 47.57284,
                "longitude": 18.41238,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.57464,
                "longitude": 18.415040000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 464
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Bánki Donát Szakképző Iskola ",
        "city": "Kisbér",
        "address": "Batthyány  tér 2",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.50188,
                "longitude": 18.028165,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00210999999999828,
            "height": -0.0016799999999932425,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.50104,
                18.029220000000002,
                47.50272,
                18.02711
            ],
            "_northWest": {
                "latitude": 47.50104,
                "longitude": 18.02711,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.50272,
                "longitude": 18.029220000000002,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 465
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Bánki Donát Szakképző Iskola Tanműhely",
        "city": "Kisbér",
        "address": "Dobi utca 1/b.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.4990101,
                "longitude": 18.0350289,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015245378283331945,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.49514738242932,
                18.042651589141666,
                47.502872817570676,
                18.027406210858334
            ],
            "_northWest": {
                "latitude": 47.49514738242932,
                "longitude": 18.027406210858334,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.502872817570676,
                "longitude": 18.042651589141666,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 466
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Bánki Donát-Péch Antal Technikum",
        "city": "Tatabánya",
        "address": "Réti utca 1-5.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.58046,
                "longitude": 18.38612,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.57956,
                18.38745,
                47.581360000000004,
                18.384789999999995
            ],
            "_northWest": {
                "latitude": 47.57956,
                "longitude": 18.384789999999995,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.581360000000004,
                "longitude": 18.38745,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 467
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Bláthy Ottó Technikum, Szakképző Iskola és Kollégium Székhely",
        "city": "Tata",
        "address": "Hősök tere 9.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.64547,
                "longitude": 18.317520000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.64457,
                18.318850000000005,
                47.646370000000005,
                18.31619
            ],
            "_northWest": {
                "latitude": 47.64457,
                "longitude": 18.31619,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.646370000000005,
                "longitude": 18.318850000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 468
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Bláthy Ottó Technikum, Szakképző Iskola és Kollégium Telephely",
        "city": "Tata",
        "address": "Bercsényi utca 7",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.64545,
                "longitude": 18.31548,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.644549999999995,
                18.31681,
                47.64635,
                18.31415
            ],
            "_northWest": {
                "latitude": 47.644549999999995,
                "longitude": 18.31415,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.64635,
                "longitude": 18.31681,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 469
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Eötvös Loránd Szakképző Iskola",
        "city": "Oroszlány",
        "address": "Asztalos János utca 2",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.483270000000005,
                "longitude": 18.31837,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.48237,
                18.319700000000005,
                47.484170000000006,
                18.31704
            ],
            "_northWest": {
                "latitude": 47.48237,
                "longitude": 18.31704,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.484170000000006,
                "longitude": 18.319700000000005,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 470
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Fellner Jakab Technikum és Szakképző Iskola",
        "city": "Tatabánya",
        "address": "Pilinszky János utca 3",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.57383,
                "longitude": 18.40081,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.57293,
                18.40214,
                47.57473,
                18.39948
            ],
            "_northWest": {
                "latitude": 47.57293,
                "longitude": 18.39948,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.57473,
                "longitude": 18.40214,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 471
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Kossuth Lajos Gazdasági és Humán Technikum ",
        "city": "Tatabánya",
        "address": "Cseri utca 35",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.55249,
                "longitude": 18.41983,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.55159,
                18.42116,
                47.55339,
                18.4185
            ],
            "_northWest": {
                "latitude": 47.55159,
                "longitude": 18.4185,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.55339,
                "longitude": 18.42116,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 472
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Mikes Kelemen Technikum és Szakgimnázium",
        "city": "Tatabánya",
        "address": "Béke utca 8",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.56254,
                "longitude": 18.42416,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.56164,
                18.42549,
                47.56344,
                18.42283
            ],
            "_northWest": {
                "latitude": 47.56164,
                "longitude": 18.42283,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.56344,
                "longitude": 18.42549,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 473
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Tatabányai SZC Széchenyi István Gazdasági és Informatikai Technikum",
        "city": "Komárom",
        "address": "Táncsics Mihály utca 75.",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.74264,
                "longitude": 18.13203,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.74174,
                18.13337,
                47.74354,
                18.13069
            ],
            "_northWest": {
                "latitude": 47.74174,
                "longitude": 18.13069,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.74354,
                "longitude": 18.13337,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 474
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Terv utcai telephely",
        "city": "Mosonmagyaróvár",
        "address": "Terv utca 92",
        "county": "Győr-Moson-Sopron",
        "bestView": {
            "center": {
                "latitude": 47.88225,
                "longitude": 17.25928,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.88135,
                17.26062,
                47.88315,
                17.25794
            ],
            "_northWest": {
                "latitude": 47.88135,
                "longitude": 17.25794,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.88315,
                "longitude": 17.26062,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 475
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna  Megyei SZC Magyar László Szakképző Iskolája (Templom utca 4.)",
        "city": "Dunaföldvár",
        "address": "Templom utca 4",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.80797,
                "longitude": 18.92714,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.807069999999996,
                18.92845,
                46.80887,
                18.92583
            ],
            "_northWest": {
                "latitude": 46.807069999999996,
                "longitude": 18.92583,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.80887,
                "longitude": 18.92845,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 476
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Ady Endre Technikum és Kollégium Holub utcai kollégium ",
        "city": "Szekszárd",
        "address": "Holub utca 9.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.34348,
                "longitude": 18.70792,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0000800000000005241,
            "height": -0.0005000000000023874,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.34323,
                18.70796,
                46.34373,
                18.707880000000003
            ],
            "_northWest": {
                "latitude": 46.34323,
                "longitude": 18.707880000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.34373,
                "longitude": 18.70796,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 477
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Ady Endre Technikum és Kollégiuma Székhely",
        "city": "Szekszárd",
        "address": "Széchenyi utca 2-14.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.35149,
                "longitude": 18.70234,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.35059,
                18.70364,
                46.35239,
                18.70104
            ],
            "_northWest": {
                "latitude": 46.35059,
                "longitude": 18.70104,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.35239,
                "longitude": 18.70364,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 478
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Apáczai Csere János Technikum és Kollégium Székhely",
        "city": "Dombóvár",
        "address": "Arany János tér 21",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.3699,
                "longitude": 18.12817,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.369,
                18.12947,
                46.3708,
                18.12687
            ],
            "_northWest": {
                "latitude": 46.369,
                "longitude": 18.12687,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.3708,
                "longitude": 18.12947,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 479
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Bezerédj István Szakképző Iskolája Székhely",
        "city": "Szekszárd",
        "address": "Szent László utca 8-12.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.351575,
                "longitude": 18.70024,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0001600000000010482,
            "height": -0.00007000000000090267,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.35154,
                18.70032,
                46.351609999999994,
                18.70016
            ],
            "_northWest": {
                "latitude": 46.35154,
                "longitude": 18.70016,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.351609999999994,
                "longitude": 18.70032,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 480
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Hunyadi Mátyás Vendéglátó Technikum és Szakképző Iskola Székhely",
        "city": "Szekszárd",
        "address": "Szent István tér 15-17.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.34777,
                "longitude": 18.70611,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.346869999999996,
                18.70741,
                46.34867,
                18.70481
            ],
            "_northWest": {
                "latitude": 46.346869999999996,
                "longitude": 18.70481,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.34867,
                "longitude": 18.70741,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 481
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Hunyadi Mátyás Vendéglátó Technikum és Szakképző Iskola Telephely",
        "city": "Szekszárd",
        "address": "Szent István tér 15-17.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.34777,
                "longitude": 18.70611,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.346869999999996,
                18.70741,
                46.34867,
                18.70481
            ],
            "_northWest": {
                "latitude": 46.346869999999996,
                "longitude": 18.70481,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.34867,
                "longitude": 18.70741,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 482
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC I. István Szakképző Iskola Székhely",
        "city": "Paks",
        "address": "Iskola utca 7",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.591319999999996,
                "longitude": 18.80928,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.590419999999995,
                18.81059,
                46.59222,
                18.80797
            ],
            "_northWest": {
                "latitude": 46.590419999999995,
                "longitude": 18.80797,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.59222,
                "longitude": 18.81059,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 483
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Magyar László Szakképző Iskola (Jókai u. 5.)",
        "city": "Dunaföldvár",
        "address": "Jókai  utca 5.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.80762,
                "longitude": 18.92536,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.80672,
                18.92667,
                46.80852,
                18.92405
            ],
            "_northWest": {
                "latitude": 46.80672,
                "longitude": 18.92405,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.80852,
                "longitude": 18.92667,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 484
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Magyar László Szakképző Iskola Székhely",
        "city": "Dunaföldvár",
        "address": "Templom utca 9",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.808679999999995,
                "longitude": 18.92633,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.807779999999994,
                18.92764,
                46.80958,
                18.92502
            ],
            "_northWest": {
                "latitude": 46.807779999999994,
                "longitude": 18.92502,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.80958,
                "longitude": 18.92764,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 485
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Perczel Mór Technikum és Kollégium Jókai Mór Utcai Telephelye ",
        "city": "Bonyhád",
        "address": "Jókai Mór utca 3.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.29639,
                "longitude": 18.5256,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.29549,
                18.5269,
                46.297290000000004,
                18.5243
            ],
            "_northWest": {
                "latitude": 46.29549,
                "longitude": 18.5243,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.297290000000004,
                "longitude": 18.5269,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 486
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Perczel Mór Technikum és Kollégium Perczel Mór Utca 44. Alatti Telephelye (Kollégium)",
        "city": "Bonyhád",
        "address": "Perczel Mór  utca 44.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.2947,
                "longitude": 18.52767,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.2938,
                18.52897,
                46.2956,
                18.52637
            ],
            "_northWest": {
                "latitude": 46.2938,
                "longitude": 18.52637,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.2956,
                "longitude": 18.52897,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 487
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Perczel Mór Technikum és Kollégium Székhely",
        "city": "Bonyhád",
        "address": "Széchenyi  tér 18.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.29846,
                "longitude": 18.53124,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.29756,
                18.53254,
                46.29936,
                18.52994
            ],
            "_northWest": {
                "latitude": 46.29756,
                "longitude": 18.52994,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.29936,
                "longitude": 18.53254,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 488
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Vályi Péter Szakképző Iskola és Kollégium Fornád tanműhely",
        "city": "Tamási",
        "address": "Fornád puszta",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.63701820373535,
                "longitude": 18.301939964294434,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.1606731414794922,
            "height": -0.17383956909179688,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.55009841918945,
                18.38227653503418,
                46.72393798828125,
                18.221603393554688
            ],
            "_northWest": {
                "latitude": 46.55009841918945,
                "longitude": 18.221603393554688,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.72393798828125,
                "longitude": 18.38227653503418,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 489
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Vályi Péter Szakképző Iskola és Kollégium Rákóczi utca 21.",
        "city": "Tamási",
        "address": "Rákóczi utca 21.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.57918,
                "longitude": 18.39446,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.57828,
                18.39577,
                46.58008,
                18.39315
            ],
            "_northWest": {
                "latitude": 46.57828,
                "longitude": 18.39315,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.58008,
                "longitude": 18.39577,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 490
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Vályi Péter Szakképző Iskola és Kollégium Székhely",
        "city": "Tamási",
        "address": "Deák Ferenc utca 6-8.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.6290282,
                "longitude": 18.2829453,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.014998624630230495,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.625165482429324,
                18.290444612315117,
                46.63289091757068,
                18.275445987684886
            ],
            "_northWest": {
                "latitude": 46.625165482429324,
                "longitude": 18.275445987684886,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.63289091757068,
                "longitude": 18.290444612315117,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 491
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolna Megyei SZC Vályi Péter Szakképző Iskola Szabadság Utcai Telephely",
        "city": "Tamási",
        "address": "Szabadság utca 9.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.657849999999996,
                "longitude": 18.17832,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.656949999999995,
                18.17963,
                46.65875,
                18.17701
            ],
            "_northWest": {
                "latitude": 46.656949999999995,
                "longitude": 18.17701,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.65875,
                "longitude": 18.17963,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 492
    },
    {
        "region": "Dél-Dunántúl",
        "name": "Tolnai Megyei SZC Perczel Mór technikum és Kollégium Rákóczi Utcai Telephelye  (Tanműhely)",
        "city": "Bonyhád",
        "address": "Rákóczi Ferenc utca 31.",
        "county": "Tolna",
        "bestView": {
            "center": {
                "latitude": 46.30296,
                "longitude": 18.53089,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002600000000001046,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.30206,
                18.53219,
                46.30386,
                18.52959
            ],
            "_northWest": {
                "latitude": 46.30206,
                "longitude": 18.52959,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.30386,
                "longitude": 18.53219,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 493
    },
    {
        "region": "Közép-Dunántúl",
        "name": "TSZC Kereskedelmi, Vendéglátó és Idegenforgalmi Technikum és Szakképző Iskola",
        "city": "Tatabánya",
        "address": "Kós Károly utca 17",
        "county": "Komárom-Esztergom",
        "bestView": {
            "center": {
                "latitude": 47.5912,
                "longitude": 18.396,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.5903,
                18.39733,
                47.5921,
                18.39467
            ],
            "_northWest": {
                "latitude": 47.5903,
                "longitude": 18.39467,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.5921,
                "longitude": 18.39733,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 494
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci Szakképzési Centrum Boronkay György Műszaki Technikum és Gimnázium Tanüzem",
        "city": "Vác",
        "address": "Kossuth tér 1.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.77686,
                "longitude": 19.130850000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.77596,
                19.13219,
                47.77776,
                19.129510000000003
            ],
            "_northWest": {
                "latitude": 47.77596,
                "longitude": 19.129510000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.77776,
                "longitude": 19.13219,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 495
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC Bocskai István Kollégium Székhely",
        "city": "Vác ",
        "address": "Brusznyai Árpád utca 1.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.79473,
                "longitude": 19.11237,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.79383,
                19.113709999999998,
                47.79563,
                19.11103
            ],
            "_northWest": {
                "latitude": 47.79383,
                "longitude": 19.11103,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.79563,
                "longitude": 19.113709999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 496
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC Boronkay György Műszaki Technikum és Gimnázium Székhely",
        "city": "Vác",
        "address": "Németh László út 4-6.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.7938628,
                "longitude": 19.1100849,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015331669511489565,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.79000008242932,
                19.117750734755745,
                47.797725517570676,
                19.102419065244256
            ],
            "_northWest": {
                "latitude": 47.79000008242932,
                "longitude": 19.102419065244256,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.797725517570676,
                "longitude": 19.117750734755745,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 497
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC I. Géza Király Közgazdasági Technikum Székhely",
        "city": "Vác",
        "address": "Géza király tér 8.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.77209,
                "longitude": 19.131529999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.77119,
                19.132869999999997,
                47.77299,
                19.13019
            ],
            "_northWest": {
                "latitude": 47.77119,
                "longitude": 19.13019,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.77299,
                "longitude": 19.132869999999997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 498
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC Király Endre  Technikum és Szakképző Iskola  Székhely",
        "city": "Vác",
        "address": "Naszály út 8.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.784189999999995,
                "longitude": 19.131970000000003,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.783289999999994,
                19.13331,
                47.78509,
                19.130630000000004
            ],
            "_northWest": {
                "latitude": 47.783289999999994,
                "longitude": 19.130630000000004,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.78509,
                "longitude": 19.13331,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 499
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC Madách Imre Technikum és Szakképző Iskola Székhely",
        "city": "Gödöllő",
        "address": "Ganz Ábrahám utca 1-3",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.593497,
                "longitude": 19.335568,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015272880740511141,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.58963428242932,
                19.343204440370254,
                47.597359717570676,
                19.327931559629743
            ],
            "_northWest": {
                "latitude": 47.58963428242932,
                "longitude": 19.327931559629743,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.597359717570676,
                "longitude": 19.343204440370254,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 500
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC Petőfi Sándor Műszaki Technikum,  Gimnázium és Kollégium",
        "city": "Aszód",
        "address": "Hatvani út 3",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.65101,
                "longitude": 19.49202,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026799999999980173,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.65011,
                19.49336,
                47.65191,
                19.49068
            ],
            "_northWest": {
                "latitude": 47.65011,
                "longitude": 19.49068,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.65191,
                "longitude": 19.49336,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 501
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC Petzelt József Technikum és Szakképző Iskola Székhely",
        "city": "Szentendre",
        "address": "Római sánc  köz 1.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.66368,
                "longitude": 19.075499999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.66278,
                19.076839999999997,
                47.66458,
                19.07416
            ],
            "_northWest": {
                "latitude": 47.66278,
                "longitude": 19.07416,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.66458,
                "longitude": 19.076839999999997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 502
    },
    {
        "region": "Közép-Magyarország",
        "name": "Váci SZC Selye János Egészségügyi Technikum",
        "city": "Vác",
        "address": "Jávorszky sétány 2.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.783615,
                "longitude": 19.144655,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0019500000000007844,
            "height": -0.0005899999999954275,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.78332,
                19.14563,
                47.78390999999999,
                19.14368
            ],
            "_northWest": {
                "latitude": 47.78332,
                "longitude": 19.14368,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.78390999999999,
                "longitude": 19.14563,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 503
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei Műszaki SZC III. Béla Technikum (Székhely)",
        "city": "Szentgotthárd",
        "address": "Honvéd utca 10",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 46.95107094314247,
                "longitude": 16.276534052238095,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015088624151005092,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.94720822557179,
                16.284078364313597,
                46.954933660713145,
                16.268989740162592
            ],
            "_northWest": {
                "latitude": 46.94720822557179,
                "longitude": 16.268989740162592,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.954933660713145,
                "longitude": 16.284078364313597,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 504
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Barabás György Műszaki Szakképző Iskola Székhely",
        "city": "Sárvár",
        "address": "Kisfaludy S. út 2/A",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.2564257,
                "longitude": 16.9337636,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015175408647877475,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.252562982429325,
                16.941351304323938,
                47.26028841757068,
                16.92617589567606
            ],
            "_northWest": {
                "latitude": 47.252562982429325,
                "longitude": 16.92617589567606,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.26028841757068,
                "longitude": 16.941351304323938,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 505
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Eötvös Loránd Szakképző Iskola Székhely",
        "city": "Celldömölk ",
        "address": "Sági utca 65.",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.24947,
                "longitude": 17.15126,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.24857,
                17.15258,
                47.250370000000004,
                17.14994
            ],
            "_northWest": {
                "latitude": 47.24857,
                "longitude": 17.14994,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.250370000000004,
                "longitude": 17.15258,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 506
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Hefele Menyhért Szakképző Iskola",
        "city": "Szombathely",
        "address": "Szent Márton út 77",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.234161701560275,
                "longitude": 16.632928674740118,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015169032712364583,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.2302989839896,
                16.6405131910963,
                47.23802441913095,
                16.625344158383935
            ],
            "_northWest": {
                "latitude": 47.2302989839896,
                "longitude": 16.625344158383935,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.23802441913095,
                "longitude": 16.6405131910963,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 507
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Horváth Boldizsár Közgazdasági és Informatikai  Technikum Székhely",
        "city": "Szombathely",
        "address": "Zrínyi Ilona utca 12.",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.22855,
                "longitude": 16.62455,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.22765,
                16.62587,
                47.22945,
                16.62323
            ],
            "_northWest": {
                "latitude": 47.22765,
                "longitude": 16.62323,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.22945,
                "longitude": 16.62587,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 508
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Horváth Boldizsár Közgazdasági és Informatikai Technikum Telephelye",
        "city": "Szombathely",
        "address": "Zrínyi Ilona utca 19.",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.22905,
                "longitude": 16.62489,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.22815,
                16.62621,
                47.22995,
                16.62357
            ],
            "_northWest": {
                "latitude": 47.22815,
                "longitude": 16.62357,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.22995,
                "longitude": 16.62621,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 509
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Kereskedelmi és Vendéglátó Technikum és Kollégium Székhely",
        "city": "Szombathely",
        "address": "Nagykar utca 1-3.",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.23138,
                "longitude": 16.61481,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.23048,
                16.61613,
                47.23228,
                16.61349
            ],
            "_northWest": {
                "latitude": 47.23048,
                "longitude": 16.61349,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.23228,
                "longitude": 16.61613,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 510
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Nádasdy Tamás Technikum és Kollégium Büki Szakképző Iskola",
        "city": "Bük",
        "address": "Eötvös utca 1-3",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.38314,
                "longitude": 16.75074,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026599999999987745,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.382239999999996,
                16.75207,
                47.38404,
                16.74941
            ],
            "_northWest": {
                "latitude": 47.382239999999996,
                "longitude": 16.74941,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.38404,
                "longitude": 16.75207,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 511
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Nádasdy Tamás Technikum és Kollégium Csepreg",
        "city": "Csepreg",
        "address": "Rákóczi utca 13-15",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.40249,
                "longitude": 16.71156,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002660000000002327,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.40159,
                16.71289,
                47.40339,
                16.710229999999996
            ],
            "_northWest": {
                "latitude": 47.40159,
                "longitude": 16.710229999999996,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.40339,
                "longitude": 16.71289,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 512
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Oladi Technikum",
        "city": "Szombathely",
        "address": "Simon István utca 2-6.",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.24226,
                "longitude": 16.5986,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.24136,
                16.59992,
                47.24316,
                16.59728
            ],
            "_northWest": {
                "latitude": 47.24136,
                "longitude": 16.59728,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.24316,
                "longitude": 16.59992,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 513
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Puskás Tivadar Szakképző Iskola és Kollégium Berzsenyi Dániel Kollégiumi Intézményegysége",
        "city": "Szombathely",
        "address": "Ady tér 2",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.232079999999996,
                "longitude": 16.61363,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.231179999999995,
                16.61495,
                47.23298,
                16.61231
            ],
            "_northWest": {
                "latitude": 47.231179999999995,
                "longitude": 16.61231,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.23298,
                "longitude": 16.61495,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 514
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Puskás Tivadar Szakképző Iskola és Kollégium Székhely",
        "city": "Szombathely",
        "address": "Petőfi út 1",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.230760000000004,
                "longitude": 16.61076,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.22986,
                16.61208,
                47.231660000000005,
                16.60944
            ],
            "_northWest": {
                "latitude": 47.22986,
                "longitude": 16.60944,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.231660000000005,
                "longitude": 16.61208,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 515
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Rázsó Imre Technikum ",
        "city": "Körmend",
        "address": "Rákóczi Ferenc utca 2",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.2291043,
                "longitude": 16.6219278,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015167585445965415,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.22524158242933,
                16.629511592722984,
                47.23296701757068,
                16.61434400727702
            ],
            "_northWest": {
                "latitude": 47.22524158242933,
                "longitude": 16.61434400727702,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.23296701757068,
                "longitude": 16.629511592722984,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 516
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Vas Megyei SZC Savaria Technikum és Kollégium Székhely",
        "city": "Szombathely",
        "address": "Hadnagy utca 1.",
        "county": "Vas",
        "bestView": {
            "center": {
                "latitude": 47.23838,
                "longitude": 16.62342,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.23748,
                16.62474,
                47.23928,
                16.6221
            ],
            "_northWest": {
                "latitude": 47.23748,
                "longitude": 16.6221,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.23928,
                "longitude": 16.62474,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 517
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi Szakképzési Centrum Bethlen István Közgazdasági és Közigazgatási Technikum",
        "city": "Veszprém",
        "address": "Csap utca 9.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.10113,
                "longitude": 17.92411,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.100229999999996,
                17.92543,
                47.10203,
                17.92279
            ],
            "_northWest": {
                "latitude": 47.100229999999996,
                "longitude": 17.92279,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.10203,
                "longitude": 17.92543,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 518
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC „SÉF” Vendéglátás-Turizmus Technikum és Szakképző Iskola",
        "city": "Veszprém",
        "address": "Halle utca 3.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.1048,
                "longitude": 17.91364,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.103899999999996,
                17.91496,
                47.1057,
                17.91232
            ],
            "_northWest": {
                "latitude": 47.103899999999996,
                "longitude": 17.91232,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.1057,
                "longitude": 17.91496,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 519
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Gönczy Pál Szakképző Iskola Székhely",
        "city": "Tapolca",
        "address": "Templom tér 3.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 46.88799,
                "longitude": 17.49645,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.88709,
                17.49777,
                46.88889,
                17.49513
            ],
            "_northWest": {
                "latitude": 46.88709,
                "longitude": 17.49513,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.88889,
                "longitude": 17.49777,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 520
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Ipari Technikum Székhely",
        "city": "Veszprém",
        "address": "Iskola utca 4.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 46.91296,
                "longitude": 17.457259999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.91206,
                17.458579999999998,
                46.91386,
                17.45594
            ],
            "_northWest": {
                "latitude": 46.91206,
                "longitude": 17.45594,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.91386,
                "longitude": 17.458579999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 521
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Jendrassik – Venesz Technikum",
        "city": "Veszprém",
        "address": "Március 15. utca 5.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.105599999999995,
                "longitude": 17.92375,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026400000000030843,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.104699999999994,
                17.925069999999998,
                47.1065,
                17.92243
            ],
            "_northWest": {
                "latitude": 47.104699999999994,
                "longitude": 17.92243,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.1065,
                "longitude": 17.925069999999998,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 522
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Öveges József Technikum és Kollégium",
        "city": "Balatonfűzfő",
        "address": "Gagarin utca 27.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.076139999999995,
                "longitude": 18.0344,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.075239999999994,
                18.03572,
                47.07704,
                18.03308
            ],
            "_northWest": {
                "latitude": 47.075239999999994,
                "longitude": 18.03308,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.07704,
                "longitude": 18.03572,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 523
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Öveges József Technikum és Kollégium Bugyogóforrás Utcai Telephelye",
        "city": "Balatonfűzfő",
        "address": "Bugyogó forrás utca 19.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.07704,
                "longitude": 18.03108,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.076139999999995,
                18.0324,
                47.07794,
                18.02976
            ],
            "_northWest": {
                "latitude": 47.076139999999995,
                "longitude": 18.02976,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.07794,
                "longitude": 18.0324,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 524
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Öveges József Technikum és Kollégium Gagarin Utcai Telephelye",
        "city": "Balatonfűzfő",
        "address": "Gagarin utca 9.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.075469999999996,
                "longitude": 18.03152,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.074569999999994,
                18.03284,
                47.07637,
                18.0302
            ],
            "_northWest": {
                "latitude": 47.074569999999994,
                "longitude": 18.0302,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.07637,
                "longitude": 18.03284,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 525
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Szent-Györgyi Albert Technikum és Kollégium",
        "city": "Ajka",
        "address": "Kandó Kálmán  lakótelep 4.",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.094210000000004,
                "longitude": 17.55856,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.09331,
                17.55988,
                47.095110000000005,
                17.55724
            ],
            "_northWest": {
                "latitude": 47.09331,
                "longitude": 17.55724,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.095110000000005,
                "longitude": 17.55988,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 526
    },
    {
        "region": "Közép-Dunántúl",
        "name": "Veszprémi SZC Táncsics Mihály Technikum",
        "city": "Veszprém",
        "address": "Eötvös Károly utca 1",
        "county": "Veszprém",
        "bestView": {
            "center": {
                "latitude": 47.09853,
                "longitude": 17.91495,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026399999999995316,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.097629999999995,
                17.91627,
                47.09943,
                17.91363
            ],
            "_northWest": {
                "latitude": 47.097629999999995,
                "longitude": 17.91363,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.09943,
                "longitude": 17.91627,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 527
    },
    {
        "region": "Közép-Magyarország",
        "name": "VSzC Petzelt József Technikum és Szakképző Iskola",
        "city": "Szentendre",
        "address": "Római sánc köz 1.",
        "county": "Pest",
        "bestView": {
            "center": {
                "latitude": 47.66368,
                "longitude": 19.075499999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.00268000000000157,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                47.66278,
                19.076839999999997,
                47.66458,
                19.07416
            ],
            "_northWest": {
                "latitude": 47.66278,
                "longitude": 19.07416,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 47.66458,
                "longitude": 19.076839999999997,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 528
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Csány László Technikum  Virág Benedek utca 5.",
        "city": "Zalaegerszeg",
        "address": "Virág Benedek utca 5.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.84455,
                "longitude": 16.83894,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.84365,
                16.84025,
                46.84545,
                16.83763
            ],
            "_northWest": {
                "latitude": 46.84365,
                "longitude": 16.83763,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.84545,
                "longitude": 16.84025,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 529
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Csány László Technikum Jókai u. 4-6.",
        "city": "Zalaegerszeg",
        "address": "Jókai utca 4-6.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.84549,
                "longitude": 16.83806,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.84459,
                16.83937,
                46.84639,
                16.83675
            ],
            "_northWest": {
                "latitude": 46.84459,
                "longitude": 16.83675,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.84639,
                "longitude": 16.83937,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 530
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Deák Ferenc Technikum",
        "city": "Zalaegerszeg",
        "address": "Göcseji út 16",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.83671,
                "longitude": 16.8333,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.835809999999995,
                16.83461,
                46.83761,
                16.83199
            ],
            "_northWest": {
                "latitude": 46.835809999999995,
                "longitude": 16.83199,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.83761,
                "longitude": 16.83461,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 531
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Ganz Ábrahám Technikum",
        "city": "Zalaegerszeg",
        "address": "Gasparich Márk utca 27.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.84057,
                "longitude": 16.82717,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.83967,
                16.82848,
                46.84147,
                16.82586
            ],
            "_northWest": {
                "latitude": 46.83967,
                "longitude": 16.82586,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.84147,
                "longitude": 16.82848,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 532
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Keszthelyi Asbóth Sándor Technikum, Szakképző Iskola és Kollégium",
        "city": "Keszthely",
        "address": "Gagarin utca 2-4.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.758359999999996,
                "longitude": 17.23698,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.757459999999995,
                17.23829,
                46.75926,
                17.23567
            ],
            "_northWest": {
                "latitude": 46.757459999999995,
                "longitude": 17.23567,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.75926,
                "longitude": 17.23829,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 533
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Keszthelyi Asbóth Sándor Technikum, Szakképző Iskola és Kollégium Csik Ferenc sétány 6. telephelye",
        "city": "Keszthely",
        "address": "Csik Ferenc sétány 6.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.758015,
                "longitude": 17.248675,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.000049999999998107114,
            "height": -0.00005000000000165983,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.75799,
                17.2487,
                46.75804,
                17.248649999999998
            ],
            "_northWest": {
                "latitude": 46.75799,
                "longitude": 17.248649999999998,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.75804,
                "longitude": 17.2487,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 534
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Keszthelyi Asbóth Sándor Technikum, Szakképző Iskola és Kollégium Csik Ferenc sétány 7. telephelye",
        "city": "Keszthely",
        "address": "Csik Ferenc sétány 7.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.75733550176651,
                "longitude": 17.248384165246797,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.015034296098598077,
            "height": -0.007725435141352932,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.753472784195836,
                17.255901313296096,
                46.76119821933719,
                17.2408670171975
            ],
            "_northWest": {
                "latitude": 46.753472784195836,
                "longitude": 17.2408670171975,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.76119821933719,
                "longitude": 17.255901313296096,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 535
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Keszthelyi Közgazdasági Technikum",
        "city": "Keszthely",
        "address": "Rózsa utca 12.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.757975,
                "longitude": 17.237415,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.005069999999999908,
            "height": -0.0008300000000005525,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.75756,
                17.23995,
                46.758390000000006,
                17.234879999999997
            ],
            "_northWest": {
                "latitude": 46.75756,
                "longitude": 17.234879999999997,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.758390000000006,
                "longitude": 17.23995,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 536
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Keszthelyi Vendéglátó Technikum, Szakképző Iskola és Kollégium",
        "city": "Keszthely",
        "address": "Mártírok útja 1",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.75906,
                "longitude": 17.24393,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.75816,
                17.24524,
                46.75996,
                17.24262
            ],
            "_northWest": {
                "latitude": 46.75816,
                "longitude": 17.24262,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.75996,
                "longitude": 17.24524,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 537
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Lámfalussy Sándor Szakképző Iskola",
        "city": "Lenti",
        "address": "Petőfi Sándor út 23.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.64474,
                "longitude": 16.68719,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.64384,
                16.6885,
                46.64564,
                16.68588
            ],
            "_northWest": {
                "latitude": 46.64384,
                "longitude": 16.68588,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.64564,
                "longitude": 16.6885,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 538
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Lámfalussy Sándor Szakképző Iskola - tanétterem, tankonyha",
        "city": "Lenti",
        "address": "Petőfi Sándor út 15.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.64522,
                "longitude": 16.68782,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.64432,
                16.68913,
                46.64612,
                16.68651
            ],
            "_northWest": {
                "latitude": 46.64432,
                "longitude": 16.68651,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.64612,
                "longitude": 16.68913,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 539
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Lámfalussy Sándor Szakképző Iskola - tanműhely",
        "city": "Lenti",
        "address": "Béke utca 40.",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.630089999999996,
                "longitude": 16.53367,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.629189999999994,
                16.53498,
                46.63099,
                16.53236
            ],
            "_northWest": {
                "latitude": 46.629189999999994,
                "longitude": 16.53236,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.63099,
                "longitude": 16.53498,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 540
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Munkácsy Mihály Technikum",
        "city": "Zalaegerszeg",
        "address": "Gasparich Márk utca 24",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.841570000000004,
                "longitude": 16.82757,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.84067,
                16.82888,
                46.842470000000006,
                16.82626
            ],
            "_northWest": {
                "latitude": 46.84067,
                "longitude": 16.82626,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.842470000000006,
                "longitude": 16.82888,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 541
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "Zalaegerszegi SZC Széchenyi István Technikum",
        "city": "Zalaegerszeg",
        "address": "Déryné  utca 1",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.83829,
                "longitude": 16.8318,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.0026200000000002888,
            "height": -0.0018000000000029104,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.83739,
                16.83311,
                46.83919,
                16.83049
            ],
            "_northWest": {
                "latitude": 46.83739,
                "longitude": 16.83049,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.83919,
                "longitude": 16.83311,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 542
    },
    {
        "region": "Nyugat-Dunántúl",
        "name": "ZSZC Báthory István Technikum",
        "city": "Zalaegerszeg",
        "address": "Báthory út 58",
        "county": "Zala",
        "bestView": {
            "center": {
                "latitude": 46.837469999999996,
                "longitude": 16.824370000000002,
                "altitude": 0,
                "altitudeReference": -1
            },
            "width": 0.002619999999996736,
            "height": -0.001799999999995805,
            "crs": {
                "id": "LatLon",
                "bounds": [
                    90,
                    180,
                    -90,
                    -180
                ]
            },
            "bounds": [
                46.836569999999995,
                16.82568,
                46.83837,
                16.823060000000005
            ],
            "_northWest": {
                "latitude": 46.836569999999995,
                "longitude": 16.823060000000005,
                "altitude": 0,
                "altitudeReference": -1
            },
            "_southEast": {
                "latitude": 46.83837,
                "longitude": 16.82568,
                "altitude": 0,
                "altitudeReference": -1
            }
        },
        "index": 543
    }
];